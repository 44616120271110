<template>
  <div>
    <page-header :title="$tc('order', 2)">
      <div class="d-flex align-center">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="blue"
              depressed
              fab
              text
            ><v-icon>mdi-filter-menu</v-icon></v-btn>
          </template>
          <v-list>
            <v-list-item @click="ordersType = 'all'">
              <v-list-item-title >{{ $t('orders.all_orders') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="ordersType = 'active'">
              <v-list-item-title>{{ $t('orders.only_active') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-text-field
          append-icon="mdi-magnify"
          v-model="table.search"
          :label="$t('search')"
          @keyup.enter="loadOrders"
          @click:clear="loadOrders(true)"
          clearable
          outlined
          dense
          hide-details
        ></v-text-field>
      </div>
    </page-header>
    <v-card
      flat
      rounded="xl"
    >
      <div>
        <v-chip>{{ $t('orders.show_'+ordersType) }}</v-chip>
      </div>
      <v-card-text class="my-orders">
        <v-simple-table>
          <thead>
            <tr>
              <template v-for="(h, h_ind) in table.headers">
                <th
                  v-if="filterHeader(h.value)" 
                  :key="'h_'+h_ind"
                  :class="[{ 'd-none': $vuetify.breakpoint.mdAndDown ? h.hideSmall : false }]"
                >{{ h.text }}</th>
              </template>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, o_ind) in table.orders">
              <tr 
                :key="'o_'+o_ind" 
                class="orders-row"
                @click="$router.push({ name: 'OrderView', params: { order_id : item.id }})"
              >
                <td class="row-first-td" style="height: 70px;">
                  <div class="font-weight-bold">{{ type === 'orders' ? item.orderNumber : item.id }}</div>
                  <p v-if="item.reference !== null" class="mb-0 font-italic">{{ item.reference }}</p>
                </td>
                <td class="d-none d-md-table-cell">
                  <div>{{ formatDate(item.date) }}</div>
                </td>
                <td class="py-2">
                  <div class="d-flex align-center">
                    <div class="font-weight-bold">{{ item.total_string }}</div>
                  </div>
                </td>
                <td v-html="orderDeliveryDate(item)"></td>
                <td class="py-2 d-none d-md-table-cell">
                  <div v-if="item.expired_at !== null && item.expired_at !== ''">
                    <strong class="red--text">{{ $tc('orders.expiring_in', compareDates(item.expired_at, new Date()), { days: compareDates(item.expired_at, new Date()) }) }} </strong>
                    <div style="font-size: 12px;" class="font-italic">{{ $t('orders.pay_before', { date: item.expired_at }) }}</div>
                  </div>
                  <div v-else class="d-flex align-center">
                    <v-icon class="mr-3" small :style="`color: ${stageColor(item.stage.slug, true)}`">{{ stageIcon(item.stage.slug) }}</v-icon> 
                    <span>{{ item.stage.description }}</span> 
                  </div>
                  <v-chip
                    v-if="false"
                    :color="stageColor(item.stage.slug)"
                    dark
                    small
                  >
                    <v-avatar left>
                      <v-icon small>{{ stageIcon(item.stage.slug) }}</v-icon>
                    </v-avatar>
                    {{ item.stage.description }} 
                  </v-chip>
                  <div
                    v-for="so in item.shipping_orders"
                    :key="so.id"
                  >
                    <v-tooltip
                      bottom
                      color="rgba(255, 255, 255, 1)"
                      v-if="so.tracking_waypoints !== null"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          v-bind="attrs"
                          v-on="on"
                          color="blue"
                          dark
                          x-small
                        >
                          {{ so.id }} <v-icon x-small>mdi-map-marker</v-icon>
                        </v-chip>
                      </template>
                      <v-card class="py-2" flat>
                        <tracking-waypoints
                          :waypoints="so.tracking_waypoints"
                        ></tracking-waypoints>
                      </v-card>
                    </v-tooltip>
                  </div>
                </td>
                <td v-if="displayPrice" class="row-last-td d-none d-md-table-cell" @click.stop>
                  <div class="d-flex align-center justify-end">
                    <template v-for="p in item.pending_payments">
                      <v-btn
                        :key="p.id"
                        x-small
                        :color="p.reason === 'balance' ? 'blue' : 'primary'"
                        :to="{ name: 'Checkout', params: { payment_secret: p.protected_id } }"
                        depressed
                      >
                        <span class="white--text">{{ $t('orders.pay_now', { reason: getReason(p.reason) }) }}</span>
                      </v-btn>
                    </template>
                    <v-btn
                      v-if="false"
                      small
                      color="primary"
                      @click="$router.push({ name: 'OrderView', params: { order_id : item.id }})"
                      depressed
                      plain
                      text
                    ><span><v-icon>mdi-pencil</v-icon></span></v-btn>
                  </div>
                </td>
              </tr>
              <tr :key="'osep_'+o_ind" v-if="false">
                <td colspan="6"></td>
              </tr>
            </template>
          </tbody>
        </v-simple-table>
        <v-row>
          <v-col>
            <v-pagination
              class="mt-3"
              v-model="page"
              :length="pageCount"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import trackingWaypoints from './trackingWaypoints.vue';
import { mapMutations } from 'vuex';

export default {
  name: 'ordersList',
  props: {
    type: {
      default: 'orders'
    }
  },
  components: {
    trackingWaypoints
  },
  data() {
    return {
      loading: true,
      table: {
        search: '',
        headers: [
          { text: this.$t('ref'), align: 'start', sortable: false, value: 'orderNumber', width: '100px', fixed: true, show: true },
          { text: this.$t('orders.place_at'), align: 'start', sortable: false, value: 'date', show: true, hideSmall: true },
          { text: this.$t('total'), align: 'start', sortable: false, value: 'total_string', show: true },
          { text: this.$t('orders.estimated_delivery'), align: 'start', sortable: false, value: 'stage', show: true },
          { text: this.$t('status'), align: 'start', sortable: false, value: 'stage', show: true, hideSmall: true },
          { text: '', align: 'start', sortable: false, show: this.displayPrice, value: 'actions', hideSmall: true }
        ],
        orders: [],
      },
      orderStages: [],
      orderStage: '',
      show_expired: false,
      ordersType: 'active',
      pageSize: 10,
      page: 1,
      pageCount: 1,
      totalRecords: 0, 
      options: {}
    }
  },
  watch: {
    options: {
      handler () {
        this.loadOrders()
      },
      deep: true,
    },
    page() {
      this.loadOrders();
    },
    ordersType() {
      this.loadOrders();
    }
  },
  methods: {
    ...mapMutations({
      setLoading: 'isLoading'
    }),
    filterHeader(h) {
      if(h === 'actions' && !this.displayPrice) {
        return false;
      }
      return true;
    },
    getReason(r) {
      if(r === 'deposit' && this.lang === 'es') {
        return 'deposito';
      }
      return r;
    },
    async loadOrders(clear = false) {
      if(clear === true) {//has to be === true because clear get an event
        this.table.search = '';
      }
      this.setLoading(true);
      const result = await this.$orderController.getOrders({
        status: this.ordersType,
        stage: this.orderStage,
        pageSize: this.pageSize,
        search: this.table.search,
        pageNumber: this.page
      });
      this.table.orders = result.items;
      this.pageCount = result.pagination.totalPages;
      this.totalRecords = result.pagination.itemsCount;
      this.setLoading(false);
    },
    isExpired(date) {
      if(new Date() > new Date(date)) {
        return true;
      }
      return false;
    },
    orderDeliveryDate(order) {
      if(order.tentative_delivery_date !== null) {
        return this.formatDate(order.tentative_delivery_date);
      }
      var dates = order.target_delivery_date;
      if(order.shipping_orders.length > 0) {
        var so = order.shipping_orders;
        var text = '';
        for(var i in so) {
          text += `<div>${so[i].tracking_eta !== null ? this.formatDate(so[i].tracking_eta) : this.formatDate(so[i].arrival_date)}</div>`;
        }
        return text;
      }
      if(dates === null) {
        return;
      }
      if(dates.length === 1) {
        return this.formatDate(dates[0].date);
      } else {
        var initial_date = dates[0].date;
        var new_date = null;
        for(var d in dates) {
          if(dates[d].date > initial_date) {
            new_date = dates[d].date;
          }
        }
        if(new_date !== null) {
          return `
            <div class="text-caption font-italic">${this.$t('orders.original_date')}: ${this.formatDate(initial_date)}</div>
            <div class="text-caption font-italic font-weight-bold mColor-text">${this.$t('orders.delayed', { days: this.compareDates(initial_date, new_date) })}</div>
            <div class="font-weight-bold">${this.$t('orders.new_date')}: ${this.formatDate(new_date)}</div>
          `;
        } else {
          return this.formatDate(initial_date);
        }
      }
    }
  },
  created() {
    this.loadOrders();
  }
}
</script>
<style lang="scss">
.my-orders {
  table {
    table-layout: fixed;
  }
  .orders-row {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>