const endpoint = process.env.VUE_APP_API_ENDPOINT;
const endpoint_payment = process.env.VUE_APP_API_ENDPOINT_GLOBAL;

//auth user
export const AUTH_LOGIN = endpoint+'user/auth';
export const AUTH_LOGOUT = endpoint+'user/logout';
export const AUTH_CHECK = endpoint+'user/auth_check';
export const AUTH_FORGET = endpoint+'user/forget';
export const AUTH_RESET = endpoint+'user/reset';
export const AUTH_REGISTER_FIRST = endpoint+'user/new';
export const AUTH_REGISTER_CONFIRM_CODE = endpoint+'user/verify';
export const AUTH_REGISTER_RESEND_CONFIRM_CODE = endpoint+'user/resend_verification';
export const USER_UPDATE = endpoint+'user/update';
export const USER_FETCH = endpoint+'user/get';
export const USER_COMPANY_UPDATE = endpoint+'user/company/update';
export const USER_COMPANY_ADDRESS_INSERT = endpoint+'user/address/insert';
export const USER_COMPANY_ADDRESS_UPDATE = endpoint+'user/address/update';

export const CONTACT_FORM = endpoint+'contact_us';

//catalogue
export const CATEGORIES_LIST = endpoint+'categories/list';
export const CATALOGUE_LIST = endpoint+'catalogue/get_v2';
export const ADD_PRODUCT_TO_FAVORITES = endpoint+'user/favorites/add/:product_id';
export const REMOVE_PRODUCT_TO_FAVORITES = endpoint+'user/favorites/remove/:product_id';
export const GET_PRODUCT = endpoint+'catalogue/get/:product_id';


//orders
export const CREATE_ORDER = endpoint+'orders/create';
export const GET_ORDERS = endpoint+'orders/list';
export const GET_ORDER = endpoint+'orders/:order_id/get';
export const GET_ORDER_PRODUCTS = endpoint+'orders/:order_id/products';
export const GET_ORDER_PAYMENTS = endpoint+'orders/:order_id/payments';
export const GET_ORDER_INSPECTIONS = endpoint+'orders/:order_id/inspections';
export const GET_ORDER_TRACKING = endpoint+'orders/:order_id/tracking';

//payments
export const PAYMENT_BT_TOKEN = endpoint+'payments/bt_get_guest_token';
export const PAYMENT_BT_SUBMIT = endpoint+'payments/bt_submit_user_payment';
export const PAYMENT_BT_RETRIEVE = endpoint+'payments/bt_retrieve_payment_methods';
export const PAYMENT_BT_DELETE = endpoint+'payments/bt_delete_payment_method';
export const PAYMENT_GET = endpoint+'payments/get/:protected_id';
export const PAYMENT_BT_UPDATE = endpoint+'payments/bt_update_user_payment';
export const PAYMENT_CREATE_PAYPAL_ORDER = endpoint+'payments/create_paypal_order';
export const PAYMENT_CAPTURE_PAYPAL_ORDER = endpoint+'payments/capture_paypal_order';
export const PAYMENT_KLARNA_GET_TOKEN = endpoint+'payments/get_klarna_token';
export const PAYMENT_KLARNA_CREATE_ORDER = endpoint+'payments/create_klarna_order';


export const ORDER_GET = endpoint+'order/get';
export const ORDER_LIGHT_GET = endpoint+'order/light/get';
export const GET_ORDERS_FILTERS = endpoint+'order/get_orders_filters';
export const ADD_PRODUCT_TO_ORDER = endpoint+'order/:order_id/add_order_product';
export const PAYMENT_PAYPAL_CREATE = endpoint+'payments/create_paypal_order';
export const PAYMENT_PAYPAL_CHECK = endpoint+'payments/check_paypal_order';
export const PAYMENT_BRAINTREE_TOKEN = endpoint_payment+'payments/bt_get_guest_token';
export const PAYMENT_BRAINTREE_SUBMIT = endpoint_payment+'payments/bt_submit_guest_payment';
export const INVOICE_OPTIONS = endpoint+'invoice/co/:order_id/get';
export const INVOICE_MAKE = endpoint+'invoice/co/:order_id/preview';
export const INVOICE_LIST = endpoint+'invoice/list';
export const INVOICE_GET_ORDER_DATA = endpoint+'invoice/custom/order_data/:order_id';
export const INVOICE_MAKE_CUSTOM = endpoint+'invoice/custom/create';
export const ORDER_GET_PROTECTED = endpoint+'order/get_protected/:protected_id';
