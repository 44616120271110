<template>
  <div>
    <div class="top-nav-container d-flex justify-space-between py-1 px-3" :style="`background: ${$settings.navBarBackground};`">
      <div class="d-flex flex-grow-0">
        <div class="mx-2">
          <a @click.prevent="logoClick" href="#">
            <img width="180" :src="$settings.barLogo" alt="Logo">
          </a>
        </div>
      </div>
      <div class="d-none d-md-flex flex-grow-1 global_search_bar">
        <search-bar v-model="globalSearch"></search-bar>
      </div>
      <div v-if="$vuetify.breakpoint.mdAndUp" class="d-flex flex-grow-0">
        <div v-if="displayPrice">
          <v-badge
            :value="count > 0 ? true : false"
            :offset-x="30"
            color="primary"
            overlap
            :content="count"
          >
            <v-btn
              class="bt-content-col"
              dark
              depressed
              plain
              small
              link
              height="auto"
              :to="{ name: 'Cart' }"
            >
              <v-icon class="mb-1">mdi-cart-outline</v-icon>{{ $t('cart.title') }}
            </v-btn>
          </v-badge>
        </div>
        <div>
          <v-btn
            dark
            depressed
            small
            plain
            link
            height="auto"
            :to="{ name: 'Orders' }"
          >
            <div style="text-align: center;">
              <v-icon class="mb-1">mdi-text-box-multiple-outline</v-icon>
              <div>{{ $tc('order', 2) }}</div>
            </div>
          </v-btn>
        </div>
        <div>
          <avatar-menu></avatar-menu>
        </div>
      </div>
      <div v-else class="hamburger-menu">
        <v-badge
          :value="count > 0 ? true : false"
          :offset-x="30"
          color="primary"
          overlap
          :content="count"
        >
          <v-btn
            class="bt-content-col"
            dark
            depressed
            plain
            small
            link
            height="auto"
            :to="{ name: 'Cart' }"
          ><v-icon class="mb-1">mdi-cart-outline</v-icon></v-btn>
        </v-badge>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="bt-content-col"
              dark
              depressed
              plain
              small
              height="auto"
              v-bind="attrs"
              v-on="on"
              @click="debug([user])"
            ><v-icon class="mb-1">mdi-menu</v-icon></v-btn>
          </template>
          <v-list dense>
            <v-list-item
              :to="{ name: 'Orders' }"
              link
            >
              <v-list-item-content>
                <v-list-item-title>{{ $tc('order', 2) }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>mdi-text-box-multiple-outline</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item
              :to="{ name: 'Account' }"
              link
            >
              <v-list-item-content>
                <v-list-item-title>{{ $t('user_account.my_information') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>mdi-account-settings</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item
              @click="logout"
              link
            >
              <v-list-item-content>
                <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <categories-navigation />
    <div class="pa-3" v-if="false">
      <search-bar v-model="globalSearch"></search-bar>
    </div>
  </div>
</template>

<script>
import searchBar from '../catalogue/searchBar.vue';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import avatarMenu from './avatarMenu.vue';
import CategoriesNavigation from './categoriesNavigation.vue';

export default {
  name: 'TopNavBar',
  components: {
    avatarMenu,
    searchBar,
    CategoriesNavigation
  },
  data() {
    return {
      showCart: false,
      globalSearch: null
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ]),
    ...mapGetters('cart', [
      'count'
    ])
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
    ...mapActions(['loadProducts']),
    ...mapMutations('cart', [
      'cartToggle'
    ]),
    logoClick() {
      this.loadProducts({ reset: true });
    }
  }
}
</script>

<style lang="scss" scoped>
  .top-nav-container {
    width: 100%;
    align-items: center;
  }
  .menu-toggle-wrapper {
    margin-left: -5px;
    .inverted {
      transform: rotate(180deg);
    }
  }
  .global_search_bar {
    width: 100%;
    padding: 0px 100px;
  }
</style>
<style lang="scss">
.bt-content-col .v-btn__content {
  flex-direction: column;
}
</style>