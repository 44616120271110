import rLogo from '../assets/images/brand/revooloop-logo-white.png';
import sLogo from '../assets/images/logo.png';
import rMainLogo from '../assets/images/brand/revooloop-logo.png';
import sMainLogo from '../assets/images/logo-original.png';
//const revooloop_url = process.env.VUE_APP_URL;
const sps_url = process.env.VUE_APP_SPS_URL;
const current_url = window.location.origin+'/';

const revooloop_settings = {
  store_id: 'revooloop',
  name: 'Revooloop',
  endpointId: 'BLHIFMMYNG5CP3UDT12HFGCLTOE1XDY0GQVDPIH53SY541ZGMTDS8XOYIRP0AALJ',
  loginBackground: 'linear-gradient(-45deg, #2FAF64, #723FC9, #2FAF64, #723FC9)',
  primaryColor: '#2FAF64',
  secondaryColor: '#723FC9',
  navBarBackground: 'linear-gradient(-10deg, #7F4DD5 0%, #723FC9 100%)',
  navBarCatergoriesBackogrund: '#5B35AF',
  barLogo: rLogo,
  logo: rMainLogo
}

const sps_settings = {
  store_id: 'sps',
  name: 'Sourcing Specialists',
  endpointId: 'QS0L3Y6A334K5LNTRQU61K48POIZEDJ6BB8FIE4C1FKZJQH99TRJPMEVXR3F7UWL',
  loginBackground: '#B71C1C',
  primaryColor: '#B71C1C',
  secondaryColor: '#2196F3',
  navBarBackground: '#B71C1C',
  navBarCatergoriesBackogrund: '#C62828',
  barLogo: sLogo,
  logo: sMainLogo
}


export const preferences = current_url === sps_url ? sps_settings : revooloop_settings;
export default preferences;