<template>
  <div>
    <v-row>
      <v-col cols="12" lg="7">
        <div class="font-weight-bold px-5 mt-5">{{ $t('user_account.information') }}</div>
        <v-form ref="companyDetails" class="px-8 pb-8" @submit.prevent="updateCompanyDetails">
          <v-row>
            <v-col>
              <v-text-field
                v-model="profile.company_detail.name"
                :label="$t('user_account.company_name')+'*'"
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              md="6"
            >
              <v-text-field
                v-model="profile.company_detail.br_number"
                :label="$t('user_account.business_registration_number')+'*'"
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="profile.company_detail.contact_no"
                :label="$t('user_account.phone_number')+'*'"
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="false">
            <v-col
              md="6"
            >
              <v-text-field
                v-model="profile.company_detail.address"
                :label="$t('user_account.company_address')+'*'"
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="profile.company_detail.delivery_address"
                :label="$t('user_account.delivery_address')"
                :hint="$t('user_account.address_empty')"
                persistent-hint
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              lg="12"
              sm="12"
            >
              <v-select
                v-model="profile.company_detail.pref_currency"
                :items="currencies"
                :label="$t('user_account.pref_currency')"
                :rules="requiredRule"
                required
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="font-weight-bold">{{ $t('user_account.pref_categories') }}</div>
              <div class="text-italic" style="font-size: 12px">{{ $t('user_account.pref_categories_explain') }}</div>
              <v-chip-group
                class="pt-3"
                column
                v-model="pref_categories"
                multiple
              >
                <v-chip
                  v-for="c in categories"
                  :key="c.id"
                  filter
                  outlined
                  :value="c.id"
                >{{ c.header }}</v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                color="primary"
                depressed
                type="submit"
                :loading="loading"
              >{{ $t('update') }}</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col class="pa-8">
        <div class="font-weight-bold mt-5">{{ $t('user_account.billing_address') }}</div>
        <div class="d-flex align-center pa-3">
          <div style="font-size: 14px;" class="pr-4">
            {{ billingAddress.id === null ? this.$t('user_account.please_add_billing_address') : billingAddressText }}
          </div>
          <v-btn
            fab
            depressed
            plain
            small
            @click="openBillingAddressDialog('BILLING')"
          >
            <v-icon v-if="billingAddress.id === null" small>mdi-plus</v-icon>
            <v-icon v-else small>mdi-pen</v-icon>
          </v-btn>
        </div>
        <div class="font-weight-bold mt-5">{{ $t('user_account.delivery_addresses') }}</div>
        <div v-if="deliveryAddresses.length > 0" class="pa-3">
          <div
            v-for="d in deliveryAddresses"
            :key="d.uuid"
            class="d-flex align-center mb-3"
          >
            <div style="font-size: 14px;" class="pr-4">
              <span class="font-weight-bold">{{ d.alias }}, </span> 
              {{ `${d.line_one}, ${d.line_two}, ${d.city}, ${d.postal_code}, ${d.country_name}` }}
              <span v-if="d.is_default === 1" class="font-italic" style="font-size: 12px">({{$t('user_account.default_address')}})</span>
            </div>
            <v-btn
              fab
              depressed
              plain
              small
              @click="openBillingAddressDialog('DELIVERY', d)"
            >
              <v-icon v-if="billingAddress.id === null" small>mdi-plus</v-icon>
              <v-icon v-else small>mdi-pen</v-icon>
            </v-btn>
          </div>
        </div>
        <div style="font-size: 14px;" class="pa-3" v-else>{{ this.$t('user_account.no_delivery_addresses') }}</div>
        <v-btn
          depressed
          plain
          small
          @click="openBillingAddressDialog('DELIVERY')"
        >
          <v-icon small>mdi-plus</v-icon>{{ $t('user_account.add_delivery_address') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model="billingAddressDialog"
      max-width="480"
    >
      <v-card class="py-5">
        <v-card-title>{{ modal_title }}</v-card-title>
        <company-address-form v-if="billingAddressDialog" :address="address" @created="billingAddressDialog = false"></company-address-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import userController from '../../controllers/userController';
import companyAddressForm from './companyAddressForm.vue';

const u = new userController();

export default {
  name: 'companyDetails',
  components: { companyAddressForm },
  data() {
    return {
      profile: {},
      currencies: ['USD', 'EUR'],
      loading: false,
      pref_categories: [],
      address: {
        country: 'ESP'
      },
      billingAddressDialog: false,
      modal_title: null
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'categories',
      'billingAddress',
      'deliveryAddresses',
      'billingAddressText'
    ])
  },
  methods: {
    ...mapActions([
      'updateUserData',
      'fetchUser'
    ]),
    async updateCompanyDetails() {
      if(!this.$refs.companyDetails.validate()) {
        return;
      }
      this.loading = true;
      var data = {
        company_name: this.profile.company_detail.name,
        br: this.profile.company_detail.br_number,
        address: this.profile.company_detail.address,
        delivery_address: this.profile.company_detail.delivery_address,
        contact_no: this.profile.company_detail.contact_no,
        pref_currency: this.profile.company_detail.pref_currency,
        category_ids: this.pref_categories
      };
      const userResult = await u.updateCompany(data);
      if(userResult) {
        this.updateUserData(userResult.user);
      }
      this.loading = false;
      if(this.$route.query.redirect) {
        this.$router.push({ name: this.$route.query.redirect, params: { payment_secret: this.$route.query.param }});
      }
      this.$toasted.success(this.$t('user_account.profile_updated'));
    },
    openBillingAddressDialog(type, address = null) {
      if(type === 'BILLING') {
        this.modal_title = this.$t('user_account.billing_address');
        this.address = JSON.parse(JSON.stringify(this.billingAddress));
      }
      if(type === 'DELIVERY') {
        this.modal_title = this.$t('user_account.delivery_address');
        this.address = address === null ? {} : JSON.parse(JSON.stringify(address));
      }
      this.billingAddressDialog = true;
    }
  },
  created() {
    const client = JSON.parse(JSON.stringify(this.user));
    this.profile = client;
    this.pref_categories = this.user.company_detail.category_ids;
  },
}
</script>