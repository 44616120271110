export const area_values = [
{ text: "UK", value: "44" },
{ text: "USA", value: "1" },
{ text: "Algeria", value: "213" },
{ text: "Andorra", value: "376" },
{ text: "Angola", value: "244" },
{ text: "Anguilla", value: "1264" },
{ text: "Antigua & Barbuda", value: "1268" },
{ text: "Argentina", value: "54" },
{ text: "Armenia", value: "374" },
{ text: "Aruba", value: "297" },
{ text: "Australia", value: "61" },
{ text: "Austria", value: "43" },
{ text: "Azerbaijan", value: "994" },
{ text: "Bahamas", value: "1242" },
{ text: "Bahrain", value: "973" },
{ text: "Bangladesh", value: "880" },
{ text: "Barbados", value: "1246" },
{ text: "Belarus", value: "375" },
{ text: "Belgium", value: "32" },
{ text: "Belize", value: "501" },
{ text: "Benin", value: "229" },
{ text: "Bermuda", value: "1441" },
{ text: "Bhutan", value: "975" },
{ text: "Bolivia", value: "591" },
{ text: "Bosnia Herzegovina", value: "387" },
{ text: "Botswana", value: "267" },
{ text: "Brazil", value: "55" },
{ text: "Brunei", value: "673" },
{ text: "Bulgaria", value: "359" },
{ text: "Burkina Faso", value: "226" },
{ text: "Burundi", value: "257" },
{ text: "Cambodia", value: "855" },
{ text: "Cameroon", value: "237" },
{ text: "Canada", value: "1" },
{ text: "Cape Verde Islands", value: "238" },
{ text: "Cayman Islands", value: "1345" },
{ text: "Central African Republic", value: "236" },
{ text: "Chile", value: "56" },
{ text: "China", value: "86" },
{ text: "Colombia", value: "57" },
{ text: "Comoros", value: "269" },
{ text: "Congo", value: "242" },
{ text: "Cook Islands", value: "682" },
{ text: "Costa Rica", value: "506" },
{ text: "Croatia", value: "385" },
{ text: "Cuba", value: "53" },
{ text: "Cyprus North", value: "90392" },
{ text: "Cyprus South", value: "357" },
{ text: "Czech Republic", value: "42" },
{ text: "Denmark", value: "45" },
{ text: "Djibouti", value: "253" },
{ text: "Dominica", value: "1809" },
{ text: "Dominican Republic", value: "1809" },
{ text: "Ecuador", value: "593" },
{ text: "Egypt", value: "20" },
{ text: "El Salvador", value: "503" },
{ text: "Equatorial Guinea", value: "240" },
{ text: "Eritrea", value: "291" },
{ text: "Estonia", value: "372" },
{ text: "Ethiopia", value: "251" },
{ text: "Falkland Islands", value: "500" },
{ text: "Faroe Islands", value: "298" },
{ text: "Fiji", value: "679" },
{ text: "Finland", value: "358" },
{ text: "France", value: "33" },
{ text: "French Guiana", value: "594" },
{ text: "French Polynesia", value: "689" },
{ text: "Gabon", value: "241" },
{ text: "Gambia", value: "220" },
{ text: "Georgia", value: "7880" },
{ text: "Germany", value: "49" },
{ text: "Ghana", value: "233" },
{ text: "Gibraltar", value: "350" },
{ text: "Greece", value: "30" },
{ text: "Greenland", value: "299" },
{ text: "Grenada", value: "1473" },
{ text: "Guadeloupe", value: "590" },
{ text: "Guam", value: "671" },
{ text: "Guatemala", value: "502" },
{ text: "Guinea", value: "224" },
{ text: "Guinea - Bissau", value: "245" },
{ text: "Guyana", value: "592" },
{ text: "Haiti", value: "509" },
{ text: "Honduras", value: "504" },
{ text: "Hong Kong", value: "852" },
{ text: "Hungary", value: "36" },
{ text: "Iceland", value: "354" },
{ text: "India", value: "91" },
{ text: "Indonesia", value: "62" },
{ text: "Iran", value: "98" },
{ text: "Iraq", value: "964" },
{ text: "Ireland", value: "353" },
{ text: "Israel", value: "972" },
{ text: "Italy", value: "39" },
{ text: "Jamaica", value: "1876" },
{ text: "Japan", value: "81" },
{ text: "Jordan", value: "962" },
{ text: "Kazakhstan", value: "7" },
{ text: "Kenya", value: "254" },
{ text: "Kiribati", value: "686" },
{ text: "Korea North", value: "850" },
{ text: "Korea South", value: "82" },
{ text: "Kuwait", value: "965" },
{ text: "Kyrgyzstan", value: "996" },
{ text: "Laos", value: "856" },
{ text: "Latvia", value: "371" },
{ text: "Lebanon", value: "961" },
{ text: "Lesotho", value: "266" },
{ text: "Liberia", value: "231" },
{ text: "Libya", value: "218" },
{ text: "Liechtenstein", value: "417" },
{ text: "Lithuania", value: "370" },
{ text: "Luxembourg", value: "352" },
{ text: "Macao", value: "853" },
{ text: "Macedonia", value: "389" },
{ text: "Madagascar", value: "261" },
{ text: "Malawi", value: "265" },
{ text: "Malaysia", value: "60" },
{ text: "Maldives", value: "960" },
{ text: "Mali", value: "223" },
{ text: "Malta", value: "356" },
{ text: "Marshall Islands", value: "692" },
{ text: "Martinique", value: "596" },
{ text: "Mauritania", value: "222" },
{ text: "Mayotte", value: "269" },
{ text: "Mexico", value: "52" },
{ text: "Micronesia", value: "691" },
{ text: "Moldova", value: "373" },
{ text: "Monaco", value: "377" },
{ text: "Mongolia", value: "976" },
{ text: "Montserrat", value: "1664" },
{ text: "Morocco", value: "212" },
{ text: "Mozambique", value: "258" },
{ text: "Myanmar", value: "95" },
{ text: "Namibia", value: "264" },
{ text: "Nauru", value: "674" },
{ text: "Nepal", value: "977" },
{ text: "Netherlands", value: "31" },
{ text: "New Caledonia", value: "687" },
{ text: "New Zealand", value: "64" },
{ text: "Nicaragua", value: "505" },
{ text: "Niger", value: "227" },
{ text: "Nigeria", value: "234" },
{ text: "Niue", value: "683" },
{ text: "Norfolk Islands", value: "672" },
{ text: "Northern Marianas", value: "670" },
{ text: "Norway", value: "47" },
{ text: "Oman", value: "968" },
{ text: "Palau", value: "680" },
{ text: "Panama", value: "507" },
{ text: "Papua New Guinea", value: "675" },
{ text: "Paraguay", value: "595" },
{ text: "Peru", value: "51" },
{ text: "Philippines", value: "63" },
{ text: "Poland", value: "48" },
{ text: "Portugal", value: "351" },
{ text: "Puerto Rico", value: "1787" },
{ text: "Qatar", value: "974" },
{ text: "Reunion", value: "262" },
{ text: "Romania", value: "40" },
{ text: "Russia", value: "7" },
{ text: "Rwanda", value: "250" },
{ text: "San Marino", value: "378" },
{ text: "Sao Tome & Principe", value: "239" },
{ text: "Saudi Arabia", value: "966" },
{ text: "Senegal", value: "221" },
{ text: "Serbia", value: "381" },
{ text: "Seychelles", value: "248" },
{ text: "Sierra Leone", value: "232" },
{ text: "Singapore", value: "65" },
{ text: "Slovak Republic", value: "421" },
{ text: "Slovenia", value: "386" },
{ text: "Solomon Islands", value: "677" },
{ text: "Somalia", value: "252" },
{ text: "South Africa", value: "27" },
{ text: "Spain", value: "34" },
{ text: "Sri Lanka", value: "94" },
{ text: "St. Helena", value: "290" },
{ text: "St. Kitts", value: "1869" },
{ text: "St. Lucia", value: "1758" },
{ text: "Sudan", value: "249" },
{ text: "Suritext", value: "597" },
{ text: "Swaziland", value: "268" },
{ text: "Sweden", value: "46" },
{ text: "Switzerland", value: "41" },
{ text: "Syria", value: "963" },
{ text: "Taiwan", value: "886" },
{ text: "Tajikstan", value: "7" },
{ text: "Thailand", value: "66" },
{ text: "Togo", value: "228" },
{ text: "Tonga", value: "676" },
{ text: "Trinidad & Tobago", value: "1868" },
{ text: "Tunisia", value: "216" },
{ text: "Turkey", value: "90" },
{ text: "Turkmenistan", value: "7" },
{ text: "Turkmenistan", value: "993" },
{ text: "Turks & Caicos Islands", value: "1649" },
{ text: "Tuvalu", value: "688" },
{ text: "Uganda", value: "256" },
{ text: "Ukraine", value: "380" },
{ text: "United Arab Emirates", value: "971" },
{ text: "Uruguay", value: "598" },
{ text: "Uzbekistan", value: "7" },
{ text: "Vanuatu", value: "678" },
{ text: "Vatican City", value: "379" },
{ text: "Venezuela", value: "58" },
{ text: "Vietnam", value: "84" },
{ text: "Virgin Islands - British", value: "1284" },
{ text: "Virgin Islands - US", value: "1340" },
{ text: "Wallis & Futuna", value: "681" },
{ text: "Zambia", value: "260" },
{ text: "Zimbabwe", value: "263" },
];

export default area_values;