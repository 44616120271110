<template>
	<div>
		<v-container fluid class="d-flex align-center categories-container my-0 py-1" :style="`background: ${$settings.navBarCatergoriesBackogrund}`">
			<div>
				<v-btn
					class="category-navigation-all"
					plain
					small
					color="white"
					@cho="showCategoriesDialog = !showCategoriesDialog"
					@click="showCatsNav = true"
				><v-icon class="mr-2" small>mdi-menu</v-icon>{{ $vuetify.breakpoint.lgAndUp ? $t('products.all_categories') : $t('categories') }}</v-btn>
			</div>
			<div class="d-flex justify-center category-navigation-each" style="width: 75%;" v-if="$vuetify.breakpoint.lgAndUp">
				<v-slide-group v-if="false" multiple>
					<v-slide-item v-for="(c, index) in categories" :key="index">
						<v-menu
							v-model="c.active"
							offset-y
							:close-on-content-click="false"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn 
                  small 
                  v-bind="attrs" 
                  v-on="on" 
                  plain
                  color="white"
									class="px-2"
                >{{ c.header }}</v-btn>
							</template>
							<v-sheet :width="350">
								<div class="pa-3 font-weight-bold">{{ c.header }}</div>
								<v-divider></v-divider>
								<v-list dense>
									<v-list-item v-for="(sub, ind) in c.subcategories" :key="ind">
										<v-list-item-title>
											<v-checkbox
												v-model="sub.selected"
												dense
												:label="sub.name"
											></v-checkbox>
										</v-list-item-title>
									</v-list-item>
								</v-list>
								<div class="pa-3">
									<v-btn
										color="primary"
                    small
                    depressed
										@click="filterCategories(c)"
										>{{ $t("apply_filters") }}</v-btn
									>
								</div>
							</v-sheet>
						</v-menu>
					</v-slide-item>
				</v-slide-group>
				<v-slide-group multiple>
					<v-slide-item v-for="(c, index) in categories" :key="index">
						<v-btn 
							small 
							plain
							@mouseenter="catFocused = c.id"
							@click="catFocused = c.id, showCatsNav = true"
							color="white"
							class="px-2"
						>{{ c.header }}</v-btn>
					</v-slide-item>
				</v-slide-group>
			</div>
			<v-spacer></v-spacer>
			<div>
				<v-btn
					class="align-self-end"
					small
					text
					depressed
					:dark="onlyFavorites"
					:color="onlyFavorites ? 'white' : 'grey lighten-5'"
					@click="toggleFavorites"
				><v-icon class="mr-2" small>{{ onlyFavorites ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>{{ $t('products.my_favorites') }}</v-btn>
			</div>
		</v-container>
		<v-dialog
      transition="dialog-top-transition"
      v-model="showCategoriesDialog"
      max-width="1080"
    >
      <v-card>
        <v-card-title>{{ $t('products.filter_by_category') }}</v-card-title>
        <v-card-text>
          <div class="cat-menu">
            <v-row
              v-for="(c, i) in groupedCats"
              :key="i"
            >
              <v-col
                :cols="$vuetify.breakpoint.lgAndUp ? 12/catCols : 12"
                v-for="(cat, i) in c"
                :key="`_${i}`"
              >
                <h4>{{ cat.header }}</h4>
                <ul class="pl-3">
                  <li
                    v-for="(sub) in cat.subcategories"
                    :key="sub.id"
                  >
                    <label class="d-flex align-center"><input type="checkbox" v-model="sub.selected" class="mr-2">{{ sub.name }}</label>
                  </li>
                </ul>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            depressed
            @click="filterCategories()"
          >{{ $t('apply_filters') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
		<categories-nav v-if="showCatsNav" v-model="showCatsNav" :categories="categories" :focus-on-open="catFocused"></categories-nav>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import categoriesNav from './categoriesNav.vue';

export default {
	name: "CategoriesNavigation",
	components: { categoriesNav },
	data() {
		return {
			menu: false,
			catCols: 4,
      showCategoriesDialog: false,
			showCatsNav: false,
			catFocused: null
		};
	},
	computed: {
		...mapGetters([
			"categories",
			"selectedCategories",
			"user",
			"displayCategories",
			"onlyFavorites"
		]),
		...mapGetters("cart", ["count"]),
		groupedCats() {
      var grouped = [];
      var cols = this.catCols;
      var total_items = this.categories.length;
      var total_groups = Math.ceil(cols/total_items);
      this.categories.forEach((c, ind) => {
        var group = Math.ceil((ind+1)/cols);
        var group_ind = group-1;
        if((ind/cols) < group) {
          if(grouped[group_ind] === undefined) {
            grouped.push([c]);
          } else {
            grouped[group_ind].push(c);
          }
        } else {
          if(grouped[total_groups-1] === undefined) {
            grouped.push([c]);
          } else {
            grouped[total_groups-1].push(c);
          }
        }
      });
      return grouped;
    }
	},
	methods: {
		...mapMutations(["selectCategories", "assignCategories", "isLoading"]),
		...mapActions(["loadCategories", "loadProducts", "toggleFavorites"]),
		async getCat() {
			if (this.categories.length === 0) {
				this.loadCategories();
			} else {
				this.isLoading(false);
			}
		},
		async filterCategories(c) {
			if(c) {
				c.active = false;
			} else {
				this.showCategoriesDialog = false;
			}
			this.selectCategories();
			await this.loadProducts({ changePageSize: true });
		},
	},
	created() {
		this.getCat();
    //console.log(this.$store.state);
	},
};
</script>

<style lang="scss" scoped>
.menu-links {
	color: #ccc;
	font-size: 12px;
}
</style>

<style lang="scss">
.categories-container {
	.v-btn {
		text-transform: none;
		font-weight: 400;
		.v-btn__content {
			opacity: 1 !important;
		}
	}
  .v-slide-group {
    i {
      &:before, &:after {
        color: #FFF !important;
				font-size: 12px;
      }
    }
    .v-slide-group__wrapper {
      align-items: center;
    }
		.v-slide-group__prev, .v-slide-group__next {
			min-width: 10px !important;
			max-width: 25px;
		}
  }
}
</style>