<template>
  <v-container>
    <page-header :title="$t('your_cart')">
      <v-btn
        small
        depressed
        @click="$router.push({ name: 'Catalogue' })"
      >{{ $t('continue_shopping') }}</v-btn>
    </page-header>
    <cart-content ref="cart" checkout></cart-content>
    <v-container class="mt-5">
      <v-row>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!profileIncomplete"
          class="mr-3"
          color="primary"
          depressed
          type="submit"
          :loading="loading"
          :disabled="count == 0"
          @click.prevent="confirm"
          x-large
        >
          <span class="white--text">{{ count == 0 ? $t('no_items') : $t('cart.proceed_to_payment')+' '+numberToNiceString(required_payment, getCurrency, 2) }}</span>
        </v-btn>
        <div v-else><a href="#" @click.prevent="$router.push({ name: 'Account', hash: '#company' })">{{ $t('cart.please_complete_profile') }}</a></div>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import cartContent from '../../components/checkout/cartContent.vue';

export default {
  name: 'OrderCheckout',
  components: { cartContent },
  data() {
    return {
      loading: false,
      order: {},
      payment_id: '',
      notify: true
    }
  },
  computed: {
    reference() {
      return this.$refs.cart.reference;
    },
    ...mapGetters('cart', [
      'products',
      'count',
      'subtotal',
      'tax',
      'total',
      'incoterm',
      'required_payment',
      'deposit_percent',
      'cart_type'
    ]),
    ...mapGetters([
      'getCurrency',
      'vColor',
      'profileIncomplete',
      'user'
    ])
  },
  methods: {
    ...mapActions('cart', [
      'confirmOrder',
      'clearCart'
    ]),
    async confirm() {
      this.loading = true;
      const order = await this.confirmOrder({
        reference: this.reference
      });
      if(order) {
        this.$router.push({ name: 'Checkout', params: { payment_secret: order.payment.protected_id } });
      } else {
        this.loading = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.totals {
  width: 100%;
  padding-top: 0px;
}
</style>