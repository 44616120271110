<template>
  <div class="image-wrapper">
    <div>
      <div id="main-slider" class="splide">
        <div class="splide__track">
          <ul class="splide__list">
            <li
              v-for="image in imgs.normal_size" :key="image.id"
              :class="['splide__slide']"
            >
              <img :src="image.url">
            </li>
          </ul>
        </div>
      </div>
      <div style="width: 100%; height: 10px;"></div>
      <div id="thumbnail-slider" class="splide">
        <div class="splide__track">
          <ul class="splide__list">
            <li
              v-for="image in imgs.thumbs" :key="image.id"
              :class="['splide__slide', 'image_'+image.id]"
              style="text-align: center;"
            >
              <img style="width: auto; height: 100%" :src="image.url">
            </li>
          </ul>
        </div>
      </div>
      <div style="width: 100%; height: 10px;"></div>
    </div>
    <img v-if="imgs.length === 0" style="width: 100%; height: auto;" :src="noImage" alt="Not found">
  </div>
</template>

<script>
import noImage from '../../assets/images/not-found.png';
import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide.min.css';

export default {
  name: 'productImages',
  props: ['images'],
  data() {
    return {
      'zoomerOptions': {
        zoomFactor: 3, // scale for zoomer
        pane: 'container', // three type of pane ['pane', 'container-round', 'container']
        hoverDelay: 300, // how long after the zoomer take effect
        namespace: 'zoomer', // add a namespace for zoomer component, useful when on page have mutiple zoomer
        move_by_click: true, // move image by click thumb image or by mouseover
        scroll_items: 4, // thumbs for scroll
        choosed_thumb_border_color: "#bbdefb", // choosed thumb border color
        scroller_button_style: "line",
        scroller_position: "bottom",
        zoomer_pane_position: "right"
      },
      noImage
    }
  },
  computed: {
    imgs() {
      var imgs = {
        thumbs: [],
        normal_size: [],
        large_size: []
      };
      if(this.images.length > 0) {
        for(var i in this.images) {
          imgs.thumbs.push({
            'id': this.images[i].id,
            'url': this.images[i].thumb
          });
          imgs.normal_size.push({
            'id': this.images[i].id,
            'url': this.images[i].small
          });
          imgs.large_size.push({
            'id': this.images[i].id,
            'url': this.images[i].large
          });
        }
      }
      return imgs;
    }
  },
  mounted() {
    var slider = new Splide( '#main-slider', {
      type      : 'fade',
      rewind    : true,
      pagination: false,
      arrows    : false,
    } );
    var thumbnails = new Splide( '#thumbnail-slider', {
      gap       : 10,
      perPage: 5,
      focus  : 'center',
      rewind    : true,
      pagination: false,
      isNavigation: true,
      //padding: '20%',
      breakpoints : {
        600: {
          fixedWidth : 60,
          fixedHeight: 44,
          padding: '20%'
        },
      },
    } );
    slider.sync( thumbnails );
    slider.mount();
    thumbnails.mount();
  }
}
</script>

<style lang="scss">
.splide__slide img {
  width: 100%;
  height: auto;
}
.thumb-list {
  display: flex !important;
  justify-content: space-between;
  img {
    max-width: 45px;
  }
}
</style>