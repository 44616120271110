import controller from './controller';
import { 
  PAYMENT_BT_TOKEN, 
  PAYMENT_BT_SUBMIT, 
  PAYMENT_BT_RETRIEVE, 
  PAYMENT_BT_DELETE,
  PAYMENT_GET,
  PAYMENT_BT_UPDATE,
  PAYMENT_CREATE_PAYPAL_ORDER,
  PAYMENT_CAPTURE_PAYPAL_ORDER,
  PAYMENT_KLARNA_GET_TOKEN,
  PAYMENT_KLARNA_CREATE_ORDER
} from '../constants/endpoints.js';

export default class paymentController extends controller {

  async getBtPaymentMethods() {
    return new Promise((resolve) => {
      this.sendRequest('GET', PAYMENT_BT_RETRIEVE, {}).then(resp => {
        if(resp.data.result === true) {
          resolve(resp.data.data);
        }
      });
    });
  }

  async deleteBtPaymentMethod(params) {
    return new Promise((resolve) => {
      this.sendRequest('GET', PAYMENT_BT_DELETE, params).then(resp => {
        if(resp.data.result === true) {
          resolve(resp.data.data);
        }
      });
    });
  }

  async updateBtPaymentMethod(params) {
    return new Promise((resolve) => {
      this.sendRequest('POST', PAYMENT_BT_UPDATE, params).then(resp => {
        if(resp.data.result === true) {
          resolve(resp.data.data);
        }
      });
    });
  }

  async getBtToken(params) {
    return new Promise((resolve) => {
      this.sendRequest('GET', PAYMENT_BT_TOKEN, params).then(resp => {
        if(resp.data.result === true) {
          resolve(resp.data.data);
        }
      });
    });
  }

  async submitBtPayment(params) {
    return new Promise((resolve) => {
      this.sendRequest('POST', PAYMENT_BT_SUBMIT, params).then(resp => {
        resolve(resp.data);
      });
    });
  }

  async getPayment(secret) {
    return new Promise((resolve) => {
      this.sendRequest('GET', this.buildEndpoint(PAYMENT_GET, { protected_id: secret}), {})
      .then(resp => {
        resolve(resp.data);
      });
    });
  }

  async createPaypalOrder(params) {
    return new Promise((resolve) => {
      this.sendRequest('GET', this.buildEndpoint(PAYMENT_CREATE_PAYPAL_ORDER), params)
      .then(resp => {
        resolve(resp.data);
      });
    });
  }

  async capturePaypalOrder(params) {
    return new Promise((resolve) => {
      this.sendRequest('GET', this.buildEndpoint(PAYMENT_CAPTURE_PAYPAL_ORDER), params)
      .then(resp => {
        resolve(resp.data.data);
      });
    });
  }

  async getKlarnaToken(params) {
    try {
      var klarna = await this.sendRequest('GET', this.buildEndpoint(PAYMENT_KLARNA_GET_TOKEN), params);
      if(klarna.data.result) {
        return klarna.data.data;
      } else {
        throw klarna.data;
      }
    } catch(e) {
      console.error(e.response)
      console.log('controllers/paymentController.js?getKlarnaToken', e);
    }
  }

  async createKlarnaOrder(params) {//require authorization_token & payment_secret
    return new Promise((resolve) => {
      this.sendRequest('GET', this.buildEndpoint(PAYMENT_KLARNA_CREATE_ORDER), params)
      .then(resp => {
        resolve(resp.data);
      });
    });
  }

}