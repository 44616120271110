
import catalogueController from '../controllers/catalogueController';
import mixins from '../mixins';
import router from '../router';
const cat = new catalogueController();

const defaultState = () =>  {
  return {
    categories: [],
    selectedCategories: [],
    showCategories: true,
    onlyExpress: false,
    onlyOffers: false,
    products: [], 
    productDetailsDialog: false,
    productDetailsLoading: false,
    productDetails: null,
    onlyFavorites: false,
    catalogueSearch: '',
    pagination: {
      pageSize: 25,
      pageNumber: 1,
      totalPages: 1
    }
  }
};

const catalogue = {
  state: defaultState(),
  mutations: {
    assignCategories(state, categories) {
      state.categories = categories;
    },
    selectCategories(state) {
      for(var i in state.categories) {
        for(var s in state.categories[i].subcategories) {
          if(state.categories[i].subcategories[s].selected) {
            if(!state.selectedCategories.find(sc => sc.id === state.categories[i].subcategories[s].id)) {
              state.selectedCategories.push(state.categories[i].subcategories[s]);
            }
          } else {
            //this remove the categories
            state.selectedCategories = state.selectedCategories.filter(sc => sc.id !== state.categories[i].subcategories[s].id);
          }
        }
      }
      //reset to page 1 on category change
      state.pagination.pageNumber = 1;
    },
    assignCategorySelection(state, selected) {
      state.selectedCategories = selected;
    },
    resetCatalogue(state) {
      Object.assign(state, defaultState());
    },
    softResetCatalogue(state) {
      //first remove categories.selected from each subcategory
      for(var m in state.categories) {
        for(var s in state.categories[m].subcategories) {
          if(state.categories[m].subcategories[s].selected) {
            state.categories[m].subcategories[s].selected = false;
          }
        }
      }
      state.selectedCategories = [];
      state.showCategories = true;
      state.onlyExpress = false;
      state.onlyOffers = false;
      state.products = [];
      state.productDetailsDialog = false;
      state.productDetailsLoading = false;
      state.productDetails = null;
      state.onlyFavorites = false;
      state.catalogueSearch = '';
      state.pagination = {
        pageSize: 25,
        pageNumber: 1,
        totalPages: 1
      };
    },
    toggleProductDialog(state, boolean) {
      if(!boolean) {
        mixins.methods.removeParamsToLocation(router.history.current.path);
        state.productDetailsLoading = false;
        state.productDetails = null;
      }
      state.productDetailsDialog = boolean;
    },
    attachFavoriteToCurrentResult(state, product_id) {
      var p = state.products.find(p => p.id === product_id);
      if(p) {
        p.is_favorite = p.is_favorite === 1 ? 0 : 1;
      }
    },
    setCatalogueSearch(state, search) {
      state.catalogueSearch = search;
    },
    toggleCategories(state) {
      state.showCategories = !state.showCategories;
    },
  },
  actions: {
    async userDefaultCategories({getters}, cats) {
      var preferred = getters.user.company_detail.category_ids;
      var categories = [];
      await cats.map(c => {
        if(c.parent_name === undefined) {
          categories.push(c);
        } else {
          var subc = categories.find(fc => fc.id === c.group);
          if(subc.subcategories === undefined) {
            if(preferred.includes(c.group)) {
              c.selected = true;
            }
            subc.subcategories = [c];
          } else {
            if(preferred.includes(c.group)) {
              c.selected = true;
            }
            subc.subcategories.push(c);
          }
        }
      });
      return categories;
    },
    async loadCategories({commit, dispatch}) {
      commit('isLoading', true, { root: true });
      var cats = await cat.getCategories();
      var pref = await dispatch('userDefaultCategories', cats);
      commit('assignCategories', pref);
      commit('selectCategories');
      this.dispatch('loadProducts', { changePageSize: true });
    },
    async loadProducts({state, getters, commit}, { changePageSize = false, reset = false }) {
      commit('isLoading', true, { root: true });
      if(reset) {//soft reset catalogue params
        commit('softResetCatalogue');
      }
      const result = await cat.getProducts(getters.catalogueParams);
      if(result) {
        state.products = result.items;
        state.pagination = {
          pageSize: result.pagination.pageSize,
          pageNumber: changePageSize ? 1 : result.pagination.currentPage*1,
          totalPages: result.pagination.totalPages
        };
        if(router.history.current.name !== 'Catalogue') {
          router.push({ name: 'Catalogue' });
        }
      }
      commit('isLoading', false, { root: true });
    },
    removeSelectedCategory({state}, category_id) {
      for(var i in state.categories) {
        for(var s in state.categories[i].subcategories) {
          if(state.categories[i].subcategories[s].id === category_id) {
            state.categories[i].subcategories[s].selected = false;
          }
        }
      }
      state.selectedCategories = state.selectedCategories.filter(sc => sc.id !== category_id);
      //reset to page 1
      state.pagination.pageNumber = 1;
      this.dispatch('loadProducts', {});
    },
    async loadProductDetails({ state, rootGetters, commit }, product_id) {
      commit('isLoading', true, { root: true });
      state.productDetailsLoading = true;
      const product = await cat.getProduct(product_id, rootGetters.getCurrency);
      state.productDetails = product;
      state.productDetailsLoading = false;
      commit('isLoading', false, { root: true });
    },
    toggleFavorites({ state }) {
      state.onlyFavorites = !state.onlyFavorites;
      this.dispatch('loadProducts', {});
    },
    toggleExpress({ state }) {
      state.onlyExpress = !state.onlyExpress;
      this.dispatch('loadProducts', {});
    },
    toggleOffers({ state }) {
      state.onlyOffers = !state.onlyOffers;
      this.dispatch('loadProducts', {});
    }
  },
  getters : {
    categories: state => state.categories,
    selectedCategories: state => state.selectedCategories,
    selectedCategoriesId: (state, getters) => {
      return getters.selectedCategories.map(c => {
        return c.id;
      });
    },
    catalogueProducts: state => state.products,
    catalogueParams: (state, getters) => {
      return {
        currency: getters.getCurrency,
        pageSize: state.pagination.pageSize,
        pageNumber: state.pagination.pageNumber,
        incoterm: 'REVOOLOOP',
        only_offers: state.onlyOffers ? 1 : 0,
        flash: state.onlyExpress ? 1 : 0,
        filter: JSON.stringify({
          categories: getters.selectedCategoriesId,
          keywords: getters.catalogueSearch,
          ref: '',
          favorite: state.onlyFavorites ? 1 : 0
        })
      };
    },
    pagination: state => state.pagination,
    productDetailsDialog: state => state.productDetailsDialog,
    productDetailsLoading: state => state.productDetailsLoading,
    productDetails: state => state.productDetails,
    onlyFavorites: state => state.onlyFavorites,
    catalogueSearch: state => state.catalogueSearch,
    displayCategories: state => state.showCategories,
    onlyExpress: state => state.onlyExpress,
    onlyOffers: state => state.onlyOffers
  }
}

export default catalogue