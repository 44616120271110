<template>
  <!-- App.vue -->
  <v-app 
    :style="{ 
      backgroundColor: isAuthenticated ? '#FFF' : '#f5f6f7'
    }"
    :class="[{ 
      'bg-gradient': !isAuthenticated,
      'bg-sps': $settings.name === 'Sourcing Specialists'
    }]"
  >

    <v-overlay v-if="isLoading" :value="isLoading" style="z-index: 99;">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <header
      class="top-header"
      v-if="isAuthenticated"
    >
      <top-nav-bar></top-nav-bar>
    </header>

    <v-container v-if="isAuthenticated">
      <ul class="d-flex">
        <li
          v-for="(b, i) in breadcrumbs"
          :key="i"
          style="font-size: 15px; color: rgba(0,0,0,0.7);"
        >
          <router-link v-if="i !== (breadcrumbs.length -1)" :to="b.to">{{ b.text }}<span v-if="i !== (breadcrumbs.length -1)" class="mx-2">/</span></router-link>
          <span v-else>{{ b.text }}</span>
        </li>
      </ul>
    </v-container>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container class="main">
        <incomplete-profile-banner v-if="false"></incomplete-profile-banner>
        <vue-page-transition name="fade-in-right">
          <!-- If using vue-router -->
          <router-view :key="$route.fullPath"></router-view>
        </vue-page-transition>
      </v-container>
    </v-main>

    <v-dialog v-model="showHelper" max-width="960">
      <Terms v-if="helpersModal.data === 'terms'" />
      <PrivacyPolicy v-if="helpersModal.data === 'privacy_policy'" />
      <div v-if="helpersModal.data === 'buyer_protection'">Info about the buyer protection</div>
      <div v-if="helpersModal.data === 'to_pay'">Info about to pay</div>
      <ContactForm v-if="helpersModal.data === 'contact_form'" />
    </v-dialog>

    <!--:app="$vuetify.breakpoint.mdAndUp"-->
    <v-footer class="app-footer">
      <footer-content></footer-content>
    </v-footer>
  </v-app>
</template>

<script>
  import TopNavBar from './components/nav/TopNavBar.vue';
  import incompleteProfileBanner from './components/banners/incompleteProfile.vue';
  import Terms from './components/terms.vue';
  import PrivacyPolicy from './components/miscellaneous/privacyPolicy.vue';
  import footerContent from './components/miscellaneous/footerContent.vue';
  import ContactForm from './components/miscellaneous/contactForm.vue';
  import { mapMutations, mapGetters } from 'vuex';
  import { routes } from './router';

  export default {
    name: 'Layout',
    data() {
      return {
        side: true,
      }
    },
    components: {
      TopNavBar,
      incompleteProfileBanner,
      Terms,
      footerContent,
      ContactForm,
      PrivacyPolicy
    },
    computed: {
      ...mapGetters('cart', [
        'showCart',
      ]),
      ...mapGetters([
        'isAuthenticated',
        'user',
        'isLoading',
        'profileIncomplete',
        'showTerms',
        'buyerProtectionDialog',
        'helpersModal'
      ]),
      breadcrumbs() {
        var b = [
          {
            text: 'Home',
            disabled: false,
            to: '/',
          }
        ];
        if(this.$route.name !== 'home' && this.$route.meta.breadcrumbs) {
          //parents
          if(this.$route.meta.breadcrumbs.parents && this.$route.meta.breadcrumbs.parents.length > 0) {
            var parents = [];
            this.$route.meta.breadcrumbs.parents.forEach(p => {
              parents.push(routes.find(r => r.name === p));
            });
            parents.forEach(p => {
              b.push({
                text: p.meta.breadcrumbs.title,
                to: p.path
              });
            });
          }
          b.push({
            text: this.$route.meta.breadcrumbs.title,
            to: this.$route.path
          });
        }
        return b;
      },
      displayTerms: {
        get() {
          return this.showTerms;
        },
        set() {
          this.toggleTerms();
        }
      },
      showHelper: {
        get() {
          return this.helpersModal.show;
        },
        set() {
          this.toggleHelpers({ show: false, data: null });
        }
      }
    },
    methods: {
      ...mapMutations(['toggleHelpers']),
    },
    beforeMount() {
      this.$store.commit('isLoading', true);
    },
    async mounted() {
      await this.$store.dispatch('getClientLocation');
      this.$store.commit('isLoading', false);
    }
  }
</script>
<style scoped>
.top-header {
  position: sticky;
  top: 0px;
  z-index: 5;
}
.container {
  position: inherit;
}
</style>
<style lang="scss">
.app-footer {
  z-index: 6 !important;
}
.breadcrumbs {
  border-bottom: 1px solid #E2E2E2;
}
.bg-gradient {
  &:before {
    content: " ";
    position: absolute;
    background: linear-gradient(-45deg, #2FAF64, #723FC9, #2FAF64, #723FC9);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    height: 100%;
    z-index: 0;
    top: 0;
    width: 100%;
  }
  &.bg-sps {
    &:before {
      content: " ";
      position: absolute;
      background: #B71C1C;
      background-size: 100% 100%;
      height: 100%;
      z-index: 0;
      top: 0;
      width: 100%;
    }
  }
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
</style>
