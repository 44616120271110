const mixins = {
  data() {
    return {

    }
  },
  methods: {
    buildSelectedItem(option, delivery_option) {
      var selected = this.getSelectedAttributes(option);
      var is_flash = this.selectionIsFlash(option);
      var offer = option.offer;
      var has_offer = option.has_offer;

      //there is an error and is called as observer 1 time
      if(!option.id) {
        return {};
      }

      //we need to check the prices and delivery time of the flash if exist
      var unsold_price = {};
      var target_delivery = option.option_unsold_latest ? option.option_unsold_latest.eta_string : null;
      var option_unsold_arrived = false;
      var ref_purchase_order_product_id = null;
      var ref_warehouse_product_id = null;
      var available_quantity = null;
      if(is_flash && delivery_option === 'express') {
        unsold_price = option.option_unsold_latest.price;
        ref_purchase_order_product_id = option.option_unsold_latest.ref_purchase_order_product_id;
        ref_warehouse_product_id = option.option_unsold_latest.ref_warehouse_product_id;
        available_quantity = option.option_unsold_latest.available_quantity;
        option_unsold_arrived = (option.option_unsold_latest.ref_warehouse_product_id !== null) ? true : false;
        offer = option.option_unsold_latest.offer;
        has_offer = option.option_unsold_latest.offer !== null ? true : null;
      }

      //flash
      var idd_flash = is_flash ? '.flash' : '';
      var attr_ids = selected.map(s => s.attribute_id);
      const idd = `${option.id}.${attr_ids.join('.')}${idd_flash}`; //product + attributes

      var result = {
        idd: idd,
        selected_attribute_ids: attr_ids,
        selected_attributes: selected,
        description: option.description,
        is_flash: is_flash,
        group_key_slug: option.group_key_slug,
        group_name: option.group_name,
        group_value: option.group_value,
        has_offer: has_offer,
        id: option.id,
        images: option.images,
        min_order: (is_flash && delivery_option === 'express') ? 1 : option.min_order,
        offer: offer,
        option_unsold_factory: option.option_unsold_factory,
        option_unsold_shipment: option.option_unsold_shipment,
        option_unsold_stock: option.option_unsold_stock,
        option_unsold_target_delivery: target_delivery,
        option_unsold_arrived: option_unsold_arrived,
        packing: option.packing,
        price: option.price,
        price_unsold: unsold_price,
        quantity: option.quantity,
        quantity_per_carton: option.quantity_per_carton,
        ref: option.ref,
        sku: option.sku,
        specifications: option.specifications,
        unsold_attrs: option.unsold_attrs,
        weight_per_carton: option.weight_per_carton,
        ref_purchase_order_product_id: ref_purchase_order_product_id,
        ref_warehouse_product_id: ref_warehouse_product_id,
        available_quantity: available_quantity
      };
      return result;
    },
    getSelectedAttributes(option) {
      var as = [];
      if(option.attributes_grouped !== undefined) {
        for(var i in option.attributes_grouped) {
          if(option.attributes_grouped[i].selected) {
            var selected = option.attributes.find(a => a.attribute_id === option.attributes_grouped[i].selected);
            as.push(selected);
          } else {
            as.push(option.attributes_grouped[i][0]);
          }
        }
      }
      return as;
    },
    selectionIsFlash(option) {
      var selected = this.getSelectedAttributes(option).map(s => s.attribute_id);
      var express_attrs = option.unsold_attrs;
      if(option.is_flash === 1) {
        return selected.every(v => express_attrs.includes(v));
      }
      return false;
    },
    attrAppliesForExpress(attr_id, option) {
      if(option.unsold_attrs.includes(attr_id)) {
        return true;
      }
      return false;
    },
  }
}

export default mixins