<template>
  <div style="position: relative;">
    <loader v-model="loading"></loader>
    <v-card
      outlined
    >
      <v-card-title class="justify-space-between">
        <div><v-icon>mdi-format-list-checks</v-icon> {{ $t('orders.order_total') }}</div>
        <div class="font-weight-bold">{{ order.total }}</div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div v-if="payments.pending.length > 0" class="font-weight-bold">{{ $t('payments') }}</div>
        <div v-else>{{ $t('orders.no_required_payments') }}</div>
        <div
          v-for="p in payments.pending"
          :key="p.id"
          class="d-flex justify-space-between align-center"
        >
          <div class="pa-1">
            <h4>{{ p.type_string }}</h4>
            <div v-if="p.status === 'Pending'">{{ `${$t('orders.expiring_at')}: ${p.expired_at}` }}</div>
            <div 
              :class="['font-weight-bold', { 'red--text': p.status === 'Pending' }, { 'green--text': p.status !== 'Pending' }]"
            >{{ p.status_string }}</div>
          </div> 
          <div class="font-weight-bold text-right" style="color: #000;">
            <div>{{ p.amount_string }}</div>
            <v-btn
              v-if="p.status === 'Pending'"
              class="ma-2"
              x-small
              rounded
              :color="p.reason === 'balance' ? 'blue' : 'warning'"
              :to="{ name: 'Checkout', params: { payment_secret: p.protected_id } }"
              depressed
            >
              <span class="white--text">{{ $t('orders.pay_now', { reason: '' }) }}</span>
            </v-btn>
          </div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text>
        <div v-if="false" class="d-flex justify-space-between align-center">
          <h4>{{ $t('payment.total_paid') }}</h4>
          <div>{{ order.paid }}</div>
        </div>
        <div class="d-flex justify-space-between align-center">
          <h3>{{ $t('payment.pending_amount') }}</h3>
          <h3 style="color: #000;">{{ order.outstanding }}</h3>
        </div>
      </v-card-text>
    </v-card>
    <v-card
      class="mt-6"
      outlined
    >
      <v-card-title class="justify-space-between">
        <div><v-icon>mdi-format-list-numbered</v-icon> {{ $t('orders.payments_history') }}</div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div v-if="payments.done.length === 0" class="font-weight-bold">{{ $t('orders.no_payments_done') }}</div>
        <div
          v-for="p in payments.done"
          :key="p.id"
          class="d-flex justify-space-between align-center"
        >
          <div class="pa-1">
            <h4>{{ p.paid_at }}</h4>
          </div> 
          <div class="font-weight-bold text-right" style="color: #000;">
            <div>{{ p.amount_string }}</div>
          </div>
          <v-btn
            :href="p.invoice_url"
            target="_blank"
            depressed
            x-small
            text
          >{{ $t('orders.download_invoice') }}</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import orderController from '../../controllers/orderController';
import loader from '../loadingBox.vue';

const oc = new orderController();

export default {
  name: 'orderReview',
  props: ['order'],
  components: {
    loader
  },
  data() {
    return {
      loading: true,
      payments: {
        done: [],
        pending: []
      }
    }
  },
  watch: {
    order() {
      //console.log(this.order)
      this.getOrderPayments();
    }
  },
  methods: {
    async getOrderPayments() {
      if(this.order.id) {
        this.loading = true;
        const payments = await oc.getOrderPayments(this.order.id);
        payments.items.forEach(p => {
          if(p.status === 'Completed') {
            this.payments.done.push(p);
          } else {
            this.payments.pending.push(p);
          }
        });
        //console.log(this.payments);
        this.loading = false;
      }
    },
  },
  mounted() {
    if(this.order.id) {
      this.getOrderPayments();
    }
  }
}
</script>