<template>
  <v-form ref="companyBillingAddress" class="pa-5" @submit.prevent="updateCompanyBillingAddress">
    <v-card-text>
      <v-row>
        <v-col>
          <v-select
            v-model="add.country_id"
            :rules="requiredRule"
            :label="$t('country')"
            :items="[{ text: 'España', value: 17}]"
            disabled
          ></v-select>
        </v-col>
      </v-row>
      <v-row v-if="add.type === 'DELIVERY'">
        <v-col>
          <v-text-field
            v-model="add.alias"
            :label="$t('user_account.address_alias')"
            :placeholder="$t('user_account.alias_placeholder')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="add.type === 'DELIVERY'">
        <v-col>
          <v-text-field
            v-model="add.recipient_first_name"
            :label="$t('payment.first_name')"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="add.recipient_last_name"
            :label="$t('payment.last_name')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="add.line_one"
            :label="$t('user_account.address_line_one')+'*'"
            :rules="requiredRule"
            :placeholder="$t('user_account.address_line_one_placeholder')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="add.line_two"
            :label="$t('user_account.address_line_two')"
            :placeholder="$t('user_account.address_line_two_placeholder')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <v-text-field
            v-model="add.city"
            :label="$t('user_account.city')+'*'"
            :rules="requiredRule"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="add.postal_code"
            :label="$t('user_account.postal_code')+'*'"
            :rules="requiredRule"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="add.type === 'DELIVERY'">
        <v-col>
          <v-checkbox 
            v-model="defaultAdd"
            dense
            :label="$t('user_account.use_as_default')"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn
        @click.prevent="updateBillingAddress"
        color="primary"
        depressed
        type="submit"
        dark
        :loading="loading"
      >{{ $t('save') }}</v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import userController from '../../controllers/userController';

const u = new userController();

export default {
  name: 'companyAddressForm',
  props: {
    address: {
      type: Object,
      default() {
        return {

        }
      },
      required: true
    }
  },
  data() {
    return {
      loading: false,
      add: {
        id: null,
        uuid: null,
        type: 'DELIVERY',
        is_default:  0,
        recipient_first_name: this.$store.getters.user.name,
        recipient_last_name: this.$store.getters.user.last_name,
        recipient_company: this.$store.getters.company.name,
        line_one: '',
        line_two:  '',
        city:  '',
        postal_code:  '',
        phone: this.$store.getters.company.tel,
        country_id: this.$store.getters.company.country_id,
        country_name: this.$store.getters.company.country_name,
        alias: ''
      }
    }
  },
  computed: {
    ...mapGetters(['user', 'company']),
    defaultAdd: {
      get() {
        return this.add.is_default === 0 ? false : true;
      },
      set(val) {
        this.add.is_default = val ? 1 : 0;
      }
    }
  },
  watch: {
    address() {
      this.add = this.address.type ? this.address : this.add;
    }
  },
  methods: {
    ...mapActions([
      'fetchUser'
    ]),
    async updateBillingAddress() {
      this.loading = true;
      if(await !this.$refs.companyBillingAddress.validate()) {
        this.loading = false;
        return;
      }
      try {
        await u.updateBillingAddress(this.add);
        this.$toasted.success(this.$t('information_updated'));
        if(this.add.uuid === null) {
          this.$emit('created');
        }
        this.fetchUser();
      } catch(e) {
        this.$toasted.error(this.$t('friendly_error'));
        console.log('components/account/companyDetails.vue?updateCompanyBillingAddress', e);
      }
      this.loading = false;
    },
  },
  created() {
    this.add = this.address.type ? this.address : this.add;
    this.debug([this.add])
  }
}
</script>