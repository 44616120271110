const firebaseConfig = {
  apiKey: "AIzaSyCyExtwC3b3dOUNlntJlU0Gcz0pSH8sD-w",
  authDomain: "sps-frontend-d09f5.firebaseapp.com",
  projectId: "sps-frontend-d09f5",
  storageBucket: "sps-frontend-d09f5.appspot.com",
  messagingSenderId: "490277216599",
  appId: "1:490277216599:web:22276c21548c71233c1e12",
  measurementId: "G-EN5C45E17V"
};

export default firebaseConfig