<template>
  <Transition name="slide-fade">
    <div 
      v-if="show" 
      class="categories-menu" 
      v-click-outside="{ 
        handler: handleClickOutside, 
        include: includes
      }"
    >
      <v-btn
        class="close"
        color="grey"
        plain
        x-large
        fab
        depressed
        @click="close()"
      ><v-icon x-large>mdi-close</v-icon></v-btn>
      <div class="categories-wrapper d-flex justify-space-between">
        <div class="maincategories-navigation">
          <v-subheader>{{ $t('products.main_categories') }}</v-subheader>
          <v-list dense>
            <v-list-item-group
              v-model="selected"
              color="primary"
            >
              <v-list-item
                v-for="c in categories"
                :key="c.id"
                :value="c.id"
              >
                <v-list-item-icon style="text-align: center;">
                  <v-icon>{{ c.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="c.header"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
        <div>
          <v-subheader>{{ $t('products.subcategories') }}</v-subheader>
          <v-scroll-y-transition mode="out-in">
            <div v-if="subcategories !== null" class="subcategories-navigation flex-grow-1">
              <v-list dense>
                <v-list-item-group
                  v-model="selected_subcategory_ids"
                  color="primary"
                  multiple
                >
                  <v-list-item
                    v-for="s in subcategories"
                    :key="s.id"
                    :value="s"
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox :input-value="active" dense></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-text="s.name"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>
          </v-scroll-y-transition>
        </div>
        <div>
          <v-subheader>{{ $t('products.selected_categories') }}</v-subheader>
          <v-scroll-y-transition mode="out-in">
            <div v-if="selected_subcategory_ids.length > 0" class="subcategories-navigation flex-grow-1">
              <v-chip-group column>
                <v-chip
                  v-for="s in selectedCategories"
                  :key="`_${s.id}`"
                  :value="s"
                  close
                  close-icon="mdi-close"
                  outlined
                  small
                  @click:close="remove(s.id)"
                >{{ s.name }}</v-chip>
              </v-chip-group>
            </div>
          </v-scroll-y-transition>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="d-flex">
        <v-spacer></v-spacer>
        <v-btn
          class="mt-3"
          color="primary"
          depressed
          @click="apply()"
        >{{ $t("apply_filters") }}</v-btn>
      </div>
    </div>
  </Transition>
</template>

<script>
import test from '@/assets/images/cleaning.png';
import { mapGetters, mapMutations, mapActions } from 'vuex';


export default {
  name: 'categoriesNav',
  props: ['value', 'categories', 'focus-on-open'],
  data() {
    return {
      selected: null,
      selected_initial_state: this.$store.getters.selectedCategories,
      selected_subcategory_ids: this.$store.getters.selectedCategories,
      subcategories: null,
      subs: [],
      test
    }
  },
  computed: {
    ...mapGetters([
      'selectedCategories'
    ]),
    show: {
      get() {
        return this.value;
      },
      set() {
        this.$emit('input', false);
      }
    },
  },
  watch: {
    async selected(val) {
      this.subcategories = null;
      var subs = await this.categories.find(c => c.id === val);
      if(subs) {
        this.$nextTick(() => {
          this.subcategories = subs.subcategories;
        });
      }
    },
    selected_subcategory_ids(val) {
      this.assignCategorySelection(val);
    },
    selected_initial_state(val) {
      console.log(val)
    },
    focusOnOpen() {
      this.selected = this.focusOnOpen;
    }
  },
  methods: {
    ...mapMutations(['assignCategorySelection']),
    ...mapActions(['removeSelectedCategory', 'loadProducts']),
    apply() {
      this.loadProducts({ changePageSize: true });
      this.$emit('input', false);
    },
    remove(id) {
      this.selected_subcategory_ids = this.selected_subcategory_ids.filter(s => s.id !== id);
      //this.removeSelectedCategory(id);
    },
    close() {
      var first = this.selected_initial_state.map(v => v.id);
      var second =  this.selected_subcategory_ids.map(v => v.id);
      var diff_1 = first.filter(x => second.indexOf(x) === -1);
      var diff_2 = second.filter(x => first.indexOf(x) === -1);
      if(diff_1.length > 0 || diff_2.length > 0) {
        if(confirm(this.$t('products.confirm_close_categories'))) {
          this.assignCategorySelection(this.selected_initial_state);
          this.$emit('input', false);
        }
      } else {
        this.$emit('input', false);
      }
    },
    handleClickOutside() {
      this.$emit('input', false);
    },
    includes() {
      //console.log(document.querySelector('.category-tree-navigation'));
      return [document.querySelector('.category-navigation-all'), document.querySelector('.category-navigation-each')];
    }
  },
  mounted() {
    this.selected = this.focusOnOpen ?? null;
    //console.log(this.categories);
  }
}
</script>

<style lang="scss" scoped>
.categories-menu {
  position: absolute;
  background-color: #FFF;
  width: 100%;
  min-height: 300px;
  opacity: 0.98;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 25px 50px;
  z-index: -1;
  .close {
    position: absolute;
    top: 0px;
    right: 0px;
  }
  .categories-wrapper {
    > div {
      width: 33%;
    }
    ul {
      li {
        padding: 5px 10px;
      }
    }
    .maincategories-navigation {
      ul {
        padding: 15px;
        li {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
    .subcategories-navigation {
      ul {
        padding: 15px;
        li {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transform: translateY(-20px);
  opacity: 0;
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter-to, .slide-fade-leave-from {
  transform: translateY(0px);
  opacity: 1;
}

.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>