<template>
  <!--<div class="d-block d-md-flex justify-space-between" style="width: 100%; position: relative;">
    <div>
      <v-btn
        @click="toggleHelpers({ show: true, data: 'terms' })"
        depressed
        link
        x-small
      >{{ $t('terms_conditions') }}</v-btn>
      <v-btn
        @click="toggleHelpers({ show: true, data: 'privacy_policy' })"
        depressed
        link
        x-small
      >{{ $t('privacy_policy') }}</v-btn>
    </div>
    <div>
      <span style="font-size: 12px;"><v-icon small class="mr-2">mdi-phone-message</v-icon>{{ $t('need_help') }}?</span>
      <v-btn
        @click="toggleHelpers({ show: true, data: 'contact_form' })"
        depressed
        x-small
      >{{ $t('contact_us') }}</v-btn>
    </div>
  </div>-->
  <!-- Footer -->
  <v-container class="py-10">
    <div class="foot-up">
      <v-row>
        <v-col cols="12">
          <div class="foot-lg">
            <img src="@/assets/images/brand/footer-logo.svg" alt="">
          </div>
        </v-col>
        <v-col cols="12">
          <p style="font-size: 16px">© Copyright 2022. All Rights Reserved.</p>
        </v-col>
      </v-row>
    </div>

    <div class="row">
      <div class="col-md-3 col-sm-4">
        <div class="quicklinks">
          <ul>
            <li><a href="javascript:void(0);">Account Use</a></li>
            <li><a href="javascript:void(0);">How to Use</a></li>
            <li><a href="#faq-wrp">FAQs</a></li>
            <li><a href="javascript:void(0);">Notices</a></li>
          </ul>
        </div>
      </div>
      <div class="col-md-3 col-sm-4">
        <div class="quicklinks center-links">
          <ul>
            <li><a href="javascript:void(0);" @click="toggleHelpers({ show: true, data: 'terms' })">Terms and Conditions</a></li>
            <li><a href="javascript:void(0);" @click="toggleHelpers({ show: true, data: 'privacy_policy' })">Privacy Policy</a></li>
            <li><a href="javascript:void(0);">Cancellation Terms</a></li>
            <li><a href="javascript:void(0);">Refund Policy</a></li>
          </ul>
        </div>
      </div>
      <div class="col-md-3 col-sm-4">
        <div class="quicklinks center-links">
          <ul>
            <li><a href="javascript:void(0);" @click="toggleHelpers({ show: true, data: 'contact_form' })">Contact Us</a></li>
            <li><a href="javascript:void(0);">About Us</a></li>
            <li><a href="javascript:void(0);">Corporate Information</a></li>
          </ul>
        </div>
      </div>
      <div class="col-md-3 col-sm-12">
        <div class="social-bx">
          <a href="javascript:void(0);" target="_blank"><i class="fab fa-facebook fa-fw"></i></a>
          <a href="javascript:void(0);" target="_blank"><i class="fab fa-twitter fa-fw"></i></a>
          <a href="javascript:void(0);" target="_blank"><i class="fab fa-instagram fa-fw"></i></a>
        </div>
      </div>
    </div>
  </v-container>
  <!-- End Footer -->
</template>
<script>
import { mapMutations } from 'vuex';
export default {
  name: 'footerContent',
  methods: {
    ...mapMutations(['toggleHelpers'])
  }
}
</script>

<style scoped>
/* ***** Footer ***** */
footer{
    padding: 80px 0 140px;
    width: 100%;
    background: #fff;
}

.foot-up{
    margin: 0 0 72px;
}
.foot-up .row{align-items: center;}
.foot-lg img{
    margin: 0 0 15px;
}
.joinfree a{
    padding: 15px 40px;
    border-radius: 4px;
    background: #6FCF97;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    float: right;
}
.joinfree a:hover{
    background: #1D0575;
    color: #fff;
}
.foot-up p{
    margin: 0 0 0;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #333333;
}
.quicklinks li{
    display: block;
}
.quicklinks li a{
    padding: 4px 0;
    font-size: 16px;
    font-weight: 500;
    color: #707070;
    display: block;
}
.quicklinks li a:hover{
    color: #1D0575;
}
.social-bx {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.social-bx a{
    margin-right: 24px;
}
.social-bx a:last-child{margin: 0;}
.social-bx a i{
    font-size: 24px;
    color: #828282;
    opacity: 0.80;
    transition: .4s linear;
    -webkit-transition: .4s linear;
}
.social-bx a i:hover{
    opacity: 1;
    color: #1D0575;
}
.center-links{
    margin: 0 auto;
    display: table;
}

/* ***** End Footer ***** */
</style>