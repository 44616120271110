<template>
  <div 
    class="d-block d-md-flex align-center"
    :style="`background-color: ${$settings.primaryColor}`"
  >
    <div class="contact-details flex-grow-1">
      <div>
        <div class="text-h4 white--text">{{ $t('user_account.send_us_title') }}</div>
        <div class="caption white--text pl-3">{{ $t('user_account.send_us_subtitle') }}</div>
      </div>
      <div class="d-flex">
        <ul>
          <li class="d-flex mt-12 mb-5">
            <div class="pa-5 pt-0"><v-icon class="white--text">mdi-phone-outline</v-icon></div>
            <div class="caption white--text">+34 000 000 000</div>
          </li>
          <li class="d-flex mb-5">
            <div class="pa-5 pt-0"><v-icon class="white--text">mdi-email-outline</v-icon></div>
            <div class="caption white--text">info@sourcingspecialists.com</div>
          </li>
          <li class="d-flex">
            <div class="pa-5 pt-0"><v-icon class="white--text">mdi-map-marker-outline</v-icon></div>
            <div class="caption white--text">Calle Central Num. 13<br> 7B Torre Godoy, Murcia<br>España</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="pa-8 pt-10 flex-grow-1" style="background-color: #FFF;">
      <form 
        ref="contactForm"  
        @submit.prevent="submit"
      >
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                clearable
                dense
                v-model="contact.company_name"
                :label="$t('company')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                clearable
                dense
                v-model="contact.name"
                :label="$t('payment.full_name')+'*'"
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12">
              <v-text-field
                clearable
                dense
                v-model="contact.email"
                :label="$t('email')+'*'"
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                clearable
                dense
                v-model="contact.contact_no"
                :label="$t('phone_number')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                clearable
                dense
                v-model="contact.subject"
                :label="$t('subject')+'*'"
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                clearable
                dense
                v-model="contact.message"
                :label="$t('message')+'*'"
                :rules="requiredRule"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex">
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                type="submit"
                depressed
                :loading="loading"
              ><v-icon class="mr-2">mdi-send</v-icon>{{ $t('send') }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </form>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import userController from '../../controllers/userController';

const uc = new userController();

export default {
  name: 'contactForm',
  data() {
    return {
      loading: false,
      contact: {
        name: this.$store.getters.isAuthenticated ? this.$store.getters.user.name+' '+this.$store.getters.user.last_name : null,
        company_name: this.$store.getters.isAuthenticated ? this.$store.getters.user.company : null,
        email: this.$store.getters.isAuthenticated ? this.$store.getters.user.email : null,
        contact_no: this.$store.getters.isAuthenticated ? this.$store.getters.user.contact_no : null,
        subject: null,
        message: null 
      }
    }
  },
  methods: {
    ...mapMutations(['toggleHelpers']),
    async submit() {
      this.loading = true;
      await uc.submitContact(this.contact);
      this.$toasted.success(this.$t('message_sent'));
      this.loading = false;
      this.toggleHelpers({ show: false, data: null });
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-details {
  min-width: 380px;
  position: relative;
  padding: 50px;
  &:before {
    content: " ";
    width: 30px;
    height: 30px;
    transform: rotate(45deg);
    background-color: #FFF;
    top: 50px;
    right: -15px;
    position: absolute;
    display: block;
  }
  @media (max-width: 960px) {
    min-width: auto;
    padding: 35px 15px;
    &:before {
      display: none;
    }
  }
}
</style>