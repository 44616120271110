<template>
  <div>
    <div :class="['product-flags', 'novelty-flag', { show: product.is_novelty === 1 }, 'first-flag']">
      <v-icon small color="#FFF" class="mr-1">mdi-new-box</v-icon> <span v-if="false">{{ $t('products.new') }}</span>
    </div>
    <div :class="['product-flags', 'offer-flag', { show: product.has_offer === 1 }, guessFlagPosition('offer')]">
      <v-icon small color="#FFF" class="mr-1">mdi-percent</v-icon> <span v-if="false">{{ $t('products.offer') }}</span>
    </div>
    <div :class="['product-flags', 'express-flag', { show: product.is_flash }, guessFlagPosition('express')]">
      <v-icon small color="#FFF" class="mr-1">mdi-truck-fast-outline</v-icon> <span v-if="false">{{ $t('products.express') }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'product-flags',
  props: ['product'],
  methods: {
    guessFlagPosition(flag) {
      if(flag === 'offer') {
        if(this.product.is_novelty === 1) {
          return 'second-flag';
        }
      }
      if(flag === 'express') {
        if(this.product.is_novelty === 1 && this.product.has_offer === 1) {
          return 'third-flag';
        }
        if((this.product.is_novelty === 1 && this.product.has_offer === 0) || (this.product.is_novelty === 0 && this.product.has_offer === 1)) {
          return 'second-flag';
        }
      }
      return 'first-flag';
    },
  }
}
</script>
<style scoped lang="scss">
.product-flags {
  display: none;
  position: absolute;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 5px 10px 5px 10px;
  border-radius: 0px !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  z-index: 3;
  &.show {
    display: inherit;
  }
  &.offer-flag {
    background-color: #D32F2F;
  }
  &.novelty-flag {
    background-color: #64B5F6;
  }
  &.express-flag {
    background-color: var(--v-primary-base);
  }
  &.first-flag {
    top: 0px;
    left: 0px;
  }
  &.second-flag {
    top: 30px;
    left: 0px;
  }
  &.third-flag {
    top: 60px;
    left: 0px;
  }
}
</style>