<template>
  <auth-card>
    <v-form ref="loginForm" v-on:submit.prevent="login">
      <v-container>
          <v-text-field
            v-model="email"
            :rules="emailRule"
            label="E-mail"
            type="email"
            name="email"
            required
          />
          <v-text-field
            v-model="password"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="requiredRule"
            :type="show ? 'text' : 'password'"
            name="password"
            label="Password"
            @click:append="show = !show"
          ></v-text-field>
          <div class="text-right">
            <a
              @click.prevent="forgotDialog = true"
              href="#"
            >{{ $t('forgot_password') }}?</a>
          </div>
      </v-container>
      <v-container>
        <v-row>
          <v-col>
            <v-checkbox
              v-model="remember"
              :label="`${$t('remember_me')}`"
              color="indigo"
              value="red"
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <v-col
            lg="6"
            md="12"
          >
            <v-btn
              color="primary"
              @click.prevent="login"
              dark
              type="submit"
              :loading="loading"
              depressed
            >{{ $t('login') }}</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-container>
      <div class="d-block text-center">
        {{ $t('dont_have_account') }}
        <router-link :to="{ name: 'Register' }">{{ $t('create_one') }}</router-link>
      </div>
    </v-container>
    <v-dialog
      v-model="forgotDialog"
      v-if="forgotDialog"
      max-width="480"
    >
      <v-card
        class="py-5"
      >
        <v-card-title>{{ $t('forgot_password') }}</v-card-title>
        <v-card-subtitle class="mt-2">{{ $t('forgot_password_explain') }}</v-card-subtitle>
        <v-form ref="forgot" class="pa-5">
          <v-card-text>
            <v-text-field
              v-model="forgotEmail"
              :rules="requiredRule"
              name="email_forget"
              :label="$t('email')"
            >
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click.prevent="submitForget"
              color="primary"
              depressed
              type="submit"
              dark
              :loading="forgotButtonLoading"
            >{{ $t('reset') }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </auth-card>
</template>

<script>
import authCard from '../components/authCard.vue';
import { AUTH_FORGET } from '../constants/endpoints';

export default {
	components: { authCard },
  name: 'LoginForm',
  data: () => {
    return {
      email: '',
      password: '',
      show: false,
      remember: false,
      forgotDialog: false,
      forgotEmail: '',
      forgotButtonLoading: false,
      loading: false,
    }
  },
  methods: {
    async login() {
      //check if form is complete
      if(!this.$refs.loginForm.validate()) return false
      this.loading = true;
      let email = this.email
      let password = this.password
      let remember_me = this.remember ? 1 : 0
      await this.$store.dispatch('login', { email, password, remember_me });
      this.loading = false;
    },
    submitForget() {
      if(this.$refs.forgot.validate()) {
        this.forgotButtonLoading = !this.forgotButtonLoading
        this.$http.post(this.buildEndpoint(AUTH_FORGET), {
          email: this.forgotEmail,
          callback_url: process.env.VUE_APP_URL+'reset'
        }).then(() => {
          this.forgotEmail = ''
          this.$toasted.success(this.$t('forgot_reset_success'))
          this.forgotDialog = false
          this.forgotButtonLoading = !this.forgotButtonLoading
        })
      }
    }
  }
}
</script>
