<template>
  <div class="mb-3">
    <div class="d-block d-md-flex align-center">
      <div class="mb-2">
        <div class="text-h4">{{ title }}</div>
        <div v-if="date" class="pl-1">{{ $t('orders.place_at') }}: {{ date }}</div>
        <div v-if="placedBy" class="pl-1 font-italic" style="font-size: 12px;">{{ $t('orders.placed_by') }} {{ placedBy }}</div>
      </div>
      <v-spacer></v-spacer>
      <slot></slot>
    </div>
    <v-divider></v-divider>
  </div>
</template>

<script>
export default {
  name: 'pageHeader',
  props: ['title', 'date', 'placed-by']
}
</script>