const messages = {
  dashboard_title: 'Dashboard',
  catalogue: 'Catalogue',
  catalogue_flash: 'Catalogue flash',
  custom_lists: 'Custom lists',
  order: 'Order | Orders',
  zone: 'Zone | Zones',
  address: 'Address',
  delivery_address: 'Delivery address',
  warehouse: 'Warehouse | Warehouses',
  required_deposit: 'Required deposit',
  credit_days: 'Credit days',
  save_changes: 'Save changes',
  users: 'User | Users',
  product: 'Product | Products',
  option: 'Option | Options',
  item_selected: 'Item selected | Items selected',
  item_removed: '{qty} item removed | {qty} items removed',
  delete: 'Delete',
  delete_question: 'Delete item?',
  show_prices: 'Show prices',
  show_hide_price: 'Show or hide prices',
  packing: 'Packing',
  price: 'Price',
  moq: 'Min Order',
  ref: 'Reference',
  ref_ab: 'Ref',
  origin: 'Origin',
  destination: 'Destination',
  production_time: 'Production time',
  hs_code: 'HS Code',
  carton: 'Carton | Cartons',
  day: 'Day | Days',
  mandatory_field: 'This field is mandatory',
  search: 'Search',
  search_products: 'Search products',
  select: 'Select',
  categories: 'Categories',
  product_name: 'Product name',
  download: 'Download',
  currency: 'Currency',
  friendly_error: 'Sorry, something went wrong',
  price_tiers: 'Price tiers',
  something_wrong: 'Something went wrong',
  password_length: 'Password min length is 8 characteres',
  password_unmatch: 'Passwords does not match',
  required: 'Field required',
  password: 'Password',
  password_confirmation: 'Password confirmation',
  email: 'Email',
  reset: 'Reset',
  reset_success: 'Your password has been changed',
  forgot_reset_success: 'An email has been sent to the specified address.',
  forgot_password: 'Forgot password',
  forgot_password_explain: 'Please complete the field below. We will send you an email with instructions to reset your password.',
  login: 'Login',
  remember_me: 'Remember me',
  yes: 'Yes',
  field_required: 'Field required',
  dont_have_account: 'Don\'t have an account?',
  create_one: 'Create one!',
  have_account: 'Already have an account?',
  we_only_support: 'We only support Spain country at the moment.',
  country: 'Country',
  name: 'Name',
  last_name: 'Last name',
  register: 'Register',
  phone_number: 'Phone number',
  area_code: 'Area code',
  we_have_sent_email: 'We have sent you an email with a confirmation code. Please copy and paste the code to proceed.',
  confirm_email: 'Confirm',
  code: 'Code',
  email_taken: 'Seems this email is taken already. Please login!',
  invalid_email: 'Invalid email',
  please_verify: 'Please verify your email',
  email_sent: 'Email sent',
  did_not_receive: 'Did not receive the email?',
  send_again: 'Send again!',
  account: 'Account',
  logout: 'Logout',
  apply_filters: 'Apply filters',
  product_details: 'Product details',
  add_to_favorites: 'Add to favorites',
  remove_from_favorites: 'Remove from favorites',
  checkout: 'Checkout',
  your_cart: 'Your cart',
  continue_shopping: 'Continue shopping',
  update: 'Update',
  english: 'English',
  spanish: 'Spanish',
  no_items: 'Your cart has no items',
  total: 'Total',
  company: 'Company',
  status: 'Status',
  wrong_credentials: 'This credentials do not match our records.',
  created: 'Created',
  reason: 'Reason',
  due_date: 'Due date',
  paid_at: 'Paid at',
  amount: 'Amount',
  payments: 'Payments',
  inspections: 'Inspections',
  shipping_orders: 'Shipping order | Shipping orders',
  date: 'Date',
  action: 'Action',
  accept_terms: 'I have read and accept the ',
  terms_conditions: 'terms and conditions',
  privacy_policy: 'Privacy policy',
  need_help: 'Need help',
  contact_us: 'Contact us',
  of: 'of',
  express_explain: 'Express products are delivered much faster',
  required_payment: 'Payment required',
  home: 'Home',
  offers: 'Offers',
  express: 'Express',
  favorites: 'Favorites',
  save: 'Save',
  information_updated: 'Information updated',
  next: 'Next',
  previous: 'Previous',
  send: 'Send',
  subject: 'Subject',
  message: 'Message',
  message_sent: 'Message sent',
  products: {
    all_categories: 'All',
    no_matches: 'There are no products that match your selection',
    back_to_catalogue: 'Back to catalogue',
    please_wait: 'Please wait while we load your product',
    download_datasheet: 'Download datasheet',
    add_to_cart: 'Add to cart',
    added_to_cart: 'Product added',
    your_selection: 'Your selection',
    packing: 'Packing',
    unit_price: 'Unit price',
    cartons: 'Cartons',
    total_of: 'Total of {units} units',
    show_only_favorites: 'Show favorites only',
    delivery_alert: 'Due to global shipping challenges, we\'re experiencing unexpected delays. This item is estimated to dispatch in 12 to 16 weeks, but please be aware these timings could change.',
    search_products: 'Search products',
    search: 'Search',
    quantity: 'Quantity',
    total_amount: 'Total amount of ',
    total_units_carton: 'Total units',
    offer: 'Offer',
    product_has_offer: 'This product has offer',
    product_has_express: 'Product with express options',
    new: 'New',
    express: 'Express',
    unit_price_before: 'Before',
    unit: 'Unit',
    base_unit: 'Base unit',
    outer_name: 'Outer',
    per: 'per',
    price_per: 'Price per {desc}',
    options: 'Options',
    option_group: 'Option group',
    see_more_options: 'See more options',
    express_selection: 'Selection with express option',
    normal_delivery: 'Normal delivery, 12 to 16 weeks',
    delivery_type: 'Delivery type',
    express_select_label: 'Express, {date}',
    express_select_label_within: 'Express, within 5 days',
    product_required_payment: 'Require {percent}% payment: {amount}',
    available_quantity: 'Hurry up! Only {quantity} left.',
    within_days: 'Within {days} days',
    now: 'Now',
    selection_has_offer: 'Selection has offer',
    filter_by_category: 'Filter by category',
    filters: 'Filters',
    show_only_offers: 'Offers',
    show_only_express: 'Express',
    my_favorites: 'My favorites',
    buyer_protection: 'Buyer protection for 30 days.',
    free_shipping: 'Free shipping to your door. Tracking included.',
    similar_products: 'Similar products',
    usually_bought_together: 'Usually bought together',
    etd_no_express: '12 to 16 weeks',
    clear_selection: 'Clear selections',
    main_categories: 'Main categories',
    subcategories: 'Subcategories',
    selected_categories: 'Selected categories',
    confirm_close_categories: 'You have changed your categories selection, if you close the filter your changes won\'t be reflected'
  },
  cart: {
    title: 'Cart',
    checkout: 'Checkout',
    proceed_to_payment: 'Confirm and pay',
    pay_with_credit_card: 'Pay with credit card',
    pay_with_credit_card_subtitle: 'Powered by Braintree. Our payment gateway is 100% secure!',
    pay_with_transfer: 'Pay to our bank account',
    pay_with_transfer_subtitle: 'Do a tranfer to our bank account including the reference number.',
    remove: 'Remove from cart',
    please_complete_profile: 'Please complete your profile first',
    you_have: 'You have {quantity} {outerDescription} in your cart already.',
    name_as: 'Name as in the credit card',
    card_number: 'Card number',
    expiry: 'Expiry',
    postal_code: 'Postal code',
    save_payment: 'Do you want to save the payment for future transactions?',
    'cardholderName-errors': 'Please input a correct card holder name',
    'card-number-errors': 'Please input a valid credit card',
    'expiration-date-errors': 'Invalid expiration date',
    'cvv-errors': 'Invalid cvv',
    'postal-code-errors': 'Invalid postal code',
    ok_got_it: 'Ok got it!',
    paypal_recoverable_error: 'Seems something went wrong with the payment. Please try again!',
    paypal_error: 'The transaction could not be processed.',
    klarna_only_eur: 'Klarna payments for Spain only works with Euros'
  },
  user_account: {
    my_information: 'My information',
    information: 'Information',
    account_locked: 'Remember you need to complete your company profile before being able to place an order.',
    profile: 'Profile',
    company_name: 'Company name',
    business_registration_number: 'Business registration number',
    company_address: 'Company address',
    delivery_address: 'Delivery address',
    personal_details: 'Personal details',
    company_details: 'Company details',
    profile_updated: 'Profile updated',
    phone_number: 'Phone number',
    address_empty: 'Leave empty if it is the same as the company address',
    pref_categories: 'Preferred categories',
    pref_categories_explain: 'If you select preferred categories always you login your catalogue will be filter automatically by this preferences.',
    pref_language: 'Preferred language',
    pref_currency: 'Preferred currency',
    payment_methods: 'Payment methods',
    delivery_details: 'Delivery info',
    billing_address: 'Billing address',
    address_line_one: 'Address line one',
    address_line_one_placeholder: 'Apt, Floor, Street',
    address_line_two: 'Address line two',
    address_line_two_placeholder: 'Building name, details',
    city: 'City',
    postal_code: 'Postal code',
    please_add_billing_address: 'Please add a billing address',
    delivery_addresses: 'Delivery addresses',
    no_delivery_addresses: 'You do not have delivery addresses at the moment, we will use your billing info to ship your goods.',
    add_delivery_address: 'Add delivery address',
    address_alias: 'Alias',
    alias_placeholder: 'Friendly name for your address. ex: Home, Factory',
    use_as_default: 'Use as default',
    default_address: 'Default address',
    send_us_title: 'Let\'s get in touch',
    send_us_subtitle: 'We will back to you as soon as possible'
  },
  orders: {
    view: 'View order',
    order: 'Order {order_id}',
    back_to_orders: 'Back to orders',
    subtotal: 'Subtotal',
    place_at: 'Placed at',
    estimated_delivery: 'Estimated delivery',
    expiring_at: 'Expiring at',
    expiring_in: 'Expiring in {days} day | Expiring in {days} days',
    pay_before: 'Pay before {date} to avoid losing your order.',
    only_active: 'Only active',
    all_orders: 'All orders',
    pay_now: 'Pay {reason} now',
    no_payments: 'There are no payments at the moment',
    no_inspections: 'You have no inspections at the moment',
    tax: 'Tax 21%',
    inspector: 'Inspector',
    result: 'Result',
    expired: 'Expired',
    pending: 'Pending',
    pass: 'Pass',
    forwarder: 'Forwarder',
    shipping_products: 'Shipped products',
    tracking: 'Tracking',
    location: 'Location',
    container_number: 'Container number',
    no_shipping_orders: 'There are no shipping orders at the moment',
    show_all: 'Showing all orders',
    show_active: 'Showing active orders',
    order_total: 'Order total',
    delivery: 'Delivery',
    expiration_date_alert: 'This order expiry on <strong>{date}</strong>. Please proceed with the required payment as soon as possible.',
    to_pay: 'To pay',
    order_summary: 'Order summary',
    payments_history: 'Payments history',
    no_payments_done: 'No past payments at the moment',
    no_required_payments: 'No required payments.',
    download_invoice: 'Download invoice',
    placed_by: 'Placed by',
    download_order: 'Download order'
  },
  payment: {
    create_payment: 'Create new payment request',
    payment_updated_successfully: 'Payment updated',
    payment_created_successfully: 'Payment created',
    deposit: 'Deposit',
    balance: 'Balance',
    pay_now: 'Pay now',
    full_name: 'Full name',
    secure_payments: 'Secure payments powered by',
    pay_by_credit_card: 'Pay by credit card',
    pay_by_transfer: 'Pay by transfer',
    payment_options: 'Payment options',
    payment_successful: 'Payment was successful',
    payment_error: 'There was an error. Please try again later.',
    payment_paid: 'Seems the payment has been settle already.',
    make_default: 'Set as default',
    is_default: 'Default payment method',
    disclaimer: 'REVOOLOOP does not store any information about your payment preferences. We relay on Paypal & Klarna powerfull API to process all transactions in our platform. Your information is 100% secure.',
    saved_payment_methods: 'Saved payment methods',
    no_payment_methods: 'You do not have payment methods at the moment. Please place your first order to save your credit card details',
    check_your_form: 'Please check your form',
    select_payment_method: 'Payment method',
    new_credit_card: 'New credit card',
    payment_not_valid: 'Sorry it seems we can not proceed with this payment. Please contact support.',
    item_count: '{count} item | {count} items',
    order_value: 'Order value',
    to_be_paid: 'Required amount',
    payment_type: 'Payment type',
    notify_client_payment_done: 'Notify client of payment received?',
    notify_client_payment_request: 'Notify client of this new request?',
    confirm_payment: 'Confirm payment',
    pending: 'Pending',
    paid: 'Paid',
    first_name: 'First name',
    last_name: 'Last name',
    save_payment_method: 'Save payment method?',
    total_paid: 'Total paid',
    pending_amount: 'Pending amount',
    pay_later: 'Pay later',
    pay_with_klarna: 'Pay now with Klarna',
    something_wrong_with_pm: 'Something went wrong with this payment method, please try another option.'
  },
  cart_messages: {
    min_quantity: 'Product quantity must be greater or equal to {min_quantity}',
    max_quantity: 'The maximum quantity is {max_quantity}',
    product_added: 'Product added',
    quantity_updated: 'Quantity updated',
    product_removed: 'Producto removed'
  }
}

export default messages