<template>
  <div class="payment-options">
    <v-card outlined>
      <v-card-text>
        <div style="position: relative;">
          <loader v-model="loadingPaypalCreditCardFields"></loader>
          <div id="paypal_buttons" style="position: relative; z-index: 2"></div>
        </div>
      </v-card-text>
    </v-card>
    <v-card
      v-if="payment.reason !== 'deposit'"
      outlined
      class="mt-3"
    >
      <v-card-text>
        <klarna-checkout
          :addresses="address_uuids"
        ></klarna-checkout>
      </v-card-text>
    </v-card>
    <v-card
      outlined
      class="mt-3"
    >
      <v-card-text>
        <div style="position: relative;">
          <div>
            <v-btn
              block
              color="light-blue darken-1"
              :x-small="$vuetify.breakpoint.mdAndDown"
              :x-large="$vuetify.breakpoint.mdAndUp"
              dark
              depressed
              @click="showBankDetails = true"
            ><v-icon class="mr-3">mdi-bank-transfer</v-icon> {{ $t('payment.pay_by_transfer') }}</v-btn>
          </div>
        </div>
        <div v-if="showBankDetails" class="pa-8">
          <v-btn
            depressed
            text
            @click="showBankDetails = false"
            absolute
            right
            fab
          ><v-icon>mdi-close</v-icon></v-btn>
          <v-simple-table class="mt-8">
            <template v-slot:default>
              <tbody>
                <tr v-for="a in bankSpain" :key="a.text">
                  <td>{{ a.text }}</td>
                  <td>{{ a.value }}</td>
                </tr>
                <tr>
                  <td>Ref</td>
                  <td>{{ payment.target.sku }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-btn
            color="primary"
            rounded
            :to="{ name: 'Orders' }"
            class="mt-5"
          >{{ $t('cart.ok_got_it') }}</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import loader from '../../components/loadingBox.vue';
import klarnaCheckout from './klarnaCheckout.vue';
import { bankSpain } from '../../constants/bankDetails';
import { loadScript } from "@paypal/paypal-js";
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'checkoutPaymentOptions',
  props: ['delivery-address'],
  components: {
    loader,
    klarnaCheckout
  },
  data() {
    return {
      bankSpain,
      cardPaymentLoading: false,
      clientSecret: '',
      braintree_customer_id: null,
      order: {},
      showBankDetails: false,
      loadingPaypalCreditCardFields: false,
    }
  },
  computed: {
    ...mapGetters([
      'billingAddress',
      'locale'
    ]),
    ...mapGetters('cart', {
      payment: 'checkoutPaymentData'
    }),
    address_uuids() {
      var addresses = [this.billingAddress.uuid];
      if(this.deliveryAddress) {
        addresses.push(this.deliveryAddress);
      }
      return addresses; 
    },
    payment_secret() {
      return this.payment.protected_id;
    }
  },
  methods: {
    ...mapActions('cart', [
      'clearCart'
    ]),
    async payPal() {
      var vue = this;
      var lang = this.locale.replace('-','_');
      loadScript({ 
        "client-id": process.env.VUE_APP_PAYPAL_CLIENT_ID,
        currency: vue.payment.currency,
        locale: lang
      }).then((paypal) => {
        paypal.Buttons({
          style: {
            shape:  'rect'
          },

          // Call your server to set up the transaction
          createOrder: function(/*data, actions*/) {
            return vue.createPaypalOrder();
          },

          // Call your server to finalize the transaction
          onApprove: function(data, actions) {
            return vue.$paymentController.capturePaypalOrder({ 
              payment_secret: vue.payment_secret,
              paypal_order_id: data.orderID, 
              address_uuids: vue.address_uuids 
            }).then(function(orderData) {
              // Three cases to handle:
              //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
              //   (2) Other non-recoverable errors -> Show a failure message
              //   (3) Successful transaction -> Show confirmation or thank you

              // This example reads a v2/checkout/orders capture response, propagated from the server
              // You could use a different API or structure for your 'orderData'
              var errorDetail = Array.isArray(orderData.details) && orderData.details[0];

              if (errorDetail && errorDetail.issue === 'INSTRUMENT_DECLINED') {
                vue.$toasted.error(this.$t('cart.paypal_recoverable_error'));
                return actions.restart(); // Recoverable state, per:
                // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
              }

              if (errorDetail) {
                var msg = this.$t('cart.paypal_error');
                if (errorDetail.description) msg += '\n\n' + errorDetail.description;
                if (orderData.debug_id) msg += ' (' + orderData.debug_id + ')';
                vue.$toasted.error(msg);
                return; // Show a failure message (try to avoid alerts in production environments)
              }

              // Successful capture! For demo purposes:
              //console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));
              var transaction = orderData.result.purchase_units[0].payments.captures[0];
              //alert('Transaction '+ transaction.status + ': ' + transaction.id + '\n\nSee console for all available details');
              if(transaction.status === 'COMPLETED') {
                vue.$toasted.success(vue.$t('payment.payment_successful'));
                vue.$router.push({ name: 'Orders' });
              }
                
              // Replace the above to show a success message within this page, e.g.
              // const element = document.getElementById('paypal-button-container');
              // element.innerHTML = '';
              // element.innerHTML = '<h3>Thank you for your payment!</h3>';
              // Or go to another URL:  actions.redirect('thank_you.html');
            });
          }

        }).render("#paypal_buttons")
        .catch((error) => {
            console.error("failed to render the PayPal Buttons", error);
        });
      });
    },
    createPaypalOrder() {
      var vue = this;
      vue.loadingPaypalCreditCardFields = true;
      return this.$paymentController.createPaypalOrder({ payment_secret: this.payment_secret })
      .then(function(orderData) {
        vue.loadingPaypalCreditCardFields = false;
        return orderData.data.result.id;
      });
    },
  },
  created() {
    this.payPal();
  }
}
</script>