<template>
  <div style="position: relative;">
    <!--<h3>{{ $tc('product', 2) }}</h3>-->
    <loader v-model="loading"></loader>
    <v-card
      v-for="d in deliveries"
      :key="d.key"
      outlined
      class="mb-6"
    >
      <v-card-text class="d-flex justify-space-between">
        <div>
          <div class="font-weight-bold primary--text">{{ $t('orders.delivery') }}</div>
          <div>{{ d.key === 'warehouse' ? $t('products.within_days', { days: 5 }) : d.eta_string }}</div>
        </div>
        <div v-if="false">
          <div class="font-weight-bold black--text">{{ $t('orders.delivery') }}</div>
          <div>{{ d.key === 'warehouse' ? $t('products.within_days', { days: 5 }) : d.eta_string }}</div>
        </div>
        <div v-if="false">
          <div class="font-weight-bold black--text">{{ $t('orders.delivery') }}</div>
          <div>{{ d.key === 'warehouse' ? $t('products.within_days', { days: 5 }) : d.eta_string }}</div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text v-if="d.items.length > 0">
        <order-products-list :products="d.items"></order-products-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import orderController from '../../controllers/orderController';
import orderProductsList from '../../components/orders/orderProductsList.vue';
import loader from '../loadingBox.vue';

const oc = new orderController();

export default {
  name: 'orderProductsBydelivery',
  props: ['order-id'],
  components: { loader, orderProductsList },
  data() {
    return {
      loading: false,
      deliveries: [],
    }
  },
  computed: {

  },
  methods: {
    async getProducts() {
      this.loading = true;
      const products = await oc.getOrderProducts(this.orderId);
      for(var i in products.items_grouped) {
        products.items_grouped[i].forEach(d => {
          this.deliveries.push(d);  
        });
      }
      this.loading = false;
    }
  },
  mounted() {
    this.getProducts();
  }
}
</script>