<template>
  <div class="catalogue-settings">
    <div class="d-flex align-center">
      <div class="d-flex justify-space-between" style="width: 100%;">
        <div>
          <v-btn
            depressed
            :color="onlyOffers ? 'indigo' : 'grey lighten-2'"
            :dark="onlyOffers"
            small
            @click="toggleOffers"
            class="mr-2"
          ><v-icon class="mr-2" small>mdi-percent</v-icon>{{ $t('products.show_only_offers') }}</v-btn>
          <v-btn
            depressed
            small
            :dark="onlyExpress"
            :color="onlyExpress ? 'indigo' : 'grey lighten-2'"
            @click="toggleExpress"
            class="mr-2"
          ><v-icon class="mr-2" small>mdi-truck-fast-outline</v-icon>{{ $t('products.show_only_express') }}</v-btn>
          <v-btn
            depressed
            small
            text
            plain
            outlined
            v-if="onlyExpress || onlyOffers || $store.getters.selectedCategories.length > 0 || catalogueSearch !== ''"
            @click="clearFilters"
            color="red"
            class="mr-2"
          ><v-icon class="mr-2" small>mdi-close</v-icon>{{ $t('products.clear_selection') }}</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'catalogueSettings',
  data() {
    return {
      
    }
  },
  computed: {
    ...mapGetters([
      'onlyExpress',
      'onlyOffers',
      'catalogueSearch'
    ])
  },
  methods: {
    ...mapActions([
      'toggleExpress',
      'toggleOffers',
      'loadProducts'
    ]),
    ...mapMutations(['softResetCatalogue']),
    clearFilters() {
      this.softResetCatalogue();
      this.loadProducts({ changePageSize: true, reset: false });
    }
  }
}
</script>