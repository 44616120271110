import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';
import { preferences } from '../configs/storeSettings';

Vue.use(Vuetify)


export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: preferences.primaryColor,
        secondary: preferences.secondaryColor,
        accent: colors.indigo.base,
      },
      dark: {
        primary: preferences.primaryColor,
        secondary: preferences.secondaryColor,
        accent: colors.indigo.base,
      }
    },
    options: {
      customProperties: true
    }
  },
});
