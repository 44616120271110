<template>
  <div>
    <h3 class="mb-5"><v-icon class="mr-3">{{ icon }}</v-icon>{{ title }}</h3>
    <v-divider v-if="hideDivider === undefined"></v-divider>
  </div>
</template>

<script>
export default {
  name: 'subsectionHeading',
  props: ['icon', 'title', 'hide-divider']
}
</script>