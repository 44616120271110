import controller from './controller';
import { 
  USER_FETCH,
  USER_UPDATE, 
  USER_COMPANY_UPDATE,
  USER_COMPANY_ADDRESS_INSERT, 
  USER_COMPANY_ADDRESS_UPDATE, 
  CONTACT_FORM
} from '../constants/endpoints.js';

export default class cartController extends controller {

  async fetchProfile() {
    return new Promise((resolve) => {
      this.sendRequest('GET', USER_FETCH, {}).then(resp => {
        if(resp.data.result === true) {
          resolve(resp.data.data);
        }
      });
    });
  }

  async updateProfile(params) {
    return new Promise((resolve) => {
      this.sendRequest('POST', USER_UPDATE, params).then(resp => {
        if(resp.data.result === true) {
          resolve(resp.data.data);
        }
      });
    });
  }

  async updateCompany(params) {
    return new Promise((resolve) => {
      this.sendRequest('POST', USER_COMPANY_UPDATE, params).then(resp => {
        resolve(resp.data.data);
      });
    });
  }

  async updateBillingAddress(params) {
    var endpoint = params.uuid === null ? USER_COMPANY_ADDRESS_INSERT : USER_COMPANY_ADDRESS_UPDATE;
    try {
      var res = await this.sendRequest('POST', endpoint, params);
      if(res.data.result) {
        return true;
      } else {
        throw 'error';
      }
    } catch(e) {
      console.log('controllers/userController.js?updateCompanyBillingAddress', e);
    }
  }

  async submitContact(params) {
    try {
      await this.sendRequest('POST', CONTACT_FORM, params);
    } catch(e) {
      console.log('controllers/userController.js?submitContact', e);
    }
  }

}