<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        @click.stop="toFavorites(product)"
        text
        fab
        absolute
        :right="place === 'top-right'"
        :left="place === 'top-left'"
        v-bind="attrs"
        v-on="on"
        :color="product.is_favorite === 1 ? 'primary' : 'grey'"
        :loading="product.loadingFavorite"
      ><v-icon>mdi-heart</v-icon></v-btn>
    </template>
    <span>{{ product.is_favorite ? $t('remove_from_favorites') : $t('add_to_favorites') }}</span>
  </v-tooltip>
</template>

<script>
import { mapMutations } from 'vuex';
import catalogueController from '../../controllers/catalogueController';
const cat = new catalogueController();

export default {
  name: 'productFavorite',
  props: ['product', 'place'],
  methods: {
    ...mapMutations([
      'attachFavoriteToCurrentResult'
    ]),
    toFavorites(product) {
      //product.loadingFavorite = true;
      //console.log(product);
      if(product.is_favorite === 1) {
        cat.removeProductToFavorites(product.id);
        product.is_favorite = 0;
      } else {
        cat.addProductToFavorites(product.id);
        product.is_favorite = 1;
      }
      this.attachFavoriteToCurrentResult(product.id);
    },
  }
}
</script>