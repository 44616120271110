var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"position":"relative"}},[_c('loader',{model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}}),(false)?_c('subsection-header',{attrs:{"icon":"mdi-basket-check-outline","title":_vm.$t('orders.order_summary'),"hide-divider":""}}):_vm._e(),_c('v-card',{staticClass:"py-5",attrs:{"flat":"","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.orderProducts,"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('img',{attrs:{"src":item.media.small,"width":"50px"}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-3"},[_c('h4',{staticClass:"mColor-text"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"caption"},[_c('span',[_c('strong',[_vm._v(_vm._s(item.option.key)+":")]),_vm._v(" "+_vm._s(item.option.value))]),_vm._l((item.attributes),function(attr){return _c('span',{key:attr.key},[_vm._v(" - "),_c('strong',[_vm._v(_vm._s(attr.key)+":")]),_vm._v(" "+_vm._s(attr.value))])})],2),_c('div',{staticStyle:{"font-size":"12px"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('products.quantity'))+": ")]),_vm._v(_vm._s(item.quantity)+" "+_vm._s(item.packing.unit_outer_description_text)+"(s)")])])]}},{key:"item.subtotal",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.total_price_string))])]}},{key:"item.to_pay",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.required_payment)+" ")]),_c('span',{staticClass:"font-italic",staticStyle:{"font-size":"10px"}},[_vm._v("("+_vm._s(item.is_flash ? 100 : '%%')+"%)")])]}},{key:"body.append",fn:function(){return [_c('tr',{staticClass:"total-rows"},[_c('td'),_c('td',{staticStyle:{"text-align":"right"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('payment.order_value')))])]),_c('td',{staticStyle:{"text-align":"right"}},[_vm._v(_vm._s(_vm.order.total))])]),_c('tr',{staticClass:"total-rows"},[_c('td'),_c('td',{class:['primary--text'],staticStyle:{"text-align":"right"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('payment.to_be_paid')))])]),_c('td',{class:['primary--text', 'font-weight-bold'],staticStyle:{"text-align":"right"}},[_vm._v(_vm._s(_vm.numberToNiceString(_vm.payment.amount, _vm.payment.currency, 2)))])])]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }