<template>
  <v-container>
    <page-header :title="$t('cart.checkout')">
      <v-btn
        small
        depressed
        :to="{ name: 'Orders' }"
      >{{ $t('orders.back_to_orders') }}</v-btn>
    </page-header>
    <v-row
      v-if="!isLoading"
      class="pt-3"
    >
      <v-col>
        <checkout-steppers></checkout-steppers>
        <div class="customer-info mb-5" v-if="false">
          <subsection-header icon="mdi-truck-delivery-outline" :title="$t('user_account.delivery_details')" />
          <div class="pa-3 d-flex justify-space-between align-center">
            <div><span class="font-weight-bold black--text">{{ company.name }}, </span> {{ company.delivery_address }}, {{ company.country }}</div>
            <v-btn
              v-if="payment.reason === 'deposit'"
              :to="{ name: 'Account', hash: '#company', query: { redirect: 'Checkout', param: payment_secret } }"
              depressed
              plain
              text
            ><v-icon small>mdi-pen</v-icon></v-btn>
          </div>
        </div>
      </v-col>
      <v-col cols="1"></v-col>
      <v-col lg="5">
        <order-review 
          :order-id="payment.target.id" 
          :payment="payment"
        ></order-review>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import orderReview from '../../components/checkout/orderReview.vue';
import checkoutSteppers from '../../components/checkout/checkoutSteppers.vue';

export default {
  name: 'OrderCheckout',
  props: ['payment_secret'],
  components: {
    orderReview,
    checkoutSteppers
  },
  data() {
    return {
      payment: {
        target: {
          sku: ''
        }
      },
      payment_type: '',
      orderLoaded: false
    }
  },
  computed: {
    ...mapGetters([
      'isLoading',
      'user',
      'company'
    ])
  },
  methods: {
    ...mapActions('cart', [
      'clearCart'
    ]),
    ...mapMutations({
      setLoading: 'isLoading'
    }),
    ...mapMutations('cart', ['assigncheckoutPaymentData']),
    toOrders() {
      this.clearCart();
      this.$router.push({ name: 'Orders' });
    },
    async getPayment() {

      this.setLoading(true);
      var secret = this.payment_secret;
      const payment = await this.$paymentController.getPayment(secret);

      //payment not valid
      if(payment.data === null) {
        this.$toasted.error(this.$t('payment.payment_not_valid'));
        this.$router.push({ name: 'Orders' });
        this.setLoading(false);
        return;
      }
      //payment paid
      if(payment.data.status === "2") {
        this.$toasted.error(this.$t('payment.payment_paid'));
        this.$router.push({ name: 'Orders' });
        this.setLoading(false);
        return;
      }

      this.payment = payment.data;
      this.assigncheckoutPaymentData(this.payment);
      this.setLoading(false);
    },
    async braintreePay() {
      this.payment_type = 'credit_card';
      this.cardPaymentLoading = true;
      var params = {
        payment_secret: this.payment.protected_id
      }
      const payment = await this.$paymentController.getBtToken(params);
      if(payment.token) {
        this.clientSecret = payment.token;
        this.braintree_customer_id = payment.braintree_customer_id;
      } else {
        this.$toasted.error('Something is wrong. Please contact us.')
      }
      this.cardPaymentLoading = false;
    }
  },
  beforeMount() {
    this.getPayment();
  }
}
</script>

<style scoped lang="scss">
.payment-cards {
  &:hover {
    background-color: #f0f0f0;
    cursor: pointer;
  }
  &.selected {
    background-color: #E8EAF6;
  }
}
</style>