<template>
  <div style="position: relative; min-height: 150px;">
    <loader v-model="loading"></loader>
    <div class="text-h5 black--text">{{ $t('products.usually_bought_together') }}</div>
    <v-divider></v-divider>
    <v-slide-group
      v-if="products.length > 0"
      class="pa-4"
      show-arrows
    >
      <v-slide-item
        v-for="(product, index) in products"
        :key="index"
        class="px-3 pb-5"
      >
        <div style="max-width: 250px;"><tiles :product="product"></tiles></div>
      </v-slide-item>
    </v-slide-group>
    <div v-else>{{ $t('products.no_matches') }}</div>
  </div>
</template>
<script>
import catalogueController from '../../controllers/catalogueController';
import loader from '../loadingBox.vue';
import tiles from './catalogueAsTiles.vue';

var cc = new catalogueController();

export default {
  name: 'usuallyBoughtTogether',
  props: ['ref-id'],
  components: { loader, tiles },
  data() {
    return {
      loading: false,
      products: []
    }
  },
  computed: {
    cols() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 12
        case 'sm': return 12
        case 'md': return 4
        case 'lg': return 3
        case 'xl': return 2
        default : return 12
      }
    }
  },
  methods: {
    async load() {
      this.loading = true;
      var products = await cc.getSimilarProducts({ 
        buy_together_with: this.refId,
        pageSize: 10, 
        currency: this.$store.getters.getCurrency
      });
      this.products = products.items;
      this.loading = false;
    }
  },
  mounted() {
    if(this.refId) {
      this.load();
    }
  }
}
</script>