var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"search":_vm.table.search,"headers":_vm.table.headers,"items":_vm.products,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('img',{attrs:{"src":item.media.small,"width":"50px"}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('h4',[_vm._v(_vm._s(item.name)+" - "),_c('strong',{staticClass:"mColor-text"},[_vm._v(_vm._s(item.option.key)+":")]),_vm._v(" "+_vm._s(item.option.value))]),(item.attributes.length > 0)?_c('div',{staticClass:"text-caption"},_vm._l((item.attributes),function(attr){return _c('span',{key:attr.key,staticClass:"pr-3"},[_c('strong',[_vm._v(_vm._s(attr.key)+":")]),_vm._v(" "+_vm._s(attr.value))])}),0):_vm._e()]}},{key:"item.files",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.designs.length)+" ")]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('h4',[_vm._v(_vm._s(item.quantity)+" "+_vm._s(_vm.$tc('carton', item.quantity)))]),_c('p',{staticClass:"pa-0 mb-0"},[_vm._v(_vm._s(item.total_units)+" "+_vm._s(_vm.unit(item)))])]}},{key:"item.total_price_string",fn:function(ref){
var item = ref.item;
return [_c('h4',[_vm._v(_vm._s(item.total_price_string))]),_c('p',{staticClass:"pa-0 mb-0"},[_vm._v(_vm._s(item.unit_price_string)+" "+_vm._s(_vm.unit(item, 1)))])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }