<template>
  <v-container fluid>
    <page-header :title="$t('orders.order', { order_id: order.orderNumber })" :date="order.date" :placed-by="order.order_by">
      <v-btn
        small
        depressed
        @click="$router.push({ name: 'Orders' })"
      >{{ $t('orders.back_to_orders') }}</v-btn>
    </page-header>
    <v-row v-if="order_alerts">
      <v-col>
        <v-alert
          v-if="!loading"
          v-model="expirationAlert"
          dismissible
          color="warning"
          border="left"
          elevation="2"
          colored-border
          icon="mdi-calendar-alert"
        >
          <div v-html="$t('orders.expiration_date_alert', { date: formatDate(order.expired_at) })"></div>
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="order.id">
      <v-spacer></v-spacer>
      <v-btn
        class="mr-3"
        color="primary"
        :href="order.download_url"
        target="_blank"
        dark
        depressed
      ><v-icon class="mr-2">mdi-download</v-icon>{{ $t('orders.download_order') }}</v-btn>
    </v-row>
    <v-row :class="[{ 'mt-3': !order_alerts }]">
      <v-col sm="12" lg="7">
        <order-products-bydelivery :order-id="order_id"></order-products-bydelivery>
      </v-col>
      <v-col sm="12" lg="5">
        <order-total-details :order="order"></order-total-details>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import orderTotalDetails from '../../components/orders/orderTotalDetails.vue';
import orderProductsBydelivery from '../../components/orders/orderProductsBydelivery.vue';
export default {
  name: 'Order',
  props: ['order_id'],
  components: {
    orderTotalDetails, orderProductsBydelivery
  },
  data: function() {
    return {
      loading: true,
      order: {},
      company: {},
      products: [],
      stage: 'pending',
      tab: 'products',
      convertingToOrder: false,
      addProductModal: false,
      expirationAlert: true,
      shipping_orders: []
    }
  },
  computed: {
    order_alerts() {
      if(this.order.expired_at !== null) {
        return true;
      }
      return false;
    }
  },
  watch: {
    id(new_val, old_val) {
      if(new_val !== old_val) {
        this.reload();
      }
    }
  },
  methods: {
    reload() {
      this.loadOrder();
    },
    async loadOrder() {
      this.loading = true;
      const order = await this.$orderController.getOrder(this.order_id);
      this.order = order.order;
      //console.log(this.order);
      const products = await this.$orderController.getOrderProducts(this.order_id);
      this.products = products.items;
      this.loading = false;
    },
    replace() {
      //window.location.hash = '#'+this.tab;
      //location.href.replace(location.hash, '#'+this.tab);
      history.pushState("", document.title, window.location.pathname + '#' + this.tab);
    }
  },
  mounted() {
    this.loadOrder();
    if(this.$route.hash) {
      this.$nextTick(() => {
        this.tab = this.$route.hash.replace('#', '');
      });
    } 
  }
}
</script>

<style scoped lang="scss">
.so-info {
  display: flex;
  li {
    margin-right: 15px;
    font-size: 13px;
  }
}
</style>
