<template>
  <div>
    <orders-list></orders-list>
  </div>
</template>

<script>
import ordersList from '../../components/orders/ordersList.vue';
export default {
  name: 'orders',
  components: {
    ordersList
  }
}
</script>