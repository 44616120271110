<template>
  <v-container class="container-bg">
    <v-row>
      <v-col>
        <v-container class="login-container d-flex align-center container-fluid">
          <v-card
            tile
            elevation="0"
            class="py-10"
          >
            <div class="login-header">
              <img width="250" src="../assets/images/logo-original.png" alt="Sourcing Specialists Logo"><br>
            </div>
            <v-form ref="reset" v-on:submit.prevent="submitReset">
              <v-container>
                  <v-text-field
                    v-model="password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="passwordRule"
                    :type="show ? 'text' : 'password'"
                    :label="$t('password')"
                    @click:append="show = !show"
                  ></v-text-field>
                  <v-text-field
                    v-model="password_confirmation"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="passwordConfirmationRule"
                    :type="show ? 'text' : 'password'"
                    :label="$t('password_confirmation')"
                    @click:append="show = !show"
                  ></v-text-field>
              </v-container>
              <v-container>
                <v-row>
                  <v-col
                    lg="6"
                    md="12"
                  >
                    <v-btn
                      :color="$store.getters.vColor"
                      elevation="2"
                      x-large
                      @click.prevent="submitReset"
                      dark
                      type="submit"
                      :loading="loading"
                    >{{ $t('reset') }}</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card>
        </v-container>
      </v-col>
      <v-col
        class="d-none d-lg-block"
      ></v-col>
    </v-row>
  </v-container>
</template>

<script>
import { AUTH_RESET } from '../constants/endpoints';

export default {
  name: 'ResetView',
  components: {

  },
  data() {
    return {
      show: false,
      loading: false,
      password: '',
      password_confirmation: '',
      passwordRule: [
        v => !!v || this.$t('required'),
        v => v.length > 8 || this.$t('password_length'),
      ],
      passwordConfirmationRule: [
        v => v === this.password || this.$t('password_unmatch'),
      ]
    }
  },
  methods: {
    submitReset() {
      if(this.$refs.reset.validate()) {
        this.loading = true;
        this.$http.post(this.buildEndpoint(AUTH_RESET), {
          token: this.$route.query.token,
          password: this.password,
          password_confirmation: this.password_confirmation
        }).then((resp) => {
          if(resp.data.result) {
            this.$toasted.success(this.$t('reset_success'));
            this.$router.push({ name: 'Login' });
          }
        }).finally(() => {
          this.loading = false;
        })
      }
    }
  },
  mounted() {
    if(!this.$route.query.token) {
      this.$router.push({ name: 'Login' });
    }
  }
}
</script>

<style lang="scss" scoped>
  .container-bg {
    background-image: url(../assets/images/login-img.png);
    background-position: center right;
    @media (max-width: '780px') { 
      background-position: bottom right;
      background-size: 100% auto;
    }
  }

  .login-container {
    height: calc(100vh - 60px);
    justify-content: center;
    .login-header {
      text-align: center;
      padding: 15px 15px;
      width: 100%;
      img {
        max-width: 300px;
        width: 100%;
        height: auto;
      }
    }
    .v-card {
      width: 100%;
      max-width: 450px;
    }
    form {
      padding: 25px;
    }
    @media (max-height: 720px) {
      height: auto;
    }
  }
</style>