const messages = {
  dashboard_title: 'Tablero',
  catalogue: 'Catálogo',
  catalogue_flash: 'Catátlogo flash',
  custom_lists: 'Listas personalizadas',
  order: 'Orden | Ordenes',
  zone: 'Zona | Zonas',
  address: 'Dirección',
  delivery_address: 'Dirección de entrega',
  warehouse: 'Deposito | Depositos',
  required_deposit: 'Deposito requerido',
  credit_days: 'Dias de crédito',
  save_changes: 'Guardar cambios',
  users: 'Usuario | Usarios',
  product: 'Producto | Productos',
  option: 'Opción | Opciones',
  item_selected: 'Producto seleccionado | Productos seleccionados',
  item_removed: '{qty} producto eliminados | {qty} productos eliminados',
  delete: 'Borrar',
  delete_question: 'Borrar producto?',
  show_prices: 'Mostrar precios',
  show_hide_price: 'Mostrar o ocultar precios',
  packing: 'Empaque',
  price: 'Precio',
  moq: 'Orden mínima',
  ref: 'Referencia',
  ref_ab: 'Ref',
  origin: 'Origen',
  destination: 'Destino',
  production_time: 'Tiempo de producción',
  hs_code: 'Código HS',
  carton: 'Caja | Cajas',
  day: 'Día | Días',
  mandatory_field: 'Este campo es obligatorio',
  search: 'Buscar',
  search_products: 'Buscar products',
  select: 'Seleccionar',
  categories: 'Categorías',
  product_name: 'Nombre del producto',
  download: 'Descargar',
  currency: 'Moneda',
  friendly_error: 'Lo lamentamos, algo ha salido mal',
  price_tiers: 'Price tiers',
  something_wrong: 'Algo ha salido mal',
  password_length: 'La contraseña necesita 8 caracteres mínimo',
  password_unmatch: 'Las contraseñas no coinciden',
  required: 'Campo requerido',
  password: 'Contraseña',
  password_confirmation: 'Confirmar contraseña',
  email: 'Correo',
  reset: 'Reiniciar',
  reset_success: 'Su contraseña ha sido actualizada',
  forgot_reset_success: 'Hemos enviado un correo a la dirección indicada.',
  forgot_password: 'Olvido la contraseña',
  forgot_password_explain: 'Por favor completa el campo debajo. Te enviaremos un email con detalles de como reiniciar tu contraseña.',
  login: 'Iniciar sesión',
  remember_me: 'Recordarme',
  yes: 'Si',
  field_required: 'Campo requerido',
  dont_have_account: 'No tienes cuenta?',
  create_one: 'Crea una',
  have_account: 'Ya tiene una cuenta?',
  we_only_support: 'De momento solo podemos trabajar con España',
  country: 'País',
  name: 'Nombre',
  last_name: 'Apellido',
  register: 'Registrarse',
  phone_number: 'Teléfono',
  area_code: 'Código de área',
  we_have_sent_email: 'Hemos enviado un código a su correo. Por favor copielo en el campo debajo para proceeder',
  confirm_email: 'Confirmar',
  code: 'Codigo',
  email_taken: 'El correo ya esta en uso. Inicia sesión!',
  invalid_email: 'Correo inválido',
  please_verify: 'Por favor verifique su correo',
  email_sent: 'Correo enviado',
  did_not_receive: 'No ha recibido el correo?',
  send_again: 'Enviar nuevamente!',
  account: 'Cuenta',
  logout: 'Cerrar sesión',
  apply_filters: 'Aplicar filtros',
  product_details: 'Ver detalles',
  add_to_favorites: 'Agregar a favoritos',
  remove_from_favorites: 'Quitar de favoritos',
  checkout: 'Corfimación',
  your_cart: 'Su carrito',
  continue_shopping: 'Continuar comprando',
  update: 'Actualizar',
  english: 'English',
  spanish: 'Español',
  no_items: 'Su carrito esta vacio',
  total: 'Total',
  company: 'Compañia',
  status: 'Estado',
  wrong_credentials: 'Estas credenciales no son correctas',
  created: 'Creada/o',
  reason: 'Razón',
  due_date: 'Vencimiento',
  paid_at: 'Pagado',
  amount: 'Importe',
  payments: 'Pagos',
  inspections: 'Inspecciones',
  shipping_orders: 'Envio | Envios',
  date: 'Fecha',
  action: 'Acción',
  accept_terms: 'He leido y acepto los ',
  terms_conditions: 'términos y condiciones',
  privacy_policy: 'Política de privacidad',
  need_help: 'Necesitas ayuda',
  contact_us: 'Contactanos',
  of: 'de',
  express_explain: 'Productos express tiene entrega mas rápida',
  required_payment: 'Pago requerido',
  home: 'Página principal',
  offers: 'Ofertas',
  express: 'Express',
  favorites: 'Favoritos',
  save: 'Guardar',
  information_updated: 'Información actualizada',
  next: 'Siguiente',
  previous: 'Volver',
  send: 'Enviar',
  subject: 'Asunto',
  message: 'Mensaje',
  message_sent: 'Mensaje enviado',
  products: {
    all_categories: 'Todas',
    no_matches: 'No pudimos encontrar ningún producto para esta selección',
    back_to_catalogue: 'Volver al catálogo',
    please_wait: 'Por favor espere mientras cargamos su producto',
    download_datasheet: 'Descargar detalles',
    add_to_cart: 'Agregar al carrito',
    added_to_cart: 'Producto agregado',
    your_selection: 'Su selección',
    packing: 'Empaque',
    unit_price: 'Precio unitario',
    cartons: 'Cajas',
    total_of: 'Total de {units} unidades',
    show_only_favorites: 'Mostrar favoritos solamente',
    delivery_alert: 'Debido a la crisis con los envios maritimos, estamos experimentando retrasos inesperados. Este producto esta listo entre 12 y 16 semanas, pero por favor tenga en cuenta que estas información puede cambiar.',
    search_products: 'Buscar productos',
    search: 'Buscar',
    quantity: 'Cantidad',
    total_amount: 'Total de ',
    total_units_carton: 'Unidades totales',
    offer: 'Oferta',
    product_has_offer: 'Producto con opciones en oferta',
    product_has_express: 'Producto con opciones express',
    new: 'Nuevo',
    express: 'Express',
    unit_price_before: 'Antes',
    unit: 'Unidad',
    base_unit: 'Unidad base',
    outer_name: 'Externo',
    per: 'por',
    price_per: 'Precio por {desc}',
    options: 'Opciones',
    option_group: 'Grupo',
    see_more_options: 'Ver mas opciones',
    express_selection: 'Selección con opciones express',
    normal_delivery: 'Entrega normal, de 12 a 16 semanas',
    delivery_type: 'Typo de entrega',
    express_select_label: 'Express, {date}',
    express_select_label_within: 'Express, en 5 días',
    product_required_payment: 'Requiere {percent}% de pago: {amount}',
    available_quantity: 'Rápido! Solo quedan {quantity} disponible/s.',
    within_days: 'En {days} días',
    now: 'Ahora',
    selection_has_offer: 'Su selección esta en oferta',
    filter_by_category: 'Filtrar por categoria',
    filters: 'Filtros',
    show_only_offers: 'Ofertas',
    show_only_express: 'Express',
    my_favorites: 'Favoritos',
    buyer_protection: 'Protección al comprador por 30 días.',
    free_shipping: 'Entrega gratis a tu negocio. Incluye seguimiento de tu orden.',
    similar_products: 'Productos similares',
    usually_bought_together: 'Usualmente comprado con los siguiente productos',
    etd_no_express: '12 a 16 semanas',
    clear_selection: 'Limpiar filtros',
    main_categories: 'Categorias',
    subcategories: 'Subcategorías',
    selected_categories: 'Categorías seleccionadas',
    confirm_close_categories: 'Haz cambiado tu selección de categories, si cierras esta ventana los ultimos cambios se perderán.'
  },
  cart: {
    title: 'Carrito',
    checkout: 'Confirmación',
    proceed_to_payment: 'Confirmar y pagar',
    pay_with_credit_card: 'Pagar con tarjeta de crédito',
    pay_with_credit_card_subtitle: 'Nuestros pagos estan procesados por Braintree y son 100% seguros',
    pay_with_transfer: 'Pagar a nuestra cuenta de banco',
    pay_with_transfer_subtitle: 'Transfiere a nuestra cuenta de banco incluyendo el numero de orden.',
    remove: 'Eliminar',
    please_complete_profile: 'Debes completar tu perfil para proceeder',
    you_have: 'Tienes {quantity} {outerDescription} en tu carrito.',
    name_as: 'Nombre como en la tarjeta de crédito',
    card_number: 'Número de tarjeta',
    expiry: 'Vencimiento',
    postal_code: 'Código postal',
    save_payment: 'Desea guardar el método pago para futuras transacciones?',
    'cardholderName-errors': 'Por favor revise el nombre del titular',
    'card-number-errors': 'Por favor inserte un numero de tarjeta correcto',
    'expiration-date-errors': 'Fecha inválida',
    'cvv-errors': 'Código invalido',
    'postal-code-errors': 'Código postal inválido',
    ok_got_it: 'Entendido!',
    paypal_recoverable_error: 'Algo ha salido mal. Intenta nuevamente!',
    paypal_error: 'No pudimos procesar esta transacción.',
    klarna_only_eur: 'Pagos con Klarna solo pueden ser realizados en euros.'
  },
  user_account: {
    my_information: 'Mi información',
    information: 'Información',
    account_locked: 'Recuerde que debe completar su perfil de empresa para poder procesar una orden.',
    profile: 'Perfil',
    company_name: 'Nombre de la compañía',
    business_registration_number: 'Número de registro',
    company_address: 'Direccíon de la empresa',
    delivery_address: 'Dirección de entrega',
    personal_details: 'Detalles personales',
    company_details: 'Detalles de la compañía',
    profile_updated: 'Perfil actualizado',
    phone_number: 'Teléfono',
    address_empty: 'Dejar vacío si es la misma dirección que la compañia',
    pref_categories: 'Categorías de preferencía',
    pref_categories_explain: 'Esta seleccíon filtrara su catálogo la próxima vez que inicie sesión',
    pref_language: 'Lenguaje de preferencia',
    pref_currency: 'Moneda de preferencia',
    payment_methods: 'Métodos de pago',
    delivery_details: 'Detalles de entrega',
    billing_address: 'Dirección de facturación',
    address_line_one: 'Dirección',
    address_line_one_placeholder: 'Apt, Piso, Calle',
    address_line_two: 'Dirección linea 2',
    address_line_two_placeholder: 'Edificio, detalles',
    city: 'Ciudad',
    postal_code: 'Código postal',
    please_add_billing_address: 'Por favor agrega una dirección de facturación',
    delivery_addresses: 'Direcciones de entrega',
    no_delivery_addresses: 'No tiene direcciones de entrega, usaremos tu dirección de facturación por el momento.',
    add_delivery_address: 'Agregar dirección de entrega',
    address_alias: 'Alias',
    alias_placeholder: 'Name for the address. Ej: Casa, Fábrica',
    use_as_default: 'Usar por defecto',
    default_address: 'Dirección por defecto'
  },
  orders: {
    view: 'Ver orden',
    order: 'Orden {order_id}',
    back_to_orders: 'Volver a las órdenes',
    subtotal: 'Subtotal',
    place_at: 'Fecha',
    estimated_delivery: 'Entrega estimada',
    expiring_at: 'Vencimiento',
    expiring_in: 'Vence en {days} día |Vence en {days} días',
    pay_before: 'Paga antes de {date} y asegura tu pedido.',
    only_active: 'Solo Activas',
    all_orders: 'Todas',
    pay_now: 'Pagar {reason} ahora',
    no_payments: 'No tienes pagos registrados por el momento.',
    no_inspections: 'No tienes inspecciones por el momento.',
    tax: 'IVA 21%',
    inspector: 'Inspector',
    result: 'Resultado',
    expired: 'Vencimiento',
    pending: 'Pendiente',
    pass: 'Aprovada',
    forwarder: 'Empresa logistica',
    shipping_products: 'Productos enviados',
    tracking: 'Seguimiento',
    location: 'Posición',
    container_number: 'Número de contenedor',
    no_shipping_orders: 'No tienes envios por el momento.',
    show_all: 'Todas',
    show_active: 'Solo activas',
    order_total: 'Total de la orden',
    delivery: 'Entrega',
    expiration_date_alert: 'Esta orden vence el <strong>{date}</strong>. La orden sera cancelada si no recibimos el pago antes de la fecha indicada.',
    to_pay: 'A pagar',
    order_summary: 'Detalles de tu orden',
    payments_history: 'Historial de pagos',
    no_payments_done: 'No tienes pagos realizados por el momento.',
    no_required_payments: 'No tiene requisitos de pago.',
    download_invoice: 'Descargar factura',
    placed_by: 'Creada por',
    download_order: 'Descargar orden'
  },
  payment: {
    create_payment: 'Crear un nuevo pago',
    payment_updated_successfully: 'Pago actualizado',
    payment_created_successfully: 'Pago creado',
    deposit: 'Deposito',
    balance: 'Balance',
    pay_now: 'Pagar ahora',
    full_name: 'Nombre completo',
    secure_payments: 'Pagos seguros procesados por',
    pay_by_credit_card: 'Pagar con tarjeta de crédito',
    pay_by_transfer: 'Pagar por transferencia',
    payment_options: 'Opciones de pago',
    payment_successful: 'Pago exitoso',
    payment_error: 'Ha habido un error. Por favor intente mas tarde',
    payment_paid: 'Al parecer este pago ya ha sido realizado.',
    make_default: 'Marca como predeterminado',
    is_default: 'Metodo de pago predeterminado',
    disclaimer: 'REVOOLOOP no guarda ningun tipo de informacion sobre tus tarjetas de crédito. Usamos la poderosa API de Paypal y Klarna para procesar todas las transacciones. Tu información esta 100% segura.',
    saved_payment_methods: 'Métodos de pago guardados',
    no_payment_methods: 'No tienes métodos de pago por el momento. Realiza una order primero para poder almacenar métodos.',
    check_your_form: 'Revisa el formulario',
    select_payment_method: 'Método de pago',
    new_credit_card: 'Nueva tarjeta de crédito',
    payment_not_valid: 'Lo sentimos, al parecer este pago no puede ser efectuado. Por favor contacte con soporte.',
    item_count: '{count} producto | {count} productos',
    order_value: 'Importe',
    to_be_paid: 'Pago requerido',
    payment_type: 'Tipo de pago',
    confirm_payment: 'Confirmar pago',
    pending: 'Pendiente',
    paid: 'Pagado',
    first_name: 'Nombre',
    last_name: 'Apellido',
    save_payment_method: 'Guardar método de pago?',
    total_paid: 'Pagado',
    pending_amount: 'Pendiente de pago',
    pay_later: 'Pagar luego',
    pay_with_klarna: 'Pagar ahora con Klarna',
    something_wrong_with_pm: 'Algo salio mal con este metodo de pago, intenta con otro.'
  },
  cart_messages: {
    min_quantity: 'La cantidad mínima es {min_quantity}',
    max_quantity: 'La cantiadad máxima es {max_quantity}',
    product_added: 'Producto agregado',
    quantity_updated: 'Cantidad actualizada',
    product_removed: 'Producto removido'
  }
}

export default messages