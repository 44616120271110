<template>
  <div v-if="!productDetailsLoading">
    <page-header :title="product.name">
      <v-btn
        small
        depressed
        @click="$router.push({ name: 'Catalogue' })"
      >{{ $t('continue_shopping') }}</v-btn>
    </page-header>
    <v-container v-if="product !== null">
      <v-row>
        <v-col
          sm="12"
          md="4"
          lg="5"
          xl="4"
          style="position: relative;"
        >
          <v-chip 
            v-if="is_flash"
            style="position: absolute; top: 15px; right: 15px; z-index: 1;" 
            color="green" 
            dark
          ><v-icon class="mr-3">mdi-truck-fast-outline</v-icon><b>Express</b></v-chip>
          <ProductFavorite :product="product" place="top-left"></ProductFavorite>
          <product-images :images="productImages"></product-images>
          <div class="text-center mt-5">
            <v-btn
              target="_blank"
              :href="product.datasheet_url"
              outlined
              small
            >{{ $t('products.download_datasheet') }}</v-btn>
          </div>
        </v-col>
        <v-col>
          <div>
            <p v-html="product.description.replace(/\n/g, '\n<br/>')" />
          </div>
          <product-option-selection 
            :product="product" 
            :options="product.options"
            :image-count="productImages.length"
            @selectedIsFlash="is_flash = $event"
          ></product-option-selection>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <similar-products :ref-id="product.id"></similar-products>
          <usually-bought-together :ref-id="product.id"></usually-bought-together>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import productOptionSelection from '@/components/catalogue/productOptionSelection.vue';
import productImages from '@/components/catalogue/productImages.vue';
import similarProducts from '@/components/catalogue/similarProducts.vue';
import usuallyBoughtTogether from '@/components/catalogue/usuallyBoughtTogether.vue';
import ProductFavorite from '../../components/catalogue/productFavorite.vue';

export default {
  name: 'productDetails',
  components: {
    productOptionSelection,
    productImages,
    similarProducts,
    usuallyBoughtTogether,
    ProductFavorite
  },
  data() {
    return {
      is_flash: false,
    }
  },
  computed: {
    ...mapGetters([
      'productDetailsDialog',
      'productDetailsLoading',
      'productDetails',
      'productDetailsLoading'
    ]),
    mainImage() {
      if(this.product === null) {
        return null;
      }
      if(this.productDetails.images.length > 0) {
        return this.productDetails.images[0].large;
      }
      return null;
    },
    productImages() {//includes options images if any
      var images = this.product.images;
      for(var i in this.options) {
        if(this.options[i].images && this.options[i].images.length > 0) {
          for(var e in this.options[i].images) {
            images.push(this.options[i].images[e]);
          }
        }
      }
      return images;
    },
    product() {
      return this.productDetails;
    },
    options() {
      return this.product.options;
    },
    hasOffer() {
      if(this.product.id === 195 && process.env.NODE_ENV === 'development') {
        return true;
      }
      return this.offerShouldApply(this.product.offer);
    }
  },
  methods: {
    ...mapMutations([
      'toggleProductDialog'
    ]),
    ...mapActions(['loadProductDetails']),
    inCart() {
      return false;
    }
  },
  created() {
    if(this.$route.params.product_id) {
      this.loadProductDetails(this.$route.params.product_id);
    }
  }
}
</script>

