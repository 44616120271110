<template>
  <div>
    <v-data-table
      :search="table.search"
      :headers="table.headers"
      :items="products"
      hide-default-footer
    >
      <template v-slot:[`item.image`]="{ item }">
        <img :src="item.media.small" width="50px" />
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <h4>{{ item.name }} - <strong class="mColor-text">{{ item.option.key }}:</strong> {{ item.option.value }}</h4>
        <div
          class="text-caption"
          v-if="item.attributes.length > 0"
        >
          <span
            class="pr-3"
            v-for="attr in item.attributes"
            :key="attr.key"
          ><strong>{{ attr.key }}:</strong> {{ attr.value }}</span>
        </div> 
      </template>
      <template v-slot:[`item.files`]="{ item }">
        {{ item.designs.length }}
      </template>
      <template v-slot:[`item.quantity`]="{ item }">
        <h4>{{ item.quantity }} {{ $tc('carton', item.quantity) }}</h4>
        <p class="pa-0 mb-0">{{ item.total_units }} {{ unit(item) }}</p>
      </template>
      <template v-slot:[`item.total_price_string`]="{ item }">
        <h4>{{ item.total_price_string }}</h4>
        <p class="pa-0 mb-0">{{ item.unit_price_string }} {{ unit(item, 1) }}</p> 
      </template>
    </v-data-table>
  </div>
</template>
<script>

export default {
  name: 'OrderProductsList',
  props: {
    products: {
      default: []
    },
    isPreview: {
      default: false
    }
  },
  data() {
    return {
      table: {
        search: '',
        headers: [
          { text: '', align: 'start', sortable: false, value: 'image' },
          //{ text: this.$t('ref'), align: 'start', sortable: false, value: 'ref' },
          { text: this.$t('product_name'), align: 'start', sortable: false, value: 'name' },
          { text: this.$t('products.quantity'), align: 'start', sortable: false, value: 'quantity' },
          { text: this.$t('price'), align: 'end', value: 'total_price_string' },
        ],
      },
      productEditDialog: false,
      editingProduct: {}
    }
  },
  methods: {
    unit(item, q = 0) {
      const qty = q === 0 ? item.total_units : q
      const unitDescription = item.is_tree === 1 ? item.packing_base_unit_description : this.$tc('products.unit', qty)
      return `${unitDescription}`
    }
  }
}
</script>