<template>
  <v-card
    outlined
    :class="['tile-content', { is_flash: product.is_flash }]"
    hover
    rounded="lg"
    @click="$router.push({ name: 'CatalogueProduct', params: { product_id: product.id }})"
  >
    <product-flags :product="product" />
    <ProductFavorite :product="product" place="top-right"></ProductFavorite>
    <div class="px-5 pt-5">
      <img
        class="tile-product-image"
        contain
        :src="productImage"
      />
    </div>
    <div class="d-flex align-center justify-center pt-3 pb-5">
      <div class="px-6 text-center tile-product">
        <div class="tile-product_name">{{ product.name }} <!--- <span>{{ mainOption.group_name }} {{ mainOption.group_value }}</span>--> </div>
        <template v-if="displayPrice">
          <div class="tile-product_price">{{ numberToNiceString(mainOption.price.unit_price, getCurrency, null) }} <span>{{ unitPriceDescriptionString(mainOption.packing) }}</span></div>
          <p v-if="unitSubdivisionPriceString(mainOption)" class="font-italic mb-0">{{ unitSubdivisionPriceString(mainOption) }}</p>
        </template>
      </div>
    </div>
  </v-card>
</template>

<script> 
import { mapGetters } from 'vuex';
import noImage from '../../assets/images/not-found.png';
import productFlags from './productFlags.vue';
import ProductFavorite from './productFavorite.vue';

export default {
  name: 'ProductAsTile',
  components: {
    productFlags,
    ProductFavorite
  },
  props: {
    product: {
      type: Object,
      default: () => {
        return {
          img: '',
          ref: '',
          name: '',
          description: '',
          packing: '',
          carton: '',
          price: ''
        }
      }
    },
    addCart: {
      default: false
    },
    isNovelties: {
      default: false
    },
    flash: {
      default: 0
    }
  },
  data() {
    return {
      showCertificationsModal: false,
      productImage: noImage,
    }
  },
  watch: {
    product() {
      this.testImage();
      this.loadImage();
    }
  },
  computed: {
    ...mapGetters(['getCurrency', 'user']),
    mainOption() {
      if(this.product.options[0] !== undefined) {
        return this.product.options[0]
      }
      return {}
    },
    addToCart() {
      return this.addCart ? true : false;
    },
    isFlash() {
      if(this.flash === 1) {
        return true;
      }
      return false;
    },
    flashData() {
      return this.isFlash ? this.product.unsold_ref_info : {};
    }
  },
  methods: {
    rangedPrice(options) {
      var options_price = [];
      for(var i in options) {
        options_price.push(options[i].tiers[0].cost_per_unit);
      }
      if(options_price.length > 1) {
        var from = options_price[0];
        var to = options_price[options_price.length - 1];
        return this.numberToNiceString(from, this.getCurrency)+' - '+this.numberToNiceString(to, this.getCurrency);
      } else {
        return this.numberToNiceString(options_price[0], this.getCurrency);
      }
    },
    loadImage() {
      this.productImage  = this.product.images[0].small;
    },
    testImage() {
      if(this.product.images[0]) {
        var tester = new Image();
        tester.onload = this.loadImage;
        tester.src = this.product.images[0].small;
      }
    }
  },
  mounted() {
    this.testImage();
  }
}
</script>
<style lang="scss" scoped>
.tile-content {
  width: 100%;
  //display: table;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  //border: 1px solid #E2E2E2;
  //flex-direction: column;
  .tile-product {
    &_name {
      font-weight: 700;
      font-size: 18px;
    }
    &_price {
      font-weight: 700;
      color: var(--v-secondary-base);
      font-size: 16px;
    }
  }
}
.asTile {
  background-color: #F2F2F2;
  //border-bottom-left-radius: 5px;
  //border-bottom-right-radius: 5px;
  padding: 0px !important;
  vertical-align: bottom;
  display: table-row-group;
}
.tile-product-image {
  width: 100%;
  height: auto;
}
</style>