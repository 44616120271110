import controller from './controller';
import { CATEGORIES_LIST, CATALOGUE_LIST, GET_PRODUCT, ADD_PRODUCT_TO_FAVORITES, REMOVE_PRODUCT_TO_FAVORITES } from '../constants/endpoints.js';

export default class catalogueController extends controller {

  async addProductToFavorites(product_id) {
    return new Promise((resolve) => {
      this.sendRequest('POST', this.buildEndpoint(ADD_PRODUCT_TO_FAVORITES, { product_id: product_id }), null).then(resp => {
        resolve(resp.data.data);
      });
    });
  }

  async removeProductToFavorites(product_id) {
    return new Promise((resolve) => {
      this.sendRequest('POST', this.buildEndpoint(REMOVE_PRODUCT_TO_FAVORITES, { product_id: product_id }), null).then(resp => {
        resolve(resp.data.data);
      });
    });
  }

  async getProducts(params) {
    return new Promise((resolve) => {
      this.sendRequest('GET', CATALOGUE_LIST, params).then(resp => {
        resolve(resp.data.data);
        //console.log(resp.data.data);
      });
    });
  }

  async getSimilarProducts(params) {
    return new Promise((resolve) => {
      this.sendRequest('GET', CATALOGUE_LIST, params).then(resp => {
        resolve(resp.data.data);
        //console.log(resp.data.data);
      });
    });
  }

  async getProduct(id, currency) {
    return new Promise((resolve) => {
      this.sendRequest('GET', this.buildEndpoint(GET_PRODUCT, { product_id: id }), { currency: currency }).then(resp => {
        resolve(resp.data.data);
      });
    });
  }

  async getCategories() {
    return new Promise((resolve) => {
      this.sendRequest('GET', CATEGORIES_LIST, {}).then(resp => {
        resolve(resp.data.data);
      });
    });
  }

}