<template>
  <div>
    <v-divider></v-divider>
    <div class="pt-5 text-center">
      {{ $t('payment.secure_payments') }}<br>
      <img :src="btLogo" alt="Braintree" width="200">
      <div style="font-size: 12px" class="font-italic">{{ $t('payment.disclaimer') }}</div>
    </div>
  </div>
</template>

<script>
import btLogo from '../assets/images/bt-logo.png';
export default {
  name: 'paymentDisclaimer',
  data() {
    return {
      btLogo
    }
  }
}
</script>