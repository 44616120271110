import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import VuePageTransition from 'vue-page-transition';

//components
import Login from '../views/login.vue';
import Reset from '../views/reset.vue';
import Register from '../views/register.vue';
import Catalogue from '../views/catalogue/index.vue';
import CatalogueProduct from '../views/catalogue/product.vue';
import Account from '../views/account.vue';
import Cart from '../views/cart/cart.vue';
import CartCheckout from '../views/cart/checkout.vue';
import Orders from '../views/orders/index.vue';
import OrderView from '../views/orders/viewOrder.vue';

Vue.use(VueRouter);
Vue.use(VuePageTransition);

//in meta titles use the translation index

export const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false,
      title: 'login'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      requiresAuth: false,
      title: 'register'
    }
  },
  {
    path: '/reset',
    name: 'Reset',
    component: Reset,
    meta: {
      requiresAuth: false,
      title: 'reset'
    }
  },
  {
    path: '/catalogue',
    name: 'Catalogue',
    component: Catalogue,
    meta: {
      title: 'catalogue',
      breadcrumbs: {
        title: 'Catalogue'
      }
    }
  },
  {
    path: '/catalogue/:product_id',
    name: 'CatalogueProduct',
    props: true,
    component: CatalogueProduct,
    meta: {
      title: 'product',
      breadcrumbs: {
        title: 'Product',
        parents: ['Catalogue'] //must be the parent route name
      }
    }
  },
  {
    path: '/',
    name: 'home',
    component: Catalogue,
    meta: {
      title: 'catalogue',
      breadcrumbs: {
        title: 'Catalogue'
      }
    }
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart,
    meta: {
      title: 'cart.title',
      breadcrumbs: {
        title: 'Cart'
      }
    }
  },
  {
    path: '/cart/checkout/:payment_secret',
    name: 'Checkout',
    component: CartCheckout,
    props: true,
    meta: {
      title: 'cart.checkout',
      breadcrumbs: {
        title: 'Checkout'
      }
    }
  },
  {
    path: '/orders',
    name: 'Orders',
    component: Orders,
    meta: {
      title: 'order',
      breadcrumbs: {
        title: 'Orders'
      }
    }
  },
  {
    path: '/orders/:order_id',
    name: 'OrderView',
    component: OrderView,
    props: true,
    meta: {
      title: 'orders.view',
      breadcrumbs: {
        title: 'View order',
        parents: ['Orders'] //must be the parent route name
      }
    }
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {
      title: 'account',
      breadcrumbs: {
        title: 'Account'
      }
    }
  },
  {
    path: '/logout',
    name: 'Logout',
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//validate routes and check sessions
router.beforeEach( function(to, from, next) {

  //logout directly
  if(to.name === 'Logout') {
    store.dispatch('logout');
  }
  
  //check session length and renew
  var sessionLength = 60*30*1000;
  if((new Date().getTime() - store.getters.sessionTime) > sessionLength) {
    if(to.meta.requiresAuth !== false) {
      //store.dispatch('logout');this is not ready, the logout happen also on pages outside the protected routes
    }
  } else {
    store.commit('resetSessionTime');
  }
  //redirect login to dashboard
  if (to.meta.requiresAuth === false && store.getters.isAuthenticated) {
    next({ name: 'home' });
    return;
  }
  //confirm if there is not auth require
  if(to.meta.requiresAuth === false) {
    next();
    return;
  }
  //logout on session expired
  if (to.meta.requiresAuth !== false && !store.getters.isAuthenticated) {
    next({ name: 'Login', params: { redirect: to.fullPath } });
  } else {
    next();
  }
});

export default router
