import controller from './controller';
import { 
  AUTH_LOGIN, 
  AUTH_LOGOUT, 
  AUTH_CHECK, 
  AUTH_REGISTER_RESEND_CONFIRM_CODE, 
  AUTH_REGISTER_CONFIRM_CODE 
} from '../constants/endpoints.js';

const ID = process.env.VUE_APP_API_ENDPOINT_ID;

export default class authController extends controller {

  async login(params) {
    params.saler_token = ID;
    return new Promise((resolve) => {
      this.sendRequest('POST', AUTH_LOGIN, params).then(resp => {
        if(resp.data.result === true) {
          resolve(resp.data);
        } else {
          resolve(resp.data);
        }
      });
    });
  }

  async logout() {
    return new Promise((resolve) => {
      this.sendRequest('POST', AUTH_LOGOUT, {}).then(resp => {
        resolve(resp.data.data);
      });
    });
  }

  async confirmCode(params) {
    return new Promise((resolve) => {
      this.sendRequest('POST', AUTH_REGISTER_CONFIRM_CODE, params).then(resp => {
        resolve(resp.data);
      });
    });
  }

  async resendConfirmationCode(params) {
    return new Promise((resolve) => {
      this.sendRequest('POST', AUTH_REGISTER_RESEND_CONFIRM_CODE, params).then(resp => {
        resolve(resp.data);
      });
    });
  }

  async checkSession() {
    try {
      await this.sendRequest('POST', AUTH_CHECK, {});
      return true;
    } catch {
      return false;
    }
  }

}