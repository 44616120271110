<template>
  <div>
    <v-container v-if="products.length > 0" style="padding-bottom: 0px;" fluid>
      <v-card
        flat
      >
        <v-data-table
          :headers="header"
          :items="products"
          item-key="idd"
          hide-default-footer
          class="products-in-cart"
        >
          <template v-slot:[`header.to_pay`]>
            <span>{{ $t('orders.to_pay') }}</span>
            <v-btn
              fab
              x-small
              icon
              @click="toggleHelpers({ show: true, data: 'to_pay'})"
            ><v-icon small>mdi-help-circle-outline</v-icon></v-btn>
          </template>
          <template v-slot:[`item.image`]="{ item }">
            <div
              style="overflow: hidden;"
            ><img width="100px" :src="item.image"></div>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <h3 class="mColor-text">{{ item.product_name }}</h3>
            <div>
              <span><strong>{{ item.option_group_name }}:</strong> {{ item.option_group_value }}</span>
              <span v-for="attr in item.attributes_data" :key="attr.id"> - <strong>{{ attr.group_name }}:</strong> {{ attr.value }}</span>
            </div>
            <a href="#"
              style="font-size: 13px"
              @click.prevent="$store.dispatch('cart/removeFromCart', item.idd)"
            >{{ $t('cart.remove') }}</a>
          </template>
          <template v-slot:[`item.etd`]="{ item }">
            <div v-if="item.is_flash" class="font-italic text-caption">{{ calcArrival(item) }}</div>
            <div v-else class="font-italic text-caption">{{ $t('products.etd_no_express') }}</div>
          </template>
          <template v-slot:[`item.quantity`]="{ item }">
            <v-text-field
              class="number-input"
              :value="item.quantity"
              @keyup.enter="updQty(item.idd, $event)"
              @blur="updQty(item.idd, $event)"
              dense
              hide-details
            >
              <v-btn
                class="quantity-input-actions"
                slot="prepend-inner"
                small
                :disabled="item.quantity === item.min_order"
                depressed
                tile
                color="blue-grey lighten-4"
                @click="item.quantity-- && updateQuantity({ idd: item.idd, quantity: item.quantity, change: true })"
              ><v-icon small>mdi-minus</v-icon></v-btn>
              <v-btn
                class="quantity-input-actions"
                slot="append"
                small
                depressed
                tile
                color="primary"
                @click="item.quantity++ && updateQuantity({ idd: item.idd, quantity: item.quantity, change: true })"
              ><v-icon small>mdi-plus</v-icon></v-btn>
            </v-text-field>
            <div class="mt-1" style="font-size: 11px; text-align: center;">
              {{ item.quantity }} {{ item.packing.unit_outer_description_text }}(s)
              <span v-if="item.packing.unit_outer_quantity !== null">, {{ item.packing.unit_outer_quantity*item.quantity }} {{ item.packing.unit+'(s)' }}</span>
            </div>
          </template>
          <template v-slot:[`item.subtotal`]="{ item }">
            <div class="font-weight-bold">{{ numberToNiceString(item.subtotal, getCurrency, 2) }}</div>
          </template>
          <template v-slot:[`item.to_pay`]="{ item }">
            <span class="font-weight-bold">{{ numberToNiceString(item.required_payment, getCurrency, 2) }} </span>
            <span class="font-italic" style="font-size: 10px;">({{ item.is_flash ? 100 : user_deposit }}%)</span>
          </template>
          <template v-slot:[`body.append`]>
            <tr
              class="total-rows"
              v-for="total in totals"
              :key="total.name"
            >
              <td colspan="4"></td>
              <td style="text-align: right;" :class="[{ 'primary--text': total.ref === 'to_pay' }]"><span class="font-weight-bold">{{ total.name }}</span></td>
              <td style="text-align: right;" :class="[{ 'primary--text': total.ref === 'to_pay' }, 'font-weight-bold']">{{ total.value }}</td>
            </tr>
            <tr class="total-rows">
              <td colspan="4"></td>
              <td style="text-align: right;"><span class="font-weight-bold">{{ $t('ref') }}:</span></td>
              <td>
                <v-text-field
                  dense
                  v-model="reference"
                  hide-details
                ></v-text-field>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <v-container v-else>
      <div>The cart is empty</div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
//import deliveryAlerts from '../deliveryAlerts.vue';
//import ExpressBubble from '../miscellaneous/expressBubble.vue';

export default {
  name: 'cartContent',
  props: ['checkout'],
  components: {
    //deliveryAlerts, 
    //ExpressBubble
  },
  data() {
    return {
      header: [
        { text: '', align: 'start', sortable: false, value: 'image' },
        { text: this.$tc('product',0), align: 'start', value: 'name' },
        { text: this.$t('orders.estimated_delivery'), sortable: false, align: 'start', value: 'etd' },
        { text: this.$t('products.quantity'), sortable: false, align: 'start', width: 150, value: 'quantity' },
        { text: this.$t('orders.to_pay'), align: 'end', sortable: false, width: 150, value: 'to_pay' },
        { text: this.$t('orders.subtotal'), align: 'end', width: 120, value: 'subtotal' },
      ],
      qty: 0,
      reference: null
    }
  },
  computed: {
    isCheckout() {
      return this.checkout == '' ? true : false
    },
    totals: function() {
      const subtotal = {
        name: `${this.$t('orders.subtotal')}:`,
        value: this.numberToNiceString(this.subtotal, this.getCurrency, 2),
      }
      const tax = {
        name: `${this.$t('orders.tax')}:`,
        value: this.numberToNiceString(this.tax, this.getCurrency, 2),
      }
      const total = {
        name: `${this.$t('total')}:`,
        value: this.numberToNiceString(this.total, this.getCurrency, 2)
      }
      const deposit = {
        ref: 'to_pay',
        name: `${this.$t('required_payment')}:`,
        value: this.numberToNiceString(this.required_payment, this.getCurrency, 2)
      }
      return [subtotal, tax, total, deposit]
    },
    ...mapGetters('cart', [
      'products',
      'company',
      'subtotal',
      'cbm',
      'distribution',
      'incoterm',
      'weight',
      'tax',
      'total',
      'required_payment'
    ]),
    ...mapGetters([
      'getCurrencyText',
      'getCurrency',
      'user_deposit'
    ]),
    onlyFlash() {
      for(var i in this.products) {
        if(this.products[i].is_flash === false) {
          return false;
        }
      }
      return true;
    },
  },
  methods: {
    ...mapMutations(['toggleHelpers']),
    ...mapActions('cart', [
      'updateQuantity',
      'confirmOrder'
    ]),
    updQty(idd, event) {
      const existing = this.products.find(x => x.idd === idd);
      if(existing.quantity != event.target.value) {
        this.updateQuantity({ 
          idd: idd, 
          quantity: event.target.value,
          valueChanged: true 
        }).then(qty => {
          event.target.value = qty
          event.target.dispatchEvent(new Event('input'))
        });
      }
    },
    calcArrival(item) {
      var express_label = this.$t('products.express_select_label', { date: item.option_unsold_target_delivery });
      if(item.option_unsold_arrived) {
        express_label = this.$t('products.express_select_label_within');
      }
      return express_label;
    }
  }
}
</script>
<style lang="scss" scoped>
.cart_item {
  background-color: #FFF;
  border: 1px solid #E2E2E2;
  border-radius: 8px;
  transition: all ease 0.3s;
  position: relative;

  &.is_flash {
    &:hover {
      background-color: #E1F5FE !important;
    }
  }
}
.product_content {
  position: relative;
}
.cart_total {
  display: inline-grid;
  grid-template-columns: 30% 30% 40%;
  li {
    display: flex;
    text-align: right;
    font-size: 14px;
    align-items: center;
    flex-direction: row-reverse;
  }
}
</style>

<style lang="css">
.number-input .v-text-field__slot input {
  text-align: center;
}
.quantity-input-actions {
  min-width: 20px !important;
  padding: 0 5px !important;
}

</style>

<style lang="scss">
.products-in-cart {
  th {
    border-top: thin solid rgba(0, 0, 0, 0.12) !important;
    &:first-child {
      border-left: thin solid rgba(0, 0, 0, 0.12) !important;
      border-top-left-radius: 4px;
    }
    &:last-child {
      border-right: thin solid rgba(0, 0, 0, 0.12) !important;
      border-top-right-radius: 4px;
    }
  }
  tbody {
    tr {
      td {
        &:first-child {
          border-left: thin solid rgba(0, 0, 0, 0.12);
          border-top-left-radius: 0px !important;
        }
        &:last-child {
          border-right: thin solid rgba(0, 0, 0, 0.12);
          border-top-right-radius: 0px !important;
        }
      }
    }
  }
  .total-rows {
    &:hover {
      background: none !important;
    }
    td {
      border: none !important;
    }
  }
}
</style>