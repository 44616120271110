<template>
  <div class="pa-8">
    <page-header :title="$t('payment.saved_payment_methods')"></page-header>
    <v-row v-if="payment_methods.length > 0">
      <v-col
        v-for="pm in payment_methods"
        :key="pm.globalId"
      >
        <v-card
          class="d-flex pa-4 align-center"
          flat
          outlined
          rounded="xl"
        >
          <div>
            <img :src="pm.imageUrl">
          </div>
          <div class="pl-3">
            <div class="font-weight-bold">{{ pm.cardholderName }}</div>
            <div>{{ pm.maskedNumber }}</div>
            <a
              v-if="pm.default === false"
              href="#"
              @click.prevent="setAsDefault(pm.token)"
            >{{ $t('payment.make_default') }}</a>
            <div v-if="pm.default">{{ $t('payment.is_default') }}</div>
          </div>
          <div>
            <v-btn
              fab
              absolute
              style="top: calc(50% - 22px); right: -10px"
              small
              @click="deleteBtPaymentMethod(pm.token)"
            ><v-icon>mdi-close</v-icon></v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else><v-col class="pa-5">{{ $t('payment.no_payment_methods') }}</v-col></v-row>
    <v-row>
      <v-col>
        <payment-disclaimer></payment-disclaimer>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import paymentController from '../../controllers/paymentController';
import PageHeader from '../pageHeader.vue';
import paymentDisclaimer from '../paymentDisclaimer.vue';

const p = new paymentController();

export default {
  name: 'paymentMethods',
  components: {
    paymentDisclaimer,
    PageHeader
  },
  data() {
    return {
      payment_methods: []
    }
  },
  methods: {
    ...mapMutations(['isLoading']),
    reArrange(pm) {
      const def = pm.findIndex(p => p.default === true);
      pm.splice(0, 0, pm.splice(def, 1)[0]);//help me to reorder default to the beginning
      return pm;
    },
    async getPaymentMethods() {
      this.isLoading(true);
      const pm = await p.getBtPaymentMethods();
      if(pm.length > 0) {
        this.payment_methods = this.reArrange(pm);
      }
      this.isLoading(false);
    },
    async deleteBtPaymentMethod(token) {
      this.isLoading(true);
      await p.deleteBtPaymentMethod({ payment_method_token: token });
      this.getPaymentMethods();
    },
    async setAsDefault(token) {
      this.isLoading(true);
      await p.updateBtPaymentMethod({ payment_method_token: token });
      this.getPaymentMethods();
    },
  },
  created() {
    this.getPaymentMethods();
  }
}
</script>