<template>
  <ais-instant-search
    index-name="products"
    :search-client="searchClient"
    :search-function="searchFunction"
    class="searchBox"
  >
    <ais-autocomplete>
      <div class="search" slot="default" slot-scope="{ refine }">
        <ais-hits>
          <div slot-scope="{ items }">
            <v-combobox
              v-model="search"
              :value="newSearch"
              @update:search-input="refine(newSearch)"
              @change="goSearch"
              :search-input.sync="newSearch"
              :items="items"
              outlined
              filled
              dense
              dark
              hide-no-data
              hide-details
              :item-text="`name_${$store.getters.getLanguage}`"
              item-value="ref"
              clearable
              :label="$t('products.search_products')"
            >
              <template v-slot:item="{ item }">
                <v-list-item-avatar>
                  <v-img v-if="item.image !== null" :src="item.image"></v-img>
                  <v-icon v-else>mdi-image</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <strong><ais-highlight :attribute="`name_${$store.getters.getLanguage}`" :hit="item"/></strong>
                  </v-list-item-title>
                  <v-list-item-subtitle><ais-highlight attribute="ref" :hit="item"/></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-combobox>
          </div>
        </ais-hits>
      </div>
    </ais-autocomplete>

  </ais-instant-search>
</template>

<script>

import Vue from 'vue';
import InstantSearch from 'vue-instantsearch';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';

//searching tool
Vue.use(InstantSearch);

export default {
  name: 'searchBar',
  props: ['value'],
  data() {
    return {
      search: '',
      newSearch: '',
      searchClient: instantMeiliSearch(
        process.env.VUE_APP_MEILISEARCH_HOST,
        process.env.VUE_APP_MEILISEARCH_KEY
      ),
      searchFunction: (helper) => {
        if(this.$store.getters.getIncoterm === 'REVOOLOOP') {
          helper.addNumericRefinement('is_revooloop', '=', '1');
          helper.addNumericRefinement('is_available', '=', '1');
          helper.addNumericRefinement('is_valid', '=', '1');
        }
        helper.search();
      }
    }
  },
  computed: {
    ...mapGetters(['catalogueSearch']),
    resultsLength() {
      return this.indices[0].hits.length;
    },
    searchKeyword() {
      return (this.search !== null && this.search[`name_${this.$store.getters.getLanguage}`]) ? this.search[`name_${this.$store.getters.getLanguage}`] : this.search;
    }
  },
  methods: {
    ...mapMutations(['setCatalogueSearch']),
    ...mapActions(['loadProducts']),
    goSearch() {
      //console.log(this.searchKeyword);
      if(this.searchKeyword !== null) {
        this.addParamsToLocation({ search: this.searchKeyword }, this.$router.history.current.path);
      }
      this.setCatalogueSearch(this.searchKeyword);
      this.loadProducts({});
    }
  }
}
</script>

<style>
.searchBox {
  width: 100%;
}
</style>