import controller from './controller';
import { 
  CREATE_ORDER, 
  GET_ORDERS, 
  GET_ORDER, 
  GET_ORDER_PRODUCTS, 
  GET_ORDER_PAYMENTS,
  GET_ORDER_INSPECTIONS,
  GET_ORDER_TRACKING 
} from '../constants/endpoints.js';

export default class orderController extends controller {

  async createOrder(params) {
    return new Promise((resolve) => {
      this.sendRequest('POST', CREATE_ORDER, params).then(resp => {
        resolve(resp.data);
      });
    });
  }

  async getOrders(params) {
    return new Promise((resolve) => {
      this.sendRequest('GET', GET_ORDERS, params).then(resp => {
        resolve(resp.data.data);
      });
    });
  }

  async getOrder(order_id) {
    return new Promise((resolve) => {
      this.sendRequest('GET', this.buildEndpoint(GET_ORDER, { order_id: order_id }), {}).then(resp => {
        resolve(resp.data.data);
      });
    });
  }

  async getOrderProducts(order_id) {
    return new Promise((resolve) => {
      this.sendRequest('GET', this.buildEndpoint(GET_ORDER_PRODUCTS, { order_id: order_id }), {}).then(resp => {
        resolve(resp.data.data);
      });
    });
  }

  async getOrderPayments(order_id) {
    return new Promise((resolve) => {
      this.sendRequest('GET', this.buildEndpoint(GET_ORDER_PAYMENTS, { order_id: order_id }), {}).then(resp => {
        resolve(resp.data.data);
      });
    });
  }

  async getOrderInspections(order_id) {
    return new Promise((resolve) => {
      this.sendRequest('GET', this.buildEndpoint(GET_ORDER_INSPECTIONS, { order_id: order_id }), {}).then(resp => {
        resolve(resp.data.data);
      });
    });
  }

  async getOrderTracking(order_id) {
    return new Promise((resolve) => {
      this.sendRequest('GET', this.buildEndpoint(GET_ORDER_TRACKING, { order_id: order_id }), {}).then(resp => {
        resolve(resp.data.data);
      });
    });
  }

}