<template>
  <div style="position: relative;">
    <loader v-model="loading"></loader>
    <subsection-header v-if="false" icon="mdi-basket-check-outline" :title="$t('orders.order_summary')" hide-divider />
    <v-card
      flat
      outlined
      class="py-5"
    >
      <v-data-table
        :headers="headers"
        :items="orderProducts"
        hide-default-footer
        dense
      >
        <template v-slot:[`item.image`]="{ item }">
          <img :src="item.media.small" width="50px" />
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <div class="py-3">
            <h4 class="mColor-text">{{ item.name }}</h4>
            <div class="caption">
              <span><strong>{{ item.option.key }}:</strong> {{ item.option.value }}</span>
              <span v-for="attr in item.attributes" :key="attr.key"> - <strong>{{ attr.key }}:</strong> {{ attr.value }}</span>
            </div>
            <div style="font-size: 12px"><span class="font-weight-bold">{{ $t('products.quantity') }}: </span>{{ item.quantity }} {{ item.packing.unit_outer_description_text }}(s)</div>
          </div>
        </template>
        <template v-slot:[`item.subtotal`]="{ item }">
          <div class="font-weight-bold">{{ item.total_price_string }}</div>
        </template>
        <template v-slot:[`item.to_pay`]="{ item }">
          <span class="font-weight-bold">{{ item.required_payment }} </span>
          <span class="font-italic" style="font-size: 10px;">({{ item.is_flash ? 100 : '%%' }}%)</span>
        </template>
        <template v-slot:[`body.append`]>
          <tr class="total-rows">
            <td></td>
            <td style="text-align: right;"><span class="font-weight-bold">{{ $t('payment.order_value') }}</span></td>
            <td style="text-align: right;">{{ order.total }}</td>
          </tr>
          <tr class="total-rows">
            <td></td>
            <td style="text-align: right;" :class="['primary--text']"><span class="font-weight-bold">{{ $t('payment.to_be_paid') }}</span></td>
            <td style="text-align: right;" :class="['primary--text', 'font-weight-bold']">{{ numberToNiceString(payment.amount, payment.currency, 2) }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import orderController from '../../controllers/orderController';
import loader from '../loadingBox.vue';

const oc = new orderController();

export default {
  name: 'orderReview',
  props: ['order-id', 'payment'],
  components: {
    loader
  },
  data() {
    return {
      order: {},
      orderProducts: [],
      loading: true,
      headers: [
        { text: '', align: 'start', sortable: false, width: 50, value: 'image' },
        { text: this.$tc('product', 1), align: 'start', value: 'name' },
        { text: this.$t('orders.subtotal'), align: 'end', value: 'subtotal' },
      ],
    }
  },
  computed: {
    itemCount() {
      return this.orderProducts.length;
    }
  },
  watch: {
    orderId() {
      this.loading = true;
      this.getOrder();
    }
  },
  methods: {
    async getOrder() {
      if(this.orderId) {
        const order = await oc.getOrder(this.orderId);
        this.order = order.order;
        this.getOrderProducts();
      }
    },
    async getOrderProducts() {
      if(this.orderId) {
        const prod = await oc.getOrderProducts(this.orderId);
        this.orderProducts = prod.items;
        this.loading = false;
      }
    },
  },
  created() {
    this.getOrder();
  }
}
</script>

<style lang="scss">
.total-rows {
  &:hover {
    background: none !important;
  }
  td {
    border: none !important;
  }
}
</style>