<template>
  <div class="profile">
    <page-header :title="$t('user_account.my_information')" />
    <v-row>
      <v-col>
        <v-card
          outlined
          flat
        >
          <v-tabs
            dark
            background-color="secondary"
            v-model="tab"
            @change="replace"
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#company">
              <v-icon class="mr-2">mdi-account-supervisor-circle</v-icon><span v-if="$vuetify.breakpoint.mdAndUp">{{ $t('user_account.company_details') }}</span>
            </v-tab>
            <v-tab href="#user">
              <v-icon class="mr-2">mdi-account</v-icon><span v-if="$vuetify.breakpoint.mdAndUp">{{ $t('user_account.personal_details') }}</span>
            </v-tab>
            <v-tab href="#payment_methods" v-if="false">
              {{ $t('user_account.payment_methods') }}
              <v-icon left>
                mdi-credit-card-settings-outline
              </v-icon>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item value="user">
              <v-form ref="userAccount" class="pa-8" @submit.prevent="updateUserDetails">
                <v-row>
                  <v-col md="6">
                    <v-text-field
                      v-model="profile.name"
                      :label="$t('name')"
                      :rules="requiredRule"
                    ></v-text-field>
                  </v-col>
                  <v-col md="6">
                    <v-text-field
                      v-model="profile.last_name"
                      :label="$t('last_name')"
                      :rules="requiredRule"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="profile.email"
                      :label="$t('email')"
                      :rules="requiredRule"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      v-model="profile.lang"
                      :items="[
                        { text: $t('english'), value: 'en' },
                        { text: $t('spanish'), value: 'es' }
                      ]"
                      :label="$t('user_account.pref_language')"
                      :rules="requiredRule"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div class="d-flex">
                      <v-text-field
                        v-model="password"
                        :label="$t('password')"
                        :rules="canChangePassword ? requiredRule : []"
                        :disabled="!canChangePassword"
                      ></v-text-field>
                      <v-btn
                        @click="canChangePassword = !canChangePassword"
                        depressed
                        fab
                        text
                      ><v-icon>mdi-pen</v-icon></v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-btn
                    color="primary"
                    depressed
                    type="submit"
                    :loading="loading"
                  >{{ $t('update') }}</v-btn>
                </v-row>
              </v-form>
            </v-tab-item>
            <v-tab-item value="payment_methods">
              <account-payment-methods></account-payment-methods>
            </v-tab-item>
            <v-tab-item value="company">
              <company-details></company-details>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import accountPaymentMethods from '../components/account/paymentMethods.vue';
import userController from '../controllers/userController';
import companyDetails from '../components/account/companyDetails.vue';

const u = new userController();

export default {
  name: 'Account',
  components: {
    accountPaymentMethods,
    companyDetails
  },
  data() {
    return {
      profile: {},
      loading: false,
      canChangePassword: false,
      password: '',
      tab: 'company',
      payment_methods: []
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'categories'
    ])
  },
  methods: {
    ...mapActions([
      'updateUserData'
    ]),
    async updateUserDetails() {
      if(!this.$refs.userAccount.validate()) {
        return;
      }
      this.loading = true;
      var data = {
        name: this.profile.name,
        last_name: this.profile.last_name,
        email: this.profile.email,
        lang: this.profile.lang
      };
      if(this.canChangePassword) {
        data.password = this.password;
        data.password_confirmation = this.password;
      }
      const userResult = await u.updateProfile(data);
      if(userResult) {
        this.updateUserData(userResult.user);
      }
      this.$toasted.success(this.$t('user_account.profile_updated'));
      this.loading = false;
    },
    replace() {
      //window.location.hash = '#'+this.tab;
      //location.href.replace(location.hash, '#'+this.tab);
      history.pushState("", document.title, window.location.pathname + '#' + this.tab);
    }
  },
  created() {
    //const client = Object.assign({}, this.user, false);
    const client = JSON.parse(JSON.stringify(this.user));
    this.profile = client;
    this.pref_categories = this.user.company_detail.category_ids;
    //console.log(this.categories);
  },
  mounted() {
    if(this.$route.hash) {
      this.$nextTick(() => {
        this.tab = this.$route.hash.replace('#', '');
      });
    } 
  }
}
</script>

<style>
.profile .v-window.v-item-group.theme--light.v-tabs-items {
  background-color: rgba(255,255,255,0);
}
</style>
