<template>
  <a
    href="#"
    style="white-space: normal;"
    @click.prevent="$router.push({ name: 'Account', hash: '#company' })"
  >
    <v-alert
      text
      dense
      outlined
      color="orange lighten-2"
      class="text-center"
    >
      <v-icon class="mr-3" color="orange">mdi-account-lock</v-icon>{{ $t('user_account.account_locked') }}
    </v-alert>
  </a>
</template>

<script>
export default {
  name: 'incompleteProfile'
}
</script>