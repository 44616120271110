<template>
  <div style="position: relative;">
    <div>
      <v-btn
        class="mb-2"
        block
        outlined
        color="black"
        x-large
        depressed
        @click="klarnaLoad"
        :loading="klarnaLoading"
        :disabled="showKlarnaPayButton || disableKlarna"
      >
        <img 
          src="https://x.klarnacdn.net/payment-method/assets/badges/generic/klarna.svg"
          witdh="100" 
          class="mr-3"
        >
        {{ $t('payment.pay_later') }}
      </v-btn>
      <span v-if="disableKlarna" class="error--text text-caption text-center d-block">{{ this.$t('cart.klarna_only_eur') }}</span>
      <loader v-model="klarnaLoading" v-show="showKlarnaPayButton"></loader>
      <div id="klarna-payments-container" style="text-align: center;"></div>
      <v-btn
        v-show="showKlarnaPayButton"
        class="mb-2"
        block
        outlined
        color="black"
        dark
        depressed
        @click="klarnaAuthorize"
      >{{ $t('payment.pay_with_klarna') }}</v-btn>
    </div>
  </div>
</template>

<script>
import loader from '../../components/loadingBox.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'klarnaCheckout',
  props: ['addresses'],
  components: {
    loader
  },
  data() {
    return {
      klarnaOrder: null,
      showKlarnaPayButton: false,
      klarnaLoading: false,
      disableKlarna: false
    }
  },
  computed: {
    ...mapGetters('cart', {
      payment: 'checkoutPaymentData'
    }),
    payment_secret() {
      return this.payment.protected_id;
    }
  },
  methods: {
    async klarnaInit() {
      this.klarnaLoading = true;
      try {
        var token = await this.$paymentController.getKlarnaToken({ payment_secret: this.payment_secret });
        if(token) {
          window.Klarna.Payments.init({ client_token: token.klarna.client_token });
        } else {
          this.disableKlarna = true;
        }
      } catch(e) {
        console.log('checkoutPaymentOptions.vue?klarnaInit', e);
      }
      this.klarnaLoading = false;
    },
    async klarnaLoad() {
      var vue = this;
      this.klarnaLoading = true;
      try {
        await window.Klarna.Payments.load({
          container: '#klarna-payments-container',
          payment_method_category: 'pay_later'
        }, {
        }, function (res) { // load~callback
          if(res.show_form) {
            vue.showKlarnaPayButton = true;
            vue.klarnaLoading = false;
          }
          //vue.debug([(res,'load')]);
        });
      } catch (e) {
        vue.$toasted.error(vue.$t('payment.something_wrong_with_pm'));
        vue.klarnaLoading = false;
      }
      
    },
    async klarnaAuthorize() {
      var vue = this;
      vue.klarnaLoading = true;
      try {
        window.Klarna.Payments.authorize({
          payment_method_category: 'pay_later'
        }, 
        vue.klarnaOrder, 
        async function (res) { // authorize~callback
          if(res.approved) {
            if(res.authorization_token) {
              var order = await vue.$paymentController.createKlarnaOrder({
                authorization_token: res.authorization_token,
                payment_secret: vue.payment_secret,
                address_uuids: vue.addresses
              });
              if(order.result) {
                vue.$toasted.success(vue.$t('payment.payment_successful'));
                vue.$router.push({ name: 'Orders' });
              } else {
                vue.$toasted.error(vue.$t('payment.something_wrong_with_pm'));
                vue.klarnaLoading = false;
              }
            }
          } else {
            vue.klarnaLoading = false;
          }
        });
      } catch (e) {
        vue.$toasted.error(vue.$t('payment.something_wrong_with_pm'));
        vue.klarnaLoading = false;
      }
    },
  },
  mounted() {
    this.klarnaInit();
  }
}
</script>