<template>
  <v-container class="login-container d-flex align-center flex-row">
    <v-card
      elevation="3"
      :class="`py-10 mx-auto ${$settings.store_id}`"
    >
      <div :class="['login-header']">
        <img v-if="$settings.store_id === 'sps'" width="300" :src="`${$settings.logo}`" alt="Logo" />
      </div>
      <slot></slot>
    </v-card>
  </v-container>
</template>
<script>
export default {
  name: 'authCard'
}
</script>