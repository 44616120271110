<template>
  <div>
    <v-menu
      open-on-hover
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="bt-content-col"
          dark
          depressed
          plain
          small
          height="auto"
          v-bind="attrs"
          v-on="on"
          @click="debug([user])"
        >
          <v-icon class="mb-1">mdi-account-circle-outline</v-icon>{{ $t('account') }}
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item
          :to="{ name: 'Account' }"
          link
        >
          <v-list-item-content>
            <v-list-item-title>{{ $t('user_account.my_information') }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon>mdi-account-settings</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item
          @click="logout"
          link
        >
          <v-list-item-content>
            <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'avatarMenu',
  computed: {
    ...mapGetters([
      'user'
    ]),
    initials() {
      var first = this.user.name ? this.user.name.charAt(0) : '';
      var second = this.user.last_name ? this.user.last_name.charAt(0) : '';
      return first+second;
    }
  },
  methods: {
    ...mapActions([
      'logout'
    ])
  }
}
</script>
