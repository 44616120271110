<template>
  <div>
    <div
      v-if="option.has_offer === 0 || option.has_offer === null"
      v-html="mxPriceTiers(option, deliveryChoice)"
      class="product-price"
    ></div>
    <div v-else>
      <div style="font-size: 12px">{{ $t('products.unit_price_before') }}: <span class="font-weight-bold text-decoration-line-through">{{ numberToNiceString(unit_price, getCurrency) }}</span></div>
      <div class="pb-3" style="font-size: 16px;">{{ $t('products.now') }}: <span class="mColor-text font-weight-bold">{{ numberToNiceString(offer_price, getCurrency) }}</span></div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'productOptionSelectionPrice',
  props: ['option', 'delivery-choice'],
  computed: {
    ...mapGetters(['getCurrency']),
    price() {
      if(this.deliveryChoice === 'express') {
        return this.option.price_unsold;
      }
      return this.option.price;
    },
    unit_price() {
      if(this.option.offer && this.option.offer.initial_value !== null) {
        return this.option.offer.initial_value;
      }
      return this.price.unit_price;
    },
    offer_price() {
      if(this.option.offer && this.option.offer.action === 'REPRICE') {
        return this.option.offer.value*1;
      }
      return this.price.unit_price;
    }
  }
}
</script>

<style lang="scss">
.product-price {
  font-size: 14px !important;
  color: #000 !important;
  margin-bottom: 10px;
  div {
    line-height: 1.5;
  }
}
</style>