const settings = () => {
  return {
    menuOpen: false,
    loading: false,
    vuetifyColor: 'red darken-4',
    hexColor: '#B71C1C', 
    catalogueView: 'list',
    currency: 'EUR',
    language: 'en',
    incoterm: 'REVOOLOOP',
    catalogueCargo: {},
    development: process.env.NODE_ENV === 'development' ? true : false,
    sessionTime: new Date().getTime(),
    helpersModal: {
      show: false,
      data: null
    },
    client: null
  }
};

//app general settings
const appSettings = {
  state: settings(),
  mutations: {
    menuToggle(state) {
      state.menuOpen = !state.menuOpen
    },
    isLoading(state, bool) {
      state.loading = bool
    },
    setCatalogueView(state, display) {
      state.catalogueView = display
    },
    currencyChange(state, currency) {
      state.currency = currency
    },
    languageChange(state, language) {
      state.language = language
    },
    changeInconterm(state, incoterm) {
      state.incoterm = incoterm
    },
    setCatalogueCargo(state, cargo) {
      state.catalogueCargo = cargo
    },
    resetSessionTime(state) {
      //Object.assign(state, settings())
      state.sessionTime = new Date().getTime();
    },
    toggleBuyerProtection(state) {
      state.buyerProtectionDialog = !state.buyerProtectionDialog;
    },
    toggleHelpers(state, data) {
      //console.log(state.helpersModal, data);
      state.helpersModal.show = data.show;
      state.helpersModal.data = data.data;
    },
    setClientLocation(state, location) {
      state.language = location?.country === 'ES' ? 'es' : 'en';
      state.client = location;
    }
  },
  actions: {
    async getClientLocation({state, commit }) {
      commit('setClientLocation', null);
      if(state.client === null) {
        return new Promise((resolve) => {
          fetch('https://ipapi.co/json/')
          .then(function(response) {
            response.json().then(jsonData => {
              commit('setClientLocation', jsonData);
              resolve();
            });
          }).catch(function(error) {
            console.log(error);
            resolve();
          });
        });
      }
    }
  },
  getters: {
    menuOpen: state => state.menuOpen,
    isLoading: state => state.loading,
    vColor: state => state.vuetifyColor,
    hexColor: state => state.hexColor,
    catView: state => state.catalogueView,
    getCurrency: state => state.currency,
    getCurrencyText: (state) => {
      var str = state.currency
      if(state.currency == 'EUR') {
        str+= ' €'
      }
      if(state.currency == 'USD') {
        str+= ' $'
      }
      if(state.currency == 'CNY') {
        str+= ' ¥'
      }
      if(state.currency == 'GBP') {
        str+= ' £'
      }
      return str
    },
    getLanguage: state => state.language,
    getIncoterm: state => {
      return state.incoterm
    },
    catalogueCargo: state => state.catalogueCargo,
    development: state => state.development,
    sessionTime: state => state.sessionTime,
    helpersModal: state => state.helpersModal
  }
}

export default appSettings