import Vue from 'vue';
import router from './router';
import vuetify from './plugins/vuetify';
import VueI18n from 'vue-i18n';
import en from './translations/en';
import es from './translations/es';
import store from './store';
import Axios from 'axios';
import toasted from 'vue-toasted';
import mixins from './mixins';
import productMixins from './mixins/products.js';
import firebaseConfig from './configs/firebase.js';
import firebase from 'firebase/app';
import orderController from './controllers/orderController';
import paymentController from './controllers/paymentController';
import pluralize from 'pluralize';
import IdleVue from 'idle-vue';
import { preferences } from './configs/storeSettings';

//mounting in
import App from './App.vue';

//global components
import pageHeader from './components/pageHeader.vue';
import subsectionHeader from './components/miscellaneous/subsectionHeader.vue';

//app scss
import './assets/scss/app.scss';

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

Vue.config.productionTip = process.env.NODE_ENV === 'development' ? false : true;

//register axios globally & set header of user token
Vue.prototype.$http = Axios;
Vue.prototype.$http.defaults.headers.common['apiKey'] = process.env.VUE_APP_PUBLIC_KEY;
Vue.prototype.$http.defaults.headers.common['userToken'] = store.getters.token;
Vue.prototype.$http.defaults.headers.common['lang'] = store.getters.getLanguage;
Vue.prototype.$http.defaults.headers.common['endpointId'] = preferences.endpointId;

//pluralize script
Vue.prototype.$pluralize = pluralize;

//store settings
Vue.prototype.$settings = preferences;

//Controller globally
Vue.prototype.$orderController = new orderController();
Vue.prototype.$paymentController = new paymentController();

//load toastr globally
Vue.use(toasted, {
  position: 'bottom-center',
  keepOnOver: true,
  className: 'toasted-message', 
  theme: "bubble", 
  duration : 5000
});

//load mixins
Vue.mixin(mixins);
Vue.mixin(productMixins);

//language
Vue.use(VueI18n);

const messages = { en, es };

// Create VueI18n instance with options
export const i18n = new VueI18n({
  locale: store.getters.getLanguage, // set locale
  fallbackLocale: 'en',
  silentFallbackWarn: true,
  messages
});

//append meta title
router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = preferences.name+' - '+i18n.t(to.meta.title) || 'Revooloop';
  });
});

//register global components
Vue.component('page-header', pageHeader);
Vue.component('subsection-header', subsectionHeader);

const eventsHub = new Vue();
 
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 3600000 // 45 minutes = 1000*60*45
})

//start app
new Vue({
  router,
  vuetify,
  store,
  i18n,
  computed: {
    development() {
      return process.env.NODE_ENV === 'development' ? true : false;
    },
    lang() {
      return this.$store.getters.getLanguage
    },
    currency() {
      return this.$store.getters.getCurrency
    }
  },
  watch: {
    lang(new_lang, old_lang) {
      if(new_lang !== old_lang) {
        this.$http.defaults.headers.common['lang'] = new_lang;
        this.$i18n.locale = new_lang;
        this.$store.dispatch('loadCategories');
      }
    },
    currency() {
      this.$store.dispatch('loadCategories');
    }
  },
  created: function () {
    if(this.$route.meta.requiresAuth !== false) {
      this.$store.dispatch('checkSession'); 
    }
    //check the status of the session on the server before doing any request
    this.$http.interceptors.response.use(undefined, (err) => {
      return new Promise((/*resolve, reject*/) => {
        if(err.response) {
          if (err.response.status === 401 && err.response.config && !err.response.config.__isRetryRequest) {
            this.$store.dispatch('logout', true)
          }
        }
        throw err
      })
    })
  },
  onIdle() {
    this.$store.dispatch('checkSession'); 
  },
  onActive() {
    //user is active
  },
  render: h => h(App)
}).$mount('#app');
