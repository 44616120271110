import Vue from 'vue';
import router from '../router';
import { i18n } from '../main';
//import Axios from 'axios';
//import { AUTH_LOGIN, AUTH_LOGOUT } from '../constants/endpoints.js';
import authController from '../controllers/authController';
import userController from '../controllers/userController';

var ac = new authController();
var uc = new userController();

const defaultState = () => {
  return {
    auth: false,
    status: '',
    token: '',
    user : '',
    jwt: ''
  }
}

//session module
const authentication = {
  state: defaultState,
  mutations: {
    auth_request(state){
      state.status = 'loading';
    },
    auth_success(state, data) {
      state.auth = true;
      state.status = 'success';
      state.token = data.token;
      state.user = data.client;
      state.jwt = data.jwt;
    },
    auth_error(state) {
      state.status = 'error';
    },
    logout(state) {
      Vue.prototype.$http.defaults.headers.common['userToken'] = null;
      Object.assign(state, defaultState());
    },
    //update user in store
    updateUser(state, user) {
      state.user = user;
    }
  },
  actions: {
    async fetchUser({commit}) {
      this.commit('isLoading', true);
      var u = await uc.fetchProfile();
      if(u) {
        commit('updateUser', u.user);
      }
      this.commit('isLoading', false);
    },
    updateUserData({state, commit}, user) {
      if(state.user.lang !== user.lang) {
        commit('languageChange', user.lang);
      }
      if(state.user.company_detail.pref_currency !== user.company_detail.pref_currency) {
        commit('currencyChange', user.company_detail.pref_currency);
      }
      commit('updateUser', user);
    },
    async login({commit}, user) {
      var session;
      if(user.data && user.data.token) {//session started by account creation
        session = user;
      } else {
        //normal login
        session = await ac.login(user);
      }
      if(session.result === true) {
        const token = session.data.token;
        const client = session.data.user;
        const jwt = session.data.jwt;
        Vue.prototype.$http.defaults.headers.common['userToken'] = token;
        commit('auth_success', { token, client, jwt });
        commit('languageChange', client.lang);
        commit('currencyChange', client.company_detail.pref_currency);
        if(router.history.current.params.redirect) {
          router.push(router.history.current.params.redirect);
        } else {
          router.push('/catalogue');
        }
      } else {
        if(session.status_code === 10002) {
          router.push({name: 'Register', query: { uuid: session.data }});
          Vue.toasted.error(i18n.t('please_verify'))
          return;
        }
        Vue.toasted.error(i18n.t('wrong_credentials'));
      }
    },
    async checkSession() {
      var session = await ac.checkSession();
      if(!session) {
        this.dispatch('logout');
      }
    },
    async logout({commit}, expired = false) {
      if(expired === false) {
        await ac.logout();
      }
      this.dispatch('cart/clearCart');
      commit('resetCatalogue', null, { root: true });
      commit('resetSessionTime', null, { root: true });
      commit('logout');
      if(router.history.current.name !== 'Login') {
        router.push('/login');
      }
      //remove always the loading on logout
      this.commit('isLoading', false);
    }  
  },
  getters : {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    isAuthenticated: state => state.auth,
    token: state => state.token,
    user: state => state.user,
    company: (getters) => {
      var c = getters.user.company_detail;
      return {
        name: c.name,
        br_number: c.br_number,
        tel: c.contact_no,
        delivery_address: c.delivery_address && c.delivery_address !== '' ? c.delivery_address : c.address,
        country: c.country,
        country_id: c.country_id
      }
    },
    user_deposit: (state) => state.user.company_detail.deposit_percent,
    jwt: state => state.jwt,
    profileIncomplete: (state, getters) => {
      if(!getters.isAuthenticated) {
        return false;
      }
      var contact = getters.user.company_detail.contact_no === null ? '' : getters.user.company_detail.contact_no;
      var br = getters.user.company_detail.br_number === null ? '' : getters.user.company_detail.br_number;
      var billing_address = getters.billingAddress.uuid === null;
      var profile = (br.length === 0 || contact.length === 0 || getters.user.company_detail.name === 'New Company' || billing_address);
      return profile;
    },
    billingAddress: (state, getters) => {
      let c = state.user.company_detail;
      if(c.billing_addresses.length > 0) {
        return c.billing_addresses[0];
      }
      return {
        id: null,
        uuid: null,
        type: 'BILLING',
        is_default:  1,
        recipient_first_name:  getters.user.name,
        recipient_last_name:  getters.user.last_name,
        recipient_company:  getters.company.name,
        line_one: '',
        line_two:  '',
        city:  '',
        postal_code:  '',
        phone: getters.company.tel,
        country_id: getters.company.country_id,
        country_name: getters.company.country_name
      };
    },
    deliveryAddresses: state => state.user.company_detail.delivery_addresses,
    billingAddressText: (state, getters) => {
      var b = getters.billingAddress;
      return `${b.line_one}, ${b.line_two}, ${b.city}, ${b.postal_code}, ${b.country_name}`;
    },
    defaultDeliveryUuid: (state, getters) => {
      var a = getters.deliveryAddresses.find(d => d.is_default === 1);
      return a ? a.uuid : null;
    },
    locale: (state) => {
      return state.user.locale ?? 'en-GB';
    }
  }
}

export default authentication
