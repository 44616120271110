import Vue from 'vue';

import { i18n } from '../main.js';

import orderController from '../controllers/orderController';

const oc = new orderController();

const defaultState = () => {
  return {
    products: [],
    company: {},
    distribution: {},
    incoterm: '',
    transport: 'sea',
    showCart: false,
    checkoutPaymentData: {}
  }
}

const cart = {
  state: defaultState(),
  namespaced: true,
  mutations: {
    add(state, { product, user }) {
      //check min order
      if(product.quantity < product.min_order) {
        Vue.toasted.error(i18n.t('cart_messages.min_quantity', { min_quantity: product.min_order }));
        return;
      }
      //check max order for flash
      if(product.is_flash) {
        if(product.quantity > product.available_quantity) {
          Vue.toasted.error(i18n.t('cart_messages.max_quantity', { max_quantity: product.available_quantity }));
          return;
        }
      }
      product.subtotal = product.price.unit_price*product.quantity_per_carton*product.quantity;
      product.required_payment = product.is_flash ? product.subtotal : user.company_detail.deposit_percent*product.subtotal/100;
      state.products.push(product);
      state.products.sort(function(a, b) {
        return a.is_flash - b.is_flash;
      });
      Vue.toasted.success(i18n.t('cart_messages.product_added'));
    },
    update(state, { product, addUp, failedUpdate, user }) {

      const existing = state.products.find(x => x.idd === product.idd);

      if(failedUpdate === 'min') {
        Vue.toasted.error(i18n.t('cart_messages.min_quantity', { min_quantity: existing.min_order }));
        return;
      }
      const quantity = addUp === true ? existing.quantity+product.quantity : product.quantity;
      //check max quantity in flash before updating
      if(existing.is_flash) {
        if(quantity > existing.available_quantity) {
          Vue.toasted.error(i18n.t('cart_messages.max_quantity', { max_quantity: product.available_quantity }));
          return;
        }
      }
      existing.quantity = quantity;
      existing.subtotal = existing.price.unit_price*existing.quantity_per_carton*quantity;
      existing.required_payment = existing.is_flash ? existing.subtotal : user.company_detail.deposit_percent*existing.subtotal/100;

      Vue.toasted.success(i18n.t('cart_messages.quantity_updated'));
    },
    remove(state, idd) {
      state.products = state.products.filter( function(val) {
        return val.idd != idd
      })
    },
    clearProducts(state) {
      state.products = []
    },
    clear(state, clearOnlyItems = false) {
      // Merge rather than replace so we don't lose observers
      // https://github.com/vuejs/vuex/issues/1118
      if(clearOnlyItems) {
        state.products = []
        return
      }
      Object.assign(state, defaultState());
    },
    company(state, company) {
      state.company = company
    },
    setDistribution(state, dist) {
      state.distribution = dist
    },
    setIncoterm(state, incoterm) {
      state.incoterm = incoterm
    },
    setTransport(state, transport) {
      state.transport = transport
    },
    cartToggle(state) {
      state.showCart = !state.showCart
    },
    assigncheckoutPaymentData(state, payment) {
      state.checkoutPaymentData = payment;
    }
  },
  actions: {
    setIncoterm({commit}, incoterm) {
      commit('setIncoterm', incoterm)
    },
    setTransport({commit}, transport) {
      commit('setTransport', transport)
    },
    addToCart({state, commit, dispatch, rootGetters}, product) {
      //check if exist already in cart
      const existing = state.products.find(x => x.idd === product.idd);
      var user = rootGetters.user;
      if(existing) {
        commit('update', { product, addUp: true, user });
      } else {
        commit('add', { product, user });
      }
      if(state.incoterm === 'REVOOLOOP') {
        dispatch('getDistribution');
      }
    },
    removeFromCart({commit}, idd) {
      Vue.toasted.success(i18n.t('cart_messages.product_removed'));
      commit('remove', idd)
    },
    clearCart({commit}) {
      commit('clear')
    },
    clearCartItems({commit}) {
      commit('clear', true)
    },
    updateQuantity({state, commit, rootGetters}, updates) {
      var user = rootGetters.user;
      return new Promise((resolve) => {
        const item = state.products.find(i => i.idd === updates.idd);

        if(updates.quantity == 0 || updates.quantity == '') {
          commit('remove', updates.idd)
        } else if(updates.quantity < item.min_order) {
          commit('update', { product: { idd: updates.idd, quantity: item.min_order }, failedUpdate: 'min' })
          resolve(item.quantity);
        } else {
          commit('update', { product: { idd: updates.idd, quantity: updates.quantity }, user });
          resolve(item.quantity);
        }
      })
    },
    setCompany({commit}, company) {
      commit('company', company)
    },
    async confirmOrder({state, getters, commit, rootGetters}, data) {

      var params = {
        quotation: 0,
        reference: data.reference,
        customer_id: getters.company.id,
        currency: rootGetters.getCurrency,
        products: state.products,
        incoterm: state.incoterm,
        notify_client: data.notify
      }
    
      const order = await oc.createOrder(params);
      if(order.result) {
        commit('clear', true);
        return order.data;
      } else {
        Vue.toasted.error(order.response_message);
      }

    }
  },
  getters: {
    products: state => state.products,
    count: state => state.products.length,
    company: state => state.company,
    subtotal: state => {
      const sum = state.products.map(function(p) {
        return +p.subtotal
      })
      return sum.reduce((a, b) => a + b, 0)
    },
    cbm: state => {
      const sum = state.products.map(function(p) {
        return p.cbm_per_carton*p.quantity
      })
      return sum.reduce((a, b) => a + b, 0)
    },
    weight: state => {
      const sum = state.products.map(function(p) {
        return p.weight_per_carton*p.quantity
      });
      return sum.reduce((a, b) => a + b, 0)
    },
    origin_zone: state => state.company.warehouse.zone.id,
    destination_zone: state => state.company.zone.id,
    distribution: state => state.distribution,
    cart_type: (state, getters) => {
      var items_type = getters.products.map(function(p) {
        return p.is_flash ? 'express' : 'production';
      });
      if(items_type.every((val, i, arr) => val === arr[0])) {
        return items_type[0];
      }
      return 'mixed';
    },
    total: (state, getters) => {
      var total = getters.subtotal;//+getters.distribution.cost_total;
      return total+getters.tax;
    },
    tax_val: () => 21,
    tax: (state, getters) => {
      return getters.tax_val*getters.subtotal/100;
    },
    total_production: (state, getters) => {
      var sum = 0;
      getters.products.forEach(p => {
        if(!p.is_flash) {
          sum = sum+p.subtotal;
        }
      });
      return sum;
    },
    total_express: (state, getters) => {
      var sum = 0;
      getters.products.forEach(p => {
        if(p.is_flash) {
          sum = sum+p.subtotal;
        }
      });
      return sum;
    },
    deposit_percent: (state, getters, rootState) => {
      var percent = 30;
      if(rootState.auth.user) {
        percent = rootState.auth.user.company_detail.deposit_percent;
      }
      return getters.cart_type === 'express' ? 100 : percent;
    },
    required_payment: (state, getters) => {
      var total = (getters.deposit_percent*getters.total_production/100)+getters.total_express;
      var tax = getters.tax_val*total/100;
      return total+tax;
    },
    incoterm: (state) => state.incoterm,
    transport: (state) => state.transport,
    showCart: (state) => state.showCart,
    checkoutPaymentData: (state) =>state.checkoutPaymentData,
    alreadyBilled: state => {
      if(state.checkoutPaymentData.billed === 1) {
        return true;
      }
      return false;
    },
    billedAddress: (state, getters) => {
      if(getters.alreadyBilled) {
        return state.checkoutPaymentData.billing_address;
      }
      return null;
    },
    billedDeliveryAddress: (state, getters) => {
      if(getters.alreadyBilled) {
        if(state.checkoutPaymentData.delivery_address) {
          return state.checkoutPaymentData.delivery_address;
        }
        return getters.billedAddress;
      }
      return null;
    }
  }
}

export default cart