<template>
  <v-card class="pa-5">
    <div>
      <v-card-title class="text-h4 font-weight-bold">Términos y Condiciones de Uso</v-card-title>
      <v-card-subtitle> Ultima actualización: 1 Enero 2022</v-card-subtitle>
      <v-card-text>
        Gracias por su compra en Sourcing Specialists S.L.<br>
        Si, por alguna razón, no estuviese satisfecho con su compra le invitamos a que revise nuestra política de reembolsos y garantías. 

        <div class="font-weight-bold text-h6 mt-3">DEFINICIONES</div>
        <div class="pa-3">
          <span class="font-weight-bold">Compañía</span> (referida como “la compañía”, “nosotros” o “nuestro” en este acuerdo) se refiere a Sourcing Specialists S.L., Avenida Central número 10, 30100 Murcia, España.<br><br>
          <span class="font-weight-bold">Productos</span> se refiere a los productos ofrecidos para la venta.<br><br>
          <span class="font-weight-bold">Pedido</span> significa una propuesta hecha por usted para comprar nuestros productos.<br><br>
          <span class="font-weight-bold">Servicio</span> se refiere a la web.<br><br>
          <span class="font-weight-bold">Website</span> se refiere a Sourcing Specialists, accesible desde www.sourcingspecialists.com<br><br>
          <span class="font-weight-bold">Usted</span> significa persona o compañía accediendo o utilizando el servicio, o la compañía u otra entidad legal de parte de la cual el individuo o compañía están accediendo o utilizando el servicio si es aplicable.
        </div>

        <div class="font-weight-bold text-h6 mt-3">INFORMACIÓN RELEVANTE</div>
        <div class="pa-3">
          Es requisito necesario para la adquisición de los productos que se ofrecen en este sitio, que lea y acepte los siguientes Términos y 
          Condiciones que a continuación se redactan. El uso de nuestros servicios, así como la compra de nuestros productos implicará que usted ha 
          leído y aceptado los Términos y Condiciones de Uso en el presente documento. Todos los productos que son ofrecidos por nuestro sitio 
          web pudieran ser creados, cobrados, enviados o presentados por una página web tercera y en tal caso estarían sujetos a sus propios 
          Términos y Condiciones. En algunos casos, para adquirir un producto, será necesario el registro por parte del usuario, con ingreso de 
          datos personales fidedignos y definición de una contraseña.<br><br>

          El usuario puede elegir y cambiar la clave para su acceso de administración de la cuenta en cualquier momento, en caso de que se haya 
          registrado y que sea necesario para la compra de alguno de nuestros productos. www.sourcingspecialists.com no asume la responsabilidad en caso 
          de que entregue dicha clave a terceros.<br><br>

          Todas las compras y transacciones que se lleven a cabo por medio de este sitio web están sujetas a un proceso de confirmación y verificación, 
          el cual podría incluir la verificación del stock y disponibilidad de producto, validación de la forma de pago, validación de la factura 
          (en caso de existir) y el cumplimiento de las condiciones requeridas por el medio de pago seleccionado. En algunos casos puede que se requiera 
          una verificación por medio de correo electrónico.<br><br>

          Los precios de los productos ofrecidos en este servicio son válidos solamente en las compras realizadas en este sitio web.
        </div>

        <div class="font-weight-bold text-h6 mt-3">LICENCIA</div>
        <div class="pa-3">
          Sourcing Specialists S.L.  a través de su sitio web concede una licencia para que los usuarios utilicen los productos que son vendidos en este 
          sitio web de acuerdo a los Términos y Condiciones que se describen en este documento.
        </div>
        
        <div class="font-weight-bold text-h6 mt-3">USO NO AUTORIZADO</div>
        <div class="pa-3">
          En caso de que aplique (para venta de software, u otro producto de diseño y programación) usted no puede colocar uno de nuestros 
          productos, modificado o sin modificar, en un CD, sitio web o ningún otro medio y ofrecerlos para la redistribución o la reventa de ningún tipo.
        </div>

        <div class="font-weight-bold text-h6 mt-3">PROPIEDAD</div>
        <div class="pa-3">
          Usted no puede declarar propiedad intelectual o exclusiva a ninguno de nuestros productos, modificado o sin modificar. 
          Todos los productos son propiedad de la compañía. En caso de que no se especifique lo contrario, nuestros productos se proporcionan sin ningún 
          tipo de garantía, expresa o implícita. En ningún caso la compañía será responsable de ningún daño incluyendo, pero no limitado a, daños directos, 
          indirectos, especiales, fortuitos o consecuentes u otras pérdidas resultantes del uso o de la imposibilidad de utilizar nuestros productos.
        </div>
        
        <div class="font-weight-bold text-h6 mt-3">POLÍTICA DE REEMBOLSO Y GARANTÍA</div>
        <div class="pa-3">
          Usted tiene derecho a cancelar su pedido en un plazo de 30 días a partir de la fecha en la que recibió los productos o en el que un tercero que haya 
          designado, que no sea el transportista, tome posesión del producto entregado.<br><br>

          Para ejercer su derecho de cancelación, deber informarnos de su decisión mediante una declaración clara. Puede informarnos de su decisión mediante:<br><br>

          Correo electrónico:<br>
            help@sourcingspecialists.com <br><br>

          Le reembolsaremos a más tardar 14 días luego de la fecha en la que recibamos los productos devueltos. Usaremos el mismo medio de pago que uso para 
          el pedido y la transacción no incurrirá en ningún cargo por dicho reembolso.<br><br>

          Para que los productos sean elegibles para una devolución, asegúrese de que:<br><br>
          •	La descripción no se ajusta al producto<br>
          •	Los productos fueron comprados y recibidos en los últimos 30 días<br>
          •	Los productos se encuentran con el embalaje original<br><br>
          Los siguientes productos no se pueden devolver:<br><br>
          •	Productos que por su naturaleza no sean aptos para ser devueltos, se deterioren rápidamente o cuando la fecha de caducidad haya terminado<br>
          •	Productos que, según su naturaleza, se mezclan inseparablemente con otros artículos<br>
          •	Productos que se ajusten perfectamente a la descripción y no tengan ningún defecto de fabrica<br>
          •	Productos que no se hayan entregado<br><br>

          Hay productos que pudieran tener garantía de uso y posibilidad de reembolso pasados los 30 días, pero estos casos serán especificados antes de la 
          compra del producto.<br><br>

          Nos reservamos el derecho de rechazar devoluciones de cualquier mercancía que no cumpla con las condiciones de devolución anteriores a nuestro 
          exclusivo criterio.<br><br>

          Usted es responsable del costo y riesgo de devolvernos los productos. Debe enviar los productos a la siguiente dirección, una vez se haya aprobado 
          su devolución:<br><br>
          Sourcing Specialists S.L<br>
          Avenida Central Numero 10, 30100 Murcia, España<br><br>

          No nos hacemos responsables de los productos dañados o perdidos durante el envió de devolución, por lo tanto recomendamos un servicio de correo asegurado 
          y rastreable.<br><br>

          No podemos emitir un reembolso sin la recepción real de los productos.
        </div>

        <div class="font-weight-bold text-h6 mt-3">COMPROBACIÓN ANTIFRAUDE</div>
        <div class="pa-3">
          La compra del cliente puede ser aplazada para la comprobación antifraude. También puede ser suspendida por más tiempo para una 
          investigación más rigurosa, para evitar transacciones fraudulentas.
        </div>
        <div class="font-weight-bold text-h6 mt-3">PRIVACIDAD</div>
        <div class="pa-3">
          Sourcing Specialists S.L. garantiza que la información personal que usted envía cuenta con la seguridad necesaria. Los datos ingresados por usuario 
          o en el caso de requerir una validación de los pedidos no serán entregados a terceros, salvo que deba ser revelada en cumplimiento a una orden 
          judicial o requerimientos legales.<br><br>

          La suscripción a boletines de correos electrónicos publicitarios es voluntaria y podría ser seleccionada al momento de crear su cuenta.<br><br>
          Sourcing Specialists S.L. reserva los derechos de cambiar o de modificar estos términos sin previo aviso.
        </div>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'Terms'
}
</script>