<template>
  <div>
    <v-alert
      v-if="type === 'alert'"
      class="mt-3"
      text
      dense
      outlined
      color="orange lighten-2"
      icon="mdi-airplane-alert"
    >
      <div style="font-size: 10px;">{{ $t('products.delivery_alert') }}</div>
    </v-alert>
    <div
      v-if="type === 'text'"
      class="text-alert"
    ><v-icon color="orange darken-2" x-small class="mr-1">mdi-airplane-alert</v-icon>{{ $t('products.delivery_alert') }}</div>
  </div>
</template>

<script>
export default {
  name: 'deliveryAlerts',
  props: {
    type: {
      default: 'alert'
    }
  }
}
</script>

<style scoped>
.text-alert {
  font-size: 10px;
  color: #F57C00;
}
</style>