<template>
  <div>
    <v-container class="pb-0 pt-0">
      <v-row>
        <v-col>
          
        </v-col>
      </v-row>
      <v-row class="my-0">
        <v-col class="d-block d-md-flex align-center">
          <div style="max-width: 620px;">
            <v-chip-group 
              v-if="selectedCategories.length > 0 || catalogueSearch !== ''"
              :column="$vuetify.breakpoint.mdAndDown"
            >
              <v-chip
                v-if="catalogueSearch !== ''"
                close
                close-icon="mdi-close"
                color="secondary"
                outlined
                small
                @click:close="setCatalogueSearch(''), loadProducts({})"
              >{{ $t('products.search') }}: {{ catalogueSearch }}</v-chip>
              <v-chip
                v-for="(sc, index) in selectedCategories" :key="index"
                close
                close-icon="mdi-close"
                color="secondary"
                outlined
                small
                @click:close="removeSelectedCategory(sc.id)"
              >{{ sc.name }}</v-chip>
            </v-chip-group>
          </div>
          <v-spacer></v-spacer>
          <catalogue-settings></catalogue-settings>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="pt-0">
      <v-row v-if="catalogueProducts.length > 0">
        <v-col
          :cols="cols"
          v-for="(product, index) in catalogueProducts"
          :key="index"
        >
          <tiles :product="product"></tiles>
        </v-col>
      </v-row>
      <v-row v-if="catalogueProducts.length > 0">
        <v-col>
          <products-pagination></products-pagination>
        </v-col>
      </v-row>
      <v-row  v-if="catalogueProducts.length === 0">
        <v-col class="pa-5">{{ $t('products.no_matches') }}</v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import tiles from '../../components/catalogue/catalogueAsTiles.vue';
import productsPagination from '../../components/catalogue/productsPagination.vue';
import catalogueSettings from '../../components/catalogue/catalogueSettings.vue';

export default {
  name: 'Catalogue',
  components: {
    tiles,
    productsPagination,
    catalogueSettings
  },
  computed: {
    ...mapGetters([
      'selectedCategories',
      'isLoading',
      'catalogueProducts',
      'productDetailsDialog',
      'onlyFavorites',
      'catalogueSearch'
    ]),
    cols() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 12
        case 'sm': return 12
        case 'md': return 4
        case 'lg': return 3
        case 'xl': return 2
        default : return 12
      }
    }
  },
  methods: {
    ...mapActions([
      'loadProducts',
      'removeSelectedCategory',
      'loadProductDetails',
      'toggleFavorites',
      'loadProducts'
    ]),
    ...mapMutations(['setCatalogueSearch'])
  }
}
</script>
