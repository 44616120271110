<template>
  <div>
    <div>
      <h5>{{ $t('products.option_group') }}</h5>
      <v-chip-group
        v-model="selectedMainOption"
        active-class="blue-grey darken-3 white--text"
        mandatory
        column
      >
        <div  
          v-for="option in mainOptions"
          :key="option.id"
        >
          <v-chip
            label
            :value="option.group_key_slug"
            class="main-offer-wrapper"
          >
            {{ option.group_name }}
            <v-icon v-if="option.is_flash === 1" class="ml-2" small>mdi-truck-fast-outline</v-icon>
          </v-chip>
        </div>
      </v-chip-group>
    </div>
    <div>
      <h5>{{ $t('products.options') }}</h5>
      <v-chip-group
        v-model="selectedMainOptionItem"
        active-class="blue-grey darken-3 white--text"
        mandatory
        column
      >
        <v-chip
          v-for="option_item in selectedMainOptionItems"
          :key="'_'+option_item.id"
          :value="option_item"
          class="main-offer-wrapper"
          @click="selectOption(option_item.id)"
          label
        >
          {{ option_item.group_value }}
          <v-icon v-if="option_item.is_flash === 1" class="ml-2" small>mdi-truck-fast-outline</v-icon>
        </v-chip>
      </v-chip-group>
    </div>
    <div>
      <div
        v-for="(attribute_grouped, index) in selectedMainOptionItem.attributes_grouped"
        :key="'__'+index"
      >
        <h5>{{ attribute_grouped[0].group_name }}</h5>
        <v-chip-group
          v-model="attribute_grouped.selected"
          active-class="blue-grey darken-3 white--text"
          mandatory
          column
        >
          <v-chip
            v-for="attr in attribute_grouped"
            :key="'___'+attr.attribute_id"
            small
            label
            :value="attr.attribute_id"
            class="main-offer-wrapper"
          >
            {{ attr.value }}
            <v-icon v-if="attrIsExpress(attr.attribute_id)" class="ml-2" small>mdi-truck-fast-outline</v-icon>
          </v-chip>
        </v-chip-group>
      </div>
    </div>
    <template v-if="displayPrice">
      <div v-if="selectedItem.id" class="mt-5 your-selection">
        <v-row v-if="selectedItem.description !== '' && selectedItem.description !== null">
          <v-col class="selected-item-details pb-0">
            <p class="mb-0" v-html="selectedItem.description.replace(/\n/g, '\n<br/>')" />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="selected-item-details">
            <div class="mt-2 mb-2">
              <v-checkbox
                v-model="deliveryOption"
                :label="$t('products.normal_delivery')"
                value="production"
                :readonly="deliveryOption === 'production'"
                hide-details
                dense
              ></v-checkbox>
              <v-checkbox
                v-if="product.is_flash && selectedItem.is_flash"
                v-model="deliveryOption"
                :label="$t('products.express_select_label', { date: selectedItem.option_unsold_target_delivery })"
                value="express"
                :readonly="deliveryOption === 'express'"
                hide-details
                dense
              ></v-checkbox>
              <div
                v-if="selectedItem.is_flash && deliveryOption === 'express'" 
                class="text-caption font-italic mb-2 font-weight-bold red--text"
              >{{ $t('products.available_quantity', { quantity: selectedItem.available_quantity }) }}</div>
            </div>
            <div>
              <product-option-selection-price :option="selectedItem" :delivery-choice="deliveryOption"></product-option-selection-price>
            </div>
            <p class="pb-0 mb-0" style="min-height: 25px;">
              <span v-show="quantity <= selectedItem.min_order">
                <span class="font-weight-bold">{{ $t('moq') }}:</span> {{ minOrderString(selectedItem.packing, selectedItem.min_order) }}
              </span>
            </p>
            <div class="d-block d-md-flex align-center">
              <div :style="`${$vuetify.breakpoint.mdAndUp ? 'max-width: 250px;' : ''}`">
                <vue-number-input v-model="quantity" :min="selectedItem.min_order" center controls></vue-number-input>
              </div>
              <v-btn
                :class="[{ 'ml-3': $vuetify.breakpoint.mdAndUp }, { 'mt-3': $vuetify.breakpoint.mdAndDown }]"
                large
                color="primary"
                :block="$vuetify.breakpoint.mdAndDown"
                depressed
                @click="prepareToCart(selectedItem)"
              >
                {{ $t('products.add_to_cart') }} {{ numberToNiceString(quantity*selectedForCart.price.carton_price, getCurrency, 2) }}
              </v-btn>
            </div>
            
            <div class="mb-2">
              {{ quantity }} {{ outerDescription }}<span v-if="packing.unit_outer_quantity !== null">, {{ totalBaseUnits }} {{ packing.unit+'(s)' }}</span>
              <div v-if="inCart(selectedItem)" style="font-size: 12px;" class="mColor-text">
                {{ $t('cart.you_have', { quantity: inCartQuantity(selectedItem), outerDescription: outerDescription }) }}
              </div>
            </div>
            <div class="d-block d-md-flex">
              <v-alert
                class="mt-1 mr-3 buyer-protection-alert"
                icon="mdi-shield-check-outline"
                text
                type="info"
                dense
                outlined
                @click="toggleHelpers({ show: true, data: 'buyer_protection' })"
              ><div style="font-size: 12px;">{{ $t('products.buyer_protection') }}</div></v-alert>
              <v-alert
                class="mt-1"
                icon="mdi-truck-delivery-outline"
                text
                color="green"
                dense
                outlined
              ><div style="font-size: 12px;">{{ $t('products.free_shipping') }}</div></v-alert>
            </div>
            <delivery-alerts v-if="showDeliveryAlert"></delivery-alerts>
          </v-col>
        </v-row>
      </div>
    </template>
  </div>
</template>

<script>
import VueNumberInput from '@chenfengyuan/vue-number-input';
import deliveryAlerts from '../deliveryAlerts.vue';
import productOptionSelectionPrice from './productOptionSelectionPrice.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'productOptionSelection',
  props: ['product','options'],
  components: {
    VueNumberInput,
    deliveryAlerts,
    productOptionSelectionPrice
  },
  data() {
    return {
      selectedMainOption: '',
      selectedMainOptionItem: {},
      quantity: 1,
      deliveryOption: 'express',
      deliveryOptions: []
    }
  },
  computed: {
    ...mapGetters(['getCurrency']),
    mainOptions() {
      return this.product.options_grouped;
    },
    selectedMainOptionItems() {
      var group = this.mainOptions.find(mo => mo.group_key_slug === this.selectedMainOption);
      return group ? group.group_items : [];
    },
    price() {
      if(this.offer !== null) {
        if(this.offer.action === 'REPRICE') {
          return this.offer.value*1;
        }
      }
      return this.selectedItem.price.unit_price;
    },
    offer() {
      return this.selectedItem.offer;
    },
    packing() {
      return this.selectedItem.packing !== undefined ? this.selectedItem.packing : {};
    },
    outerDescription() {
      if(this.packing.unit_outer_quantity !== null) {
        return this.packing.unit_outer_description_text+'(s)';
      }
      return this.packing.unit+'(s)';
    },
    totalBaseUnits() {
      if(this.packing.unit_outer_quantity !== null) {
        return this.quantity*this.packing.unit_outer_quantity;
      }
      return this.quantity;
    },
    selectedItem() {
      return this.buildSelectedItem(this.selectedMainOptionItem, this.deliveryOption);
    },
    showDeliveryAlert() {
      return (this.deliveryOption === 'production') ? true : false;
    },
    selectedForCart() {
      return this.buildCartProduct(this.product, this.selectedItem, this.selectedItem.selected_attribute_ids, this.quantity, this.deliveryOption);
    }
  },
  watch: {
    selectedItem() {
      if(!this.selectedItem.is_flash) {
        this.deliveryOption = 'production';
      }
      this.quantity = this.selectedItem.min_order;
      //console.log(this.selectedForCart);
      this.$emit('selectedIsFlash', this.selectedItem.is_flash ? true : false)
    },
  },
  methods: {
    ...mapMutations(['toggleHelpers']),
    ...mapActions('cart', [
      'addToCart'
    ]),
    prepareToCart(item) {
      //console.log(item);
      var attrs = item.selected_attribute_ids;
      var cartProduct = this.buildCartProduct(this.product, item, attrs, this.quantity, this.deliveryOption);
      //console.log(cartProduct);
      this.addToCart(cartProduct);
    },
    attrIsExpress(id) {
      if(this.selectedItem.unsold_attrs.includes(id)) {
        return true;
      }
      return false;
    },
    attrAppliesForOffer(a_id) {
      var offer = this.selectedItem.offer;
      if(offer && offer.attribute_ids.includes(a_id)) {
        return true;
      }
      return false;
    },
    selectOption() {//trying to make the zoomer outclick the image when selection an option
      if(this.selectedItem.images[0]) {
        var thumbnail = document.getElementsByClassName('image_'+this.selectedItem.images[0].id);
        if(thumbnail[0]) {
          thumbnail[0].click();
        }
      }
    }
  }
}
</script>

<style lang="scss">
.selected-item-details li {
  padding: 0 5px;
}
.main-offer-wrapper {
  position: relative;
  overflow: inherit;
  .main-offer-wrapper-flag {
    border-radius: 50%;
    background-color: #D32F2F;
    top: -7px;
    right: -7px;
    position: absolute;
    padding: 1px 5px;
    &.small {
      transform: scale(75%);
    }
    &.express-flag {
      background-color: #FFA000;
    }
  }
}
.buyer-protection-alert {
  &:hover {
    cursor: pointer;
  }
}
</style>