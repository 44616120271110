<template>
  <auth-card>
    <v-stepper v-model="step" flat>
      <v-stepper-items>
        <v-stepper-content :step="1">
          <v-form ref="registerForm" v-on:submit.prevent="register">
            <v-container>
              <v-row>
                <v-col>
                  <v-select
                    v-model="country"
                    :rules="requiredRule"
                    :label="$t('country')"
                    persistent-hint
                    :hint="$t('we_only_support')"
                    :items="[{ text: 'España', value: 'ESP'}]"
                    disabled
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="name"
                    :rules="requiredRule"
                    :label="$t('name')"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="last_name"
                    :rules="requiredRule"
                    :label="$t('last_name')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="3">
                  <v-autocomplete
                    v-model="area_code"
                    :rules="requiredRule"
                    :label="$t('area_code')"
                    :items="acodes"
                  ></v-autocomplete>
                </v-col>
                <v-col sm="9">
                  <v-text-field
                    v-model="phone"
                    :rules="requiredRule"
                    :label="$t('phone_number')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="email"
                    :rules="emailRule"
                    label="E-mail"
                    type="email"
                    name="email"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="requiredRule"
                    :type="show ? 'text' : 'password'"
                    name="password"
                    label="Password"
                    @click:append="show = !show"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col class="pt-0">
                  <v-checkbox
                    v-model="terms"
                    :rules="requiredRule"
                    dense
                  >
                    <template v-slot:label>
                      <div>
                        {{ $t('accept_terms') }} <a href="#" @click.prevent="toggleHelpers({ show: true, data: 'terms' })">{{ $t('terms_conditions') }}</a>
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col>
                  <v-btn
                    color="primary"
                    depressed
                    @click.prevent="register"
                    dark
                    type="submit"
                    :loading="loading"
                  >{{ $t('register') }}</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-stepper-content>
        <v-stepper-content :step="2">
          <v-form ref="confirmForm" v-on:submit.prevent="confirmCode">
            <v-container>
              <div>{{ $t('we_have_sent_email') }}</div>
              <v-row class="mt-2">
                <v-col>
                  <v-otp-input
                    v-model="email_code"
                    :rules="requiredRule"
                    :label="$t('code')"
                    :disabled="loading"
                    @finish="confirmCode"
                  ></v-otp-input>
                </v-col>
              </v-row>
            </v-container>
            <v-container>
              <v-row>
                <v-col>
                  <v-btn
                    color="primary"
                    @click.prevent="confirmCode"
                    depressed
                    type="submit"
                    :loading="loading"
                  >{{ $t('confirm_email') }}</v-btn>
                  <div class="mt-3" style="font-size: 12px">
                    {{ $t('did_not_receive') }} <a href="#" @click.prevent="resendCode">{{ $t('send_again') }}</a>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-container class="mt-0">
      <div class="d-block text-center">
        {{ $t('have_account') }}
        <router-link :to="{ name: 'Login' }">{{ $t('login') }}</router-link>
      </div>
    </v-container>
  </auth-card>
</template>

<script>
import { AUTH_REGISTER_FIRST } from '../constants/endpoints.js';
import area_codes from '../constants/areaCodes';
import { mapMutations, mapActions } from 'vuex';
import authController from '../controllers/authController';
import authCard from '../components/authCard.vue';

const ac = new authController();

export default {
  name: 'LoginForm',
  components: {
    authCard
  },
  data: () => {
    return {
      country: 'ESP',
      email: '',
      password: '',
      show: false,
      name: null,
      last_name: null,
      area_code: '34',
      phone: null,
      acodes: area_codes.map( ac => {
        return {
          text: `${ac.text} +${ac.value} `,
          value: ac.value
        }
      }),
      loading: false,
      uuid: null,
      email_code: null,
      step: 1,
      terms: false
    }
  },
  methods: {
    ...mapMutations(['toggleHelpers']),
    ...mapActions(['login']),
    register() {
      //check if form is complete
      if(!this.$refs.registerForm.validate()) {
        return false;
      }

      this.loading = true;

      this.$http.post(AUTH_REGISTER_FIRST, {
        country_code: this.country,
        first_name: this.name,
        last_name: this.last_name,
        email: this.email,
        phone: this.area_code + ' ' + this.phone,
        password: this.password,
        password_confirmation: this.password,
        terms: this.terms
      }).then((resp) => {
        //console.log(resp.data);
        if(resp.data.result) {
          this.addParamsToLocation({uuid: resp.data.data});
          this.uuid = resp.data.data;
          this.step = 2;
        } else {
          if(resp.data.status_code === 10004) {
            this.addParamsToLocation({uuid: resp.data.data});
            this.uuid = resp.data.data;
            this.step = 2;
            this.$toasted.error(this.$t('please_verify'));
          } else {
            this.$toasted.error(this.$t('email_taken'));
          }
        }
      }).catch(() => {
        
      }).finally(() => {
        this.loading = false;
      });
    },
    async confirmCode() {
      //check if form is complete
      if(!this.$refs.confirmForm.validate()) {
        return false;
      }
      this.loading = true;
      const result = await ac.confirmCode({
        uuid: this.uuid,
        code: this.email_code
      });
      if(result.result) {
        this.login(result);
      } else {
        this.$toasted.error(result.message.message);
      }
      this.loading = false;
    },
    async resendCode() {
      this.$store.commit('isLoading', true);
      await ac.resendConfirmationCode({
        uuid: this.uuid
      });
      this.$store.commit('isLoading', false);
      this.$toasted.success(this.$t('email_sent'));
    }
  },
  created() {
    this.email = this.$route.query.email ?? '';
    
    if(this.$route.query.uuid) {
      this.step = 2;
      this.uuid = this.$route.query.uuid;
    }
    if(this.$route.query.email_code) {
      this.email_code = this.$route.query.email_code;
      this.$nextTick(() => {
        this.confirmCode();
      });
    }
  }
}
</script>

