<template>
  <div style="position: relative;">
    <v-stepper
      v-model="checkoutStep"
      vertical
      flat
    >
      <v-stepper-step
        step="1"
      >
        {{ $t('user_account.billing_address') }}
        <div v-if="checkoutStep !== 1" class="pt-2" style="font-size: 12px">{{ billingAddressText }}</div>
      </v-stepper-step>

      <v-stepper-content step="1">
        <div class="d-flex align-center">
        <div class="pr-4">
            {{ billingAddress.id === null ? this.$t('user_account.please_add_billing_address') : billingAddressText }}
          </div>
          <v-btn
            fab
            depressed
            plain
            small
            @click="openBillingAddressDialog('BILLING')"
          >
            <v-icon v-if="billingAddress.id === null" small>mdi-plus</v-icon>
            <v-icon v-else small>mdi-pen</v-icon>
          </v-btn>
        </div>
        <v-btn
          color="primary"
          @click="checkoutStep = 2"
        >{{ $t('next') }}</v-btn>
      </v-stepper-content>

      <v-stepper-step step="2">
        {{ $t('user_account.delivery_address') }}
        <div v-if="checkoutStep !== 1 && checkoutStep !== 2" class="pt-2" style="font-size: 12px">
          <span class="font-weight-bold">{{ selAdd.alias }}, </span> 
          {{ `${selAdd.line_one}, ${selAdd.line_two}, ${selAdd.city}, ${selAdd.postal_code}, ${selAdd.country_name}` }}
        </div>
      </v-stepper-step>

      <v-stepper-content step="2">
        <div v-if="deliveryAddresses.length > 0" class="pa-3">
          <div
            v-for="d in deliveryAddresses"
            :key="d.uuid"
            class="d-flex align-center justify-space-between mb-3"
          >
            <v-checkbox
              v-model="selectedAddress"
              :value="d.uuid"
              name="selectedAddress"
            ></v-checkbox>
            <div style="font-size: 14px;" class="pr-4">
              <span class="font-weight-bold">{{ d.alias }}, </span> 
              {{ `${d.line_one}, ${d.line_two}, ${d.city}, ${d.postal_code}, ${d.country_name}` }}
              <span v-if="d.is_default === 1" class="font-italic" style="font-size: 12px">({{$t('user_account.default_address')}})</span>
            </div>
            <v-btn
              fab
              depressed
              plain
              small
              @click="openBillingAddressDialog('DELIVERY', d)"
            >
              <v-icon v-if="billingAddress.id === null" small>mdi-plus</v-icon>
              <v-icon v-else small>mdi-pen</v-icon>
            </v-btn>
          </div>
        </div>
        <div style="font-size: 14px;" class="pa-3" v-else>{{ this.$t('user_account.no_delivery_addresses') }}</div>
        <v-btn
          depressed
          plain
          small
          @click="openBillingAddressDialog('DELIVERY')"
        >
          <v-icon small>mdi-plus</v-icon>{{ $t('user_account.add_delivery_address') }}
        </v-btn>
        <div class="d-flex justify-space-between mt-3">
          <v-btn
            color="primary"
            @click="checkoutStep = 3"
          >{{ $t('next') }}</v-btn>
          <v-btn text @click="checkoutStep = 1">{{ $t('previous') }}</v-btn>
        </div>
      </v-stepper-content>

      <v-stepper-step
        step="3"
      >{{ $t('payment.select_payment_method') }}</v-stepper-step>

      <v-stepper-content step="3">
        <checkout-payment-options 
          v-if="checkoutStep === 3" 
          :delivery-address="selectedAddress"
        ></checkout-payment-options>
        <v-alert
          class="mt-3"
          icon="mdi-shield-lock-outline"
          prominent
          text
          type="info"
        ><div style="font-size: 13px">{{ $t('payment.disclaimer') }}</div></v-alert>
      </v-stepper-content>

    </v-stepper>
    <v-dialog
      v-model="billingAddressDialog"
      max-width="480"
    >
      <v-card class="py-5">
        <v-card-title>{{ modal_title }}</v-card-title>
        <company-address-form v-if="billingAddressDialog" :address="address" @created="billingAddressDialog = false"></company-address-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import companyAddressForm from '../account/companyAddressForm.vue';
import checkoutPaymentOptions from './checkoutPaymentOptions.vue';

export default {
  name: 'checkoutSteppers',
  components: { 
    companyAddressForm,
    checkoutPaymentOptions
  },
  data() {
    return {
      checkoutStep: 1,
      billingAddressDialog: false,
      address: {
        country: 'ESP'
      },
      modal_title: null,
      selectedAddress: this.$store.getters.defaultDeliveryUuid
    }
  },
  computed: {
    ...mapGetters({
      ba: 'billingAddress',
      bat: 'billingAddressText',
      da: 'deliveryAddresses'
    }),
    ...mapGetters('cart', [
      'alreadyBilled',
      'billedAddress',
      'billedDeliveryAddress'
    ]),
    selAdd() {
      if(this.alreadyBilled) {
        return this.billedDeliveryAddress;
      }
      var a = this.deliveryAddresses.find(a => a.uuid === this.selectedAddress);
      return a ? a : this.billingAddress;
    },
    billingAddress() {
      if(this.alreadyBilled) {
        return this.billedAddress;
      }
      return this.ba;
    },
    billingAddressText() {
      var b = this.billingAddress;
      return `${b.line_one}, ${b.line_two}, ${b.city}, ${b.postal_code}, ${b.country_name}`
    },
    deliveryAddresses() {
      if(this.alreadyBilled) {
        return [this.billedDeliveryAddress];
      }
      return this.da;
    }
  },
  methods: {
    openBillingAddressDialog(type, address = null) {
      if(type === 'BILLING') {
        this.modal_title = this.$t('user_account.billing_address');
        this.address = JSON.parse(JSON.stringify(this.billingAddress));
      }
      if(type === 'DELIVERY') {
        this.modal_title = this.$t('user_account.delivery_address');
        this.address = address === null ? {} : JSON.parse(JSON.stringify(address));
      }
      this.billingAddressDialog = true;
    }
  },
  created() {
    if(this.alreadyBilled) {
      this.checkoutStep = 3;
    }
  }
}
</script>